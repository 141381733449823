import styled from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    height: calc(100% - 90px);
    padding: 0.5rem;
    overflow: hidden;
`;

export const ListContentWrapper = styled.div`
    position: relative;
    flex: 0 0 75%;
    padding-right: 0.5rem;
    max-width: 75%;
    height: 100%;
`;

export const ListWrapper = styled.div`
    height: calc(100% - 116px);
    box-shadow: 0 1px 8px 4px #ddd;
    overflow-y: auto;
    overflow-x: hidden;
`;
