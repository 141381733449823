import React from 'react'
import BackgroundImage from '../../components/images/BackgroundImage';
import { LoginWrapper } from './login.styles';
import useMsal from "../../auth/useMsal";

export default () => {
    const { loginAzAd } = useMsal();
    console.log('login');

    return (
        <>
            <BackgroundImage />
            <LoginWrapper>
                <h1>Centralized Alarm Dashboard</h1>
                <div>
                    <p>
                        Please click on the button below
                        to enter the Centralized Alarm Dashboard.
                    </p>
                    <button onClick={loginAzAd}>Sign in</button>
                </div>
            </LoginWrapper>
        </>
    );
}
