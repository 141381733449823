import {AdGroupMember} from "../types/ViewModel/AdGroupMember";
import {AzureAdGroupMemberBM} from "../types/BusinessModel/AzureAdGroupMemberBM";

export class AzureAdGroupMemberFactory {
    constructor(
    ) {
    }

    public getAdGroupMember(item: AzureAdGroupMemberBM): AdGroupMember {
        return new AdGroupMember(
            item.id,
            item.businessPhones,
            item.displayName,
            item.givenName,
            item.jobTitle,
            item.mail,
            item.mobilePhone,
            item.officeLocation,
            item.preferredLanguage,
            item.surname,
            item.userPrincipalName);
    }

    public getAdGroupMemberList(adGroupMemberBM: AzureAdGroupMemberBM[]): AdGroupMember[] {
        return adGroupMemberBM.map(o => this.getAdGroupMember(o));
    }
}
