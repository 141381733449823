import {AlertSavingsPerFuelTypeBM} from "../types/BusinessModel/AlertSavingsPerFuelTypeBM";
import {AlertSavingsPerFuelType} from "../types/ViewModel/AlertSavingsPerFuelType";
import {FuelType} from "../enums/FuelType";
import {AlertSavingsStatus} from "../enums/AlertSavingsStatus";

export class AlertSavingsPerFuelTypeFactory {
    constructor(
    ) {
    }

    public getAlertSavingsPerFuelType(item: AlertSavingsPerFuelTypeBM): AlertSavingsPerFuelType {
        return new AlertSavingsPerFuelType(
            item.alertSavingsPerFuelTypeId,
            item.fuelTypeId,
            item.fuelTypeName,
            item.fuelPrice,
            item.expectedConsumption,
            item.actualConsumption,
            item.numberOfDays,
            item.savingsInUsd,
            item.savingsInMt
        );
    }

    public getAlertSavingsPerFuelTypeList(alertSavingsPerFuelTypeListBM: AlertSavingsPerFuelTypeBM[]): AlertSavingsPerFuelType[] {
        return alertSavingsPerFuelTypeListBM.map(o => this.getAlertSavingsPerFuelType(o));
    }

    public createAlertSavingsPerFuelTypeFromCode(
        alertSavingsPerFuelTypeId: number,
        fuelTypeId: FuelType,
        fuelTypeName: string,
        fuelPrice: number,
        expectedConsumption: number,
        actualConsumption: number,
        numberOfDays: number,
        savingsInUsd: number,
        savingsInMt: number): AlertSavingsPerFuelType {
        return new AlertSavingsPerFuelType(
            alertSavingsPerFuelTypeId,
            fuelTypeId,
            fuelTypeName,
            fuelPrice,
            expectedConsumption,
            actualConsumption,
            numberOfDays,
            savingsInUsd,
            savingsInMt)
    }

    public getAlertSavingsPerFuelTypeBM(alertSavingsPerFuelType: AlertSavingsPerFuelType): AlertSavingsPerFuelTypeBM {
        return {
            alertSavingsPerFuelTypeId: alertSavingsPerFuelType.alertSavingsPerFuelTypeId,
            fuelTypeId: alertSavingsPerFuelType.fuelTypeId,
            fuelTypeName: alertSavingsPerFuelType.fuelTypeName,
            fuelPrice: alertSavingsPerFuelType.fuelPrice,
            expectedConsumption: alertSavingsPerFuelType.expectedConsumption,
            actualConsumption: alertSavingsPerFuelType.actualConsumption,
            numberOfDays: alertSavingsPerFuelType.numberOfDays,
            savingsInUsd: alertSavingsPerFuelType.savingsInUsd,
            savingsInMt: alertSavingsPerFuelType.savingsInMt
        }
    }

    public getAlertSavingsPerFuelTypeListBM(alertSavingsPerFuelTypeList: AlertSavingsPerFuelType[]): AlertSavingsPerFuelTypeBM[] {
        return alertSavingsPerFuelTypeList.map(o => this.getAlertSavingsPerFuelTypeBM(o));
    }
}
