import './History.scss';
import { Wrapper, RightContentWrapper, EditButtonWrapper } from './History.styles';
import React, { useContext, useState } from 'react';
import { PrettifyDate, PrettifyDateTime } from '../../../utils/dateUtils';
import { AlertDetails } from '../../../types/ViewModel/AlertDetails';
import { AlertItem } from '../../../types/ViewModel/AlertItem';
import SpinnerLoader from '../../loader/SpinnerLoader';
import { AppAlertListContext } from '../../../context/AppAlertListContext';

type Props = {
    alertDetails: AlertDetails | null;
    historicalAlerts: AlertItem[] | null;
};

const HistoryComponent: React.FC<Props> = ({ alertDetails, historicalAlerts }: Props) => {
    const { isLoadingHistoricalAlerts, setIsLoadingHistoricalAlerts } = useContext(AppAlertListContext)!;
    return (
        <>
            {isLoadingHistoricalAlerts && <SpinnerLoader showOverlay={true} />}
            {alertDetails && historicalAlerts && (
                <Wrapper className="history-list-wrapper">
                    <div className="list-heading">
                        <span className="alert-date">Alert date</span>
                        <span className="aol-date">AOL date</span>
                        <span className="value-wrapper">Value / Limit</span>
                        <span className="assignee">Name</span>
                    </div>
                    <ul className="history-list">
                        {historicalAlerts.map((historyItem, i) => {
                            return (
                                <li key={historyItem.alertId}>
                                    <div className="date-field-wrp field-wrp">
                                        <span className="alert-date">{PrettifyDateTime(historyItem.alertDate)}</span>
                                        <span className="aol-date">{PrettifyDate(historyItem.aolDate)}</span>
                                        <span className="value-wrapper">
                                            <span className="value">{historyItem.value?.toLocaleString('no-NO')}</span>
                                            <span className="sep">/</span>
                                            <span className="limit">{historyItem.limit?.toLocaleString('no-NO')}</span>
                                        </span>
                                        <span className="assignee">{historyItem.assignee}</span>
                                    </div>
                                    {historyItem.commentList && historyItem.commentList.length > 0 && (
                                        <div>
                                            <h4>Comments</h4>
                                            <ul className="comments-list">
                                                {historyItem.commentList.map((commentItem, j) => {
                                                    return (
                                                        <li className="comment-item" key={commentItem.alertCommentId}>
                                                            <div className="field-wrp">
                                                                <span className="created-utc">
                                                                    {PrettifyDate(commentItem.createdUtc)}
                                                                </span>
                                                                <span className="created-by">
                                                                    {commentItem.createdBy}
                                                                </span>
                                                            </div>
                                                            <p>{commentItem.comment}</p>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </Wrapper>
            )}
        </>
    );
};

export default HistoryComponent;
