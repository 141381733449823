import {AlertItem} from "../types/ViewModel/AlertItem";
import {VesselOption} from "../types/ViewModel/VesselOption";

export const SetVesselNameForAlertList = (alertList: AlertItem[], vesselList: VesselOption[]) => {
    return alertList.forEach((alert: AlertItem) => {
        const vessel = vesselList.find((vessel: VesselOption) => vessel.imoNumber === alert.alertContext.imoNumber);
        if (vessel) {
            alert.alertContext.vesselName = vessel.name;
        }
    });
};

export const SetVesselNameForAlertItem = (alertItem: AlertItem, vesselList: VesselOption[]) => {
    const vessel = vesselList.find((vessel: VesselOption) => vessel.imoNumber === alertItem.alertContext.imoNumber);
    if (vessel) {
        alertItem.alertContext.vesselName = vessel.name;
    }
    return alertItem;
};
