export class AlertContext {
    constructor(
        imoNumber: number,
        cargoOperationId: number,
        cargoNumber: string,
        productName: string,
        noonReportId: number,
        tankName:string
    )
    {
        this.imoNumber = imoNumber;
        this.vesselName = '';
        this.cargoOperationId = cargoOperationId;
        this.cargoNumber = cargoNumber;
        this.productName = productName;
        this.noonReportId = noonReportId;
        this.tankName = tankName;
    }
    imoNumber: number;
    vesselName: string;
    cargoOperationId: number;
    cargoNumber: string;
    productName: string;
    noonReportId: number;
    tankName:string;
}
