import React, {useContext} from 'react'
import { TextWrapper, Title } from './SavingsLastMonth.styles';
import {AppStateContext} from "../../../context/AppStateContext";

type Props = {}

const SavingsLastMonthComponent: React.FC<Props> = () => {
  const { savingsLastMonth
  } = useContext(AppStateContext)!;

    return (
        <TextWrapper title="Savings in USD last 30 days">
            <Title>Savings</Title>
            {savingsLastMonth.toLocaleString('no-NO')} $
        </TextWrapper>
    );
}

export default SavingsLastMonthComponent;
