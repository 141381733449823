import React, {useContext, useEffect} from 'react';
import useApi from './useApi';
import { AlertDataBM } from '../types/BusinessModel/AlertDataBM';
import { SetVesselNameForAlertList } from '../utils/vesselUtils';
import {AlertItemFactory} from "../factory/AlertItemFactory";
import {AlertItem} from "../types/ViewModel/AlertItem";
import {AppStateContext} from "../context/AppStateContext";
import {AppAlertListContext} from "../context/AppAlertListContext";

export default () => {
    const endpoint = 'GetAlertSavingsItemList';
    const { state, getApiData } = useApi();
    const {
        setAlertSavingsListView,
        setIsLoadingAlertSavingsListView,
    } = useContext(AppAlertListContext)!;

    const createQueryParam = (fromDate: Date, toDate: Date) => {
        return '?fromdate=' + fromDate.toISOString().split('T')[0] + '&todate=' + toDate.toISOString().split('T')[0];
    };

    const getAlertSavingsList = (fromDate: Date, toDate: Date) => {
        if (!endpoint) return;
        const queryParam = createQueryParam(fromDate, toDate);
        getApiData(endpoint, queryParam);
    };

    const alertItemFactory = new AlertItemFactory();

    useEffect(() => {
        setIsLoadingAlertSavingsListView(state.isLoading);
        if (state.status === 'success'){
            const alertData = state.result as unknown as AlertDataBM;
            const alertListView: AlertItem[] = alertItemFactory.getAlertItemList(alertData.alertItemList);
            if (alertListView) {
                setAlertSavingsListView(alertListView);
            }
        }
    }, [state]);

    return { state, getAlertSavingsList };
}
