import React, {useEffect, useState} from 'react'
import {UserInfo} from "./UserInfo.styles";
import {RoleType} from "../../enums/RoleType";
import {AuthContext} from "../../context/AuthContext";
import useMsal from "../../auth/useMsal";

type Props = {}

const UserInfoComponent: React.FC<Props> = () => {
    const { loggedInUser } = React.useContext(AuthContext);
    const [isUserInfoVisible, setIsUserInfoVisible] = useState(false);
    const [initials, setInitials] = useState<string | null>(null);
    
    const { logoutAzAd } = useMsal();

    const logOut = () => {
        logoutAzAd();
    };

    const showInitials = () => {
        if(loggedInUser && loggedInUser?.displayName){
            let initialsTempArr = loggedInUser?.displayName.split(" ");
            const inits = initialsTempArr[0].substring(0, 1) + " " + initialsTempArr[initialsTempArr.length-1].substring(0, 1);
            return inits.toLocaleUpperCase('no-NO');
        }
    };

    return (
            <UserInfo>
                {
                    loggedInUser?.displayName && 
                    <div className="user-icon" onClick={()=> setIsUserInfoVisible(!isUserInfoVisible)}>
                       <h3>{showInitials()}</h3> 
                    </div>
                }
                {
                    isUserInfoVisible && 
                        <div className='user-info-panel'>
                            <div className="top-wrapper">
                                <div className="user-icon">
                                    <h3>{showInitials()}</h3> 
                                </div>
                                <div>
                                    <h3>{loggedInUser?.displayName}</h3>
                                    <h4>{loggedInUser?.role.roleName}</h4>
                                </div>  
                            </div>
                            <div className="bottom-wrapper">
                                <button className="logout" onClick={()=> logOut()}>
                                    Sign out
                                </button>
                            </div>
                        </div>
                }
            </UserInfo>
    );
}

export default UserInfoComponent;
