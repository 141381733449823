import styled from "styled-components";

export const Title = styled.div`
    margin: 10px;
    padding: 5px;
    font-size: 1.35rem;
    font-weight: 400;
    overflow: hidden;
    height: 80px;
    display: flex;
    align-items: center;
`;

export const DetailWrapper = styled.div`
    background: #fff;
    margin: 0.5rem;
    height: calc(100% - 90px);
    overflow: hidden;
`;