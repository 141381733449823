import {AlertTypeMute} from "./AlertTypeMute";

export class AlertType {
    constructor(
        label: string,
        value: string,
        procedureUrl: string,
        alertTypeMuteList: AlertTypeMute[]
    ) {
        this.label = label;
        this.value = value;
        this.procedureUrl = procedureUrl;
        this.alertTypeMuteList = alertTypeMuteList;
    }
    label: string;
    value: string;
    procedureUrl: string;
    alertTypeMuteList: AlertTypeMute[];
}
