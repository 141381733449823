import {AlertChangeType} from "../../enums/AlertChangeType";

export class ChangeLog {
    constructor(
        alertChangeId: number,
        alertSavingsPerFuelTypeId: number,
        alertChangeTypeId: AlertChangeType,
        alertChangeTypeName: string,
        oldValue: string,
        newValue: string,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string
    ) {
        this.alertChangeId = alertChangeId;
        this.alertSavingsPerFuelTypeId = alertSavingsPerFuelTypeId;
        this.alertChangeTypeId = alertChangeTypeId;
        this.alertChangeTypeName = alertChangeTypeName;
        this.oldValue = oldValue;
        this.newValue = newValue;
        this.lastUpdatedUtc = lastUpdatedUtc;
        this.lastUpdatedBy = lastUpdatedBy;
    }
    alertChangeId: number;
    alertSavingsPerFuelTypeId: number;
    alertChangeTypeId: AlertChangeType;
    alertChangeTypeName: string;
    oldValue: string;
    newValue: string;
    lastUpdatedUtc: Date;
    lastUpdatedBy: string;
}
