import moment from "moment";
import { HighChartModel } from "../types/ViewModel/HighChartModel";
import { StatisticsAlert } from "../types/ViewModel/StatisticsAlert";

export const groupAlertsByDay = (statisticsAlertList: StatisticsAlert[]) => {
    const groupedStatisticsAlertList = statisticsAlertList.reduce((result, currentValue) => {
        const dayStr = moment(currentValue.alertDate).format('DD/MM');
        (result[dayStr] = result[dayStr] || []).push(
            currentValue
        );
        return result;
    }, {});

    return groupedStatisticsAlertList;
};

export const groupSavingsByMonth = (statisticsAlertList: StatisticsAlert[]) => {
    const groupedSavingsList = statisticsAlertList.reduce((result, value) => {
        const monthStr = moment(value.alertDate).format('MMM YYYY');

        if (!result[monthStr]) {
            result[monthStr] = { totalSavings: 0 };
        }
        result[monthStr].totalSavings += value.totalSavingsInUsd;

        return result;
    }, {});

    return groupedSavingsList;
};

export const groupAlertsByMonth = (statisticsAlertList: StatisticsAlert[]) => {
    const groupedStatisticsAlertList = statisticsAlertList.reduce((result, currentValue) => {
        const monthStr = moment(currentValue.alertDate).format('MMM YYYY');
        (result[monthStr] = result[monthStr] || []).push(
            currentValue
        );
        return result;
    }, {});

    return groupedStatisticsAlertList;
};


export const groupAlertsByReportingGroup = (statisticsAlertList: StatisticsAlert[]) => {
    const groupedStatisticsAlertList = statisticsAlertList.reduce((result, currentValue) => {
        const reportingGroup = currentValue.reportingGroup !== '' ? currentValue.reportingGroup : 'Unknown';;
        
        (result[reportingGroup] = result[reportingGroup] || []).push(
            currentValue
        );
        return result;
    }, {});

    return groupedStatisticsAlertList;
};

export const groupAlertsByShipDrillDown = (statisticsAlertList: StatisticsAlert[]) => {
    const groupedShipNumAlerts = new Array();
    const groupedStatisticsAlertList = statisticsAlertList.reduce((result, currentValue) => {
        const shipName = currentValue.shipName !== '' ? currentValue.shipName : 'Unknown';
       
        (result[shipName] = result[shipName] || []).push(
            currentValue
        );
        return result;
    }, {});

    Object.entries(groupedStatisticsAlertList).forEach(([key]) => {
        const shipArray:any[] = [];
        shipArray.push(key);
        shipArray.push(groupedStatisticsAlertList[key].length);
        groupedShipNumAlerts.push(shipArray);
    });

    return groupedShipNumAlerts;
};

export const groupAlertsByShip = (statisticsAlertList: StatisticsAlert[]) => {
    const groupedStatisticsAlertList = statisticsAlertList.reduce((result, currentValue) => {
        const shipName = currentValue.shipName;
        (result[shipName] = result[shipName] || []).push(
            currentValue
        );
        return result;
    }, {});

    return groupedStatisticsAlertList;
};

export const groupAlertsPrAlertResponsible = (statisticsAlertList: StatisticsAlert[]) => {
    const groupedAlertResponsibelAlertList = statisticsAlertList.reduce((result, currentValue) => {
        const assignee = currentValue.assignee;
        (result[assignee] = result[assignee] || []).push(
            currentValue
        );
        return result;
    }, {});
    
    return groupedAlertResponsibelAlertList;
};

export const groupAlertTypes = (statisticsAlertList: StatisticsAlert[], alertTypeList) => {
    const groupedAlertTypeList = statisticsAlertList.reduce((result, currentValue) => {
        const alertType = alertTypeList.find(alertType => alertType.value === currentValue.alertTypeId.toString());
        if(alertType){
            (result[alertType?.label] = result[alertType?.label] || []).push(
                currentValue
            );
        }
        return result;
    }, {});
    
    return groupedAlertTypeList;
};

export const generateHighChartsArray = (groupedStatsArray: any) => {
    const highChartsArray: HighChartModel[] = [];

    Object.entries(groupedStatsArray).forEach(([key, value]) => {
        const highChartModel: HighChartModel = {
            name: key,
            fullname: key,
            y: groupedStatsArray[key].length
        };
        highChartsArray.push(highChartModel);
    });

    return highChartsArray;
};