import styled from "styled-components";

export const TextWrapper = styled.div`
    border: 1px solid #12427d;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: #12427d;
    cursor: default;
    height: 25px;
`;


export const Title = styled.div`
    font-weight: 500;
`;
