import {AlertStatusMap} from "../types/ViewModel/AlertStatusMap";
import {AlertStatus} from '../enums/AlertStatus';
import {AlertSavingsStatus} from "../enums/AlertSavingsStatus";
import {AlertSavingsStatusMap} from "../types/ViewModel/AlertSavingsStatusMap";

export const GetAlertStatusMap = (alertStatusId: AlertStatus) => {
    var statusMap = new AlertStatusMap(false, false, false, false, false);
    switch (alertStatusId) {
        case AlertStatus.New:
            statusMap.completeIsEnabled = true;
            statusMap.excludeIsEnabled = true;
            statusMap.inProgressIsEnabled = true;
            break;
        case AlertStatus.InProgress:
            statusMap.completeIsEnabled = true;
            statusMap.excludeIsEnabled = true;
            break;
        case AlertStatus.Excluded:
            statusMap.completeIsEnabled = true;
            statusMap.inProgressIsEnabled = true;
            break;
        case AlertStatus.Completed:
            statusMap.reOpenIsEnabled = true;
            break;
        case AlertStatus.Muted:
            break;
    }
    return statusMap;
};

export const GetAlertStatusName = (alertStatusId: AlertStatus) => {
    switch (alertStatusId) {
        case AlertStatus.New:
            return 'New';
        case AlertStatus.InProgress:
            return 'In progress';
        case AlertStatus.Excluded:
            return 'Excluded';
        case AlertStatus.Completed:
            return 'Completed';
        case AlertStatus.Muted:
            return 'Muted';
    }
    return '';
};

export const GetAlertSavingsStatusMap = (alertSavingsStatusId: AlertSavingsStatus) => {
    var statusMap = new AlertSavingsStatusMap(false, false, false);
    switch (alertSavingsStatusId) {
        case AlertSavingsStatus.NoSavings:
            break;
        case AlertSavingsStatus.Draft:
            statusMap.sendToApprovalIsEnabled = true;
            break;
        case AlertSavingsStatus.PendingApproval:
            statusMap.approveIsEnabled = true;
            statusMap.rejectIsEnabled = true;
            break;
        case AlertSavingsStatus.Approved:
            break;
        case AlertSavingsStatus.Rejected:
            statusMap.sendToApprovalIsEnabled = true;
            break;
    }
    return statusMap;
};

export const GetAlertSavingsStatusName = (alertSavingsStatusId: AlertSavingsStatus) => {
    switch (alertSavingsStatusId) {
        case AlertSavingsStatus.NoSavings:
            return 'No savings';
        case AlertSavingsStatus.Draft:
            return 'Draft';
        case AlertSavingsStatus.PendingApproval:
            return 'Pending approval';
        case AlertSavingsStatus.Approved:
            return 'Approved';
        case AlertSavingsStatus.Rejected:
            return 'Rejected';
    }
    return '';
};
