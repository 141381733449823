import {AlertItemBM} from "../types/BusinessModel/AlertItemBM";
import {AlertItem} from "../types/ViewModel/AlertItem";
import {AlertContextFactory} from "./AlertContextFactory";
import {AlertCommentFactory} from "./AlertCommentFactory";
import {AlertTypeDetailsFactory} from "./AlertTypeDetailsFactory";

export class AlertItemFactory {
    _alertContextFactory: AlertContextFactory;
    _alertCommentFactory: AlertCommentFactory;
    _alertTypeDetailsFactory: AlertTypeDetailsFactory;

    constructor(
    ) {
        this._alertContextFactory = new AlertContextFactory();
        this._alertCommentFactory = new AlertCommentFactory();
        this._alertTypeDetailsFactory = new AlertTypeDetailsFactory();
    }

    public getAlertItem(item : AlertItemBM): AlertItem {
        return new AlertItem(
            item.alertId,
            // item.alertTypeDetails.alertTypeId,
            // item.alertTypeDetails.alertTypeName,
            this._alertTypeDetailsFactory.getAlertTypeDetails(item.alertTypeDetails),
            item.alertSourceId,
            item.alertSourceName,
            this._alertCommentFactory.getAlertCommentList(item.commentList),
            item.alertDate,
            item.aolDate,
            item.value,
            item.limit,
            item.hfoPrice,
            item.mgoPrice,
            item.severityId,
            item.severityName,
            item.priorityId,
            item.priorityName,
            item.assignee,
            item.alertStatusId,
            item.alertStatusName,
            item.alertSavingsStatusId,
            item.alertSavingsStatusName,
            item.totalSavingsInUsd,
            this._alertContextFactory.getAlertContext(item.alertContext),
            item.alertSavingsDescription,
            item.alertMutedUntil,
            item.createdUtc,
            item.createdBy,
            item.lastUpdatedUtc,
            item.lastUpdatedBy);
    }

    public createAlertItemFromCode(item: AlertItem): AlertItem {
        return new AlertItem(
            item.alertId,
            // item.alertTypeId,
            // item.alertTypeName,
            this._alertTypeDetailsFactory.getAlertTypeDetailsFromCode(item.alertTypeDetails),
            item.alertSourceId,
            item.alertSourceName,
            this._alertCommentFactory.getAlertCommentList(item.commentList),
            item.alertDate,
            item.aolDate,
            item.value,
            item.limit,
            item.hfoPrice,
            item.mgoPrice,
            item.severityId,
            item.severityName,
            item.priorityId,
            item.priorityName,
            item.assignee,
            item.alertStatusId,
            item.alertStatusName,
            item.alertSavingsStatusId,
            item.alertSavingsStatusName,
            item.totalSavingsInUsd,
            this._alertContextFactory.getAlertContext(item.alertContext),
            item.alertSavingsDescription,
            item.alertMutedUntil,
            item.createdUtc,
            item.createdBy,
            item.lastUpdatedUtc,
            item.lastUpdatedBy);
    }

    public getAlertItemList(alertItemListBM: AlertItemBM[]): AlertItem[] {
        return alertItemListBM ? alertItemListBM.map(o => this.getAlertItem(o)) : [];
    }

    public getAlertItemBM(item: AlertItem): any {
        return {
            alertId: item.alertId,
            alertTypeDetails: this._alertTypeDetailsFactory.getAlertTypeDetailsBM(item.alertTypeDetails),
            alertSourceId: item.alertSourceId,
            alertDate: item.alertDate,
            aolDate: item.aolDate,
            value: item.value,
            limit: item.limit,
            severityId: item.severityId,
            priorityId: item.priorityId,
            assignee: item.assignee,
            alertStatusId: item.alertStatusId,
            alertSavingsStatusId: item.alertSavingsStatusId,
            totalSavingsInUsd: item.totalSavingsInUsd,
            alertSavingsDescription: item.alertSavingsDescription,
            alertContext: this._alertContextFactory.getAlertContextBM(item.alertContext),
            alertMutedUntil: item.alertMutedUntil,
            createdUtc: item.createdUtc,
            createdBy: item.createdBy,
            lastUpdatedUtc: item.lastUpdatedUtc,
            lastUpdatedBy: item.lastUpdatedBy
        }
    }
}
