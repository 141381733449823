import { OverlayLoader } from './SpinnerLoader.styles';
import React from 'react'
import './SpinnerLoader.scss';

type Props = {
    showOverlay?: boolean
}

const SpinnerLoaderComponent: React.FC<Props> = props => {
    return (
        <>
            {
                props.showOverlay &&
                <OverlayLoader>
                    <div className="loader-wrapper">
                        <div className="lds-ripple"><div></div><div></div></div>
                        <div className="loader-text">Loading</div>
                    </div>
                </OverlayLoader>
            }
            {
                !props.showOverlay &&
                <div>
                    <div className="lds-ripple"><div></div><div></div></div>
                    <div className="loader-text">Loading</div>
                </div>
            }
        </>
    );
}

export default SpinnerLoaderComponent;
