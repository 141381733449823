import {AlertItem} from "../types/ViewModel/AlertItem";
import {User} from "../types/ViewModel/User";
import moment from "moment";
import {SelectedUser} from "../types/ViewModel/SelectedUser";
import {AlertSavingsPerUser} from "../types/ViewModel/AlertSavingsPerUser";
import {AlertSavingsStatus} from "../enums/AlertSavingsStatus";
import {AlertSavingsPerUserFactory} from "../factory/AlertSavingsPerUserFactory";

export const GetNumberOfSavingsPendingApproval = (alertList: AlertItem[]) => {
    return alertList.filter(o => o.alertSavingsStatusId === AlertSavingsStatus.PendingApproval).length;
};

export const GetSavingsLastMonth = (alertList: AlertItem[], loggedInUser: User | null | undefined) => {
    var lastMonth: Date = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 2); // test: 2 months instead of 1
    var totalSavings: number = 0;

    if (loggedInUser) { //&& (loggedInUser.role.roleType === RoleType.User)) {
        var alertListLastMonth: AlertItem[] = alertList.filter(o => moment(o.alertDate).isAfter(lastMonth)
            && loggedInUser.displayName === o.assignee
            && o.alertSavingsStatusId === AlertSavingsStatus.Approved);
        if (alertListLastMonth) {
            alertListLastMonth.forEach(o => {
                if (o.totalSavingsInUsd) {
                    totalSavings += o.totalSavingsInUsd;
                }
            });
        }
    }
    return totalSavings;
};

export const UpdateAlertSavingsPerUser = (alertSavingsPerUserList: AlertSavingsPerUser[], previousAssignee: string, newAssignee: string, savingsInUsd: number): AlertSavingsPerUser[] => {
    const factory = new AlertSavingsPerUserFactory();
    const savingsPrevious = alertSavingsPerUserList.find(o => o.username === previousAssignee);
    if (savingsPrevious) {
        savingsPrevious.savingsInUsd -= savingsInUsd;
    }
    const savingsNew = alertSavingsPerUserList.find(o => o.username === newAssignee);
    if (savingsNew) {
        savingsNew.savingsInUsd += savingsInUsd;
    } else {
        alertSavingsPerUserList.push(factory.createSavingsPerUser(newAssignee, savingsInUsd, 0));
    }
    return alertSavingsPerUserList;
};

export const AddAlertSavingsPerUser = (alertSavingsPerUserList: AlertSavingsPerUser[], assignee: string, savingsInUsd: number): AlertSavingsPerUser[] => {
    const factory = new AlertSavingsPerUserFactory();
    const savingsNew = alertSavingsPerUserList.find(o => o.username === assignee);
    if (savingsNew) {
        savingsNew.savingsInUsd += savingsInUsd;
    } else {
        alertSavingsPerUserList.push(factory.createSavingsPerUser(assignee, savingsInUsd, 0));
    }
    return alertSavingsPerUserList;
};

export const GetSavingsLastMonthForSelectedUsers = (selectedUserList: SelectedUser[] | undefined, alertSavingsPerUserList: AlertSavingsPerUser[]): number => {
    var totalSavings: number = 0;

    if (selectedUserList && selectedUserList.length > 0) {
        alertSavingsPerUserList.forEach(o => {
            const user = selectedUserList.find(x => x.label === o.username);
            if (user) {
                totalSavings += o.savingsInUsd;
            }
        });
    }
    return totalSavings;
};
