import {User} from "./User";
import {AlertPriority} from "../../enums/AlertPriority";
import {AlertStatus} from "../../enums/AlertStatus";
import {AlertType} from "./AlertType";
import {AlertSeverity} from "../../enums/AlertSeverity";
import {VesselOption} from "./VesselOption";

export class FilterAlertData {
    constructor(
        userList: User[],
        alertPriorityList: AlertPriority[],
        alertSeverityList: AlertSeverity[],
        alertStatusList: AlertStatus[],
        alertTypeList: AlertType[],
        vesselList: VesselOption[],
        updated: boolean
    ) {
        this.userList = userList;
        this.alertPriorityList = alertPriorityList;
        this.alertSeverityList = alertSeverityList;
        this.alertStatusList = alertStatusList;
        this.alertTypeList = alertTypeList;
        this.vesselList = vesselList;
        this.updated = updated;
    }
    userList: User[];
    alertPriorityList: AlertPriority[];
    alertSeverityList: AlertSeverity[];
    alertStatusList: AlertStatus[];
    alertTypeList: AlertType[];
    vesselList: VesselOption[];
    updated: boolean;
}
