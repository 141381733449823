import React, {useState} from 'react'
import {ContextProps} from '../types/ContextProps';
import {AlertItem} from "../types/ViewModel/AlertItem";

type AppAlertListContextType = {
    alertListView: AlertItem[],
    alertSavingsListView: AlertItem[],
    selectedAlertItem: AlertItem | null,
    isAlertItemUpdated: boolean,
    isLoadingAlertListView: boolean,
    isLoadingAlertSavingsListView: boolean,
    historicalAlerts: AlertItem[] | null,
    isLoadingHistoricalAlerts: boolean,
    filteredAlertListView: AlertItem[],
    filteredAlertSavingsListView: AlertItem[],
    paginatedFilteredAlerts: AlertItem[],
    paginatedFilteredAlertSavings: AlertItem[],
    numberOfFilteredAlerts: number,
    numberOfFilteredSavingsAlerts: number,
    setAlertListView: (alertList: AlertItem[]) => void,
    setAlertSavingsListView: (alertList: AlertItem[]) => void,
    setSelectedAlertItem: (alertItem: AlertItem | null) => void,
    setIsAlertItemUpdated: (boolean) => void,
    setIsLoadingAlertListView: (isLoading: boolean) => void,
    setIsLoadingAlertSavingsListView: (isLoading: boolean) => void,
    setHistoricalAlerts: (historicalAlerts: AlertItem[] | null) => void,
    setIsLoadingHistoricalAlerts: (boolean) => void,
    setFilteredAlertListView: (alertList: AlertItem[]) => void,
    setFilteredAlertSavingsListView: (alertList: AlertItem[]) => void,
    setPaginatedFilteredAlerts: (alertList: AlertItem[]) => void,
    setPaginatedFilteredAlertSavings: (alertList: AlertItem[]) => void,
    setNumberOfFilteredAlerts: (numberOfAlerts: number) => void,
    setNumberOfFilteredSavingsAlerts: (numberOfAlerts: number) => void,
}

export const AppAlertListContext = React.createContext<AppAlertListContextType | null>(null);

export const AppAlertListProvider = ({ children }: ContextProps) => {
    const [alertListView, setAlertListView] = useState<AlertItem[]>([]);
    const [alertSavingsListView, setAlertSavingsListView] = useState<AlertItem[]>([]);
    const [selectedAlertItem, setSelectedAlertItem] = useState<AlertItem | null>(null);
    const [isAlertItemUpdated, setIsAlertItemUpdated] = useState<boolean>(false);
    const [isLoadingAlertListView, setIsLoadingAlertListView] = useState<boolean>(false);
    const [isLoadingAlertSavingsListView, setIsLoadingAlertSavingsListView] = useState<boolean>(false);
    const [historicalAlerts, setHistoricalAlerts] = useState<AlertItem[] | null>(null);
    const [isLoadingHistoricalAlerts, setIsLoadingHistoricalAlerts] = useState<boolean>(false);
    const [filteredAlertListView, setFilteredAlertListView] = useState<AlertItem[]>([]);
    const [filteredAlertSavingsListView, setFilteredAlertSavingsListView] = useState<AlertItem[]>([]);
    const [paginatedFilteredAlerts, setPaginatedFilteredAlerts] = useState<AlertItem[]>([]);
    const [paginatedFilteredAlertSavings, setPaginatedFilteredAlertSavings] = useState<AlertItem[]>([]);
    const [numberOfFilteredAlerts, setNumberOfFilteredAlerts] = useState<number>(0);
    const [numberOfFilteredSavingsAlerts, setNumberOfFilteredSavingsAlerts] = useState<number>(0);

    return <AppAlertListContext.Provider value={{
        alertListView,
        alertSavingsListView,
        selectedAlertItem,
        isAlertItemUpdated,
        isLoadingAlertListView,
        isLoadingAlertSavingsListView,
        historicalAlerts,
        isLoadingHistoricalAlerts,
        filteredAlertListView,
        filteredAlertSavingsListView,
        paginatedFilteredAlerts,
        paginatedFilteredAlertSavings,
        numberOfFilteredAlerts,
        numberOfFilteredSavingsAlerts,
        setAlertListView,
        setAlertSavingsListView,
        setSelectedAlertItem,
        setIsAlertItemUpdated,
        setIsLoadingAlertListView,
        setIsLoadingAlertSavingsListView,
        setHistoricalAlerts,
        setIsLoadingHistoricalAlerts,
        setFilteredAlertListView,
        setFilteredAlertSavingsListView,
        setPaginatedFilteredAlerts,
        setPaginatedFilteredAlertSavings,
        setNumberOfFilteredAlerts,
        setNumberOfFilteredSavingsAlerts,
    }}>
        {children}
    </AppAlertListContext.Provider >
}
