import React, {useEffect} from 'react'
import styled from 'styled-components';
import {AuthContext} from "../../context/AuthContext";
import useGetVesselList from "../../api/useGetVesselList";
import useGetUserRole from "../../api/useGetUserRole";

const BackgroundImage = styled.div`
    position: relative;
    overflow: hidden;

    img {
        background-size: cover;
        width: 100%;
    }

    h1 {
        position: absolute;
        width: 100%;
        top: -28px;
        text-align: center;
        font-family: 'Pridi',serif;
        text-align: center;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        font-size: 4rem;
        font-weight: 300;
        line-height: 1;
    }
`;

export default () => {
    return (
        <BackgroundImage>
            <img src="../../../alarm-bg.png" alt="Logo"></img>
        </BackgroundImage>
    )
}
