import {AlertCategory} from "../../enums/AlertCategory";
import {AlertScope} from "../../enums/AlertScope";

export interface AlertTypeDetails {
}

export class AlertTypeDetails {
    constructor(
        alertTypeId: number,
        alertTypeName: string,
        alertCategoryId: AlertCategory,
        alertCategoryName: string,
        alertScopeId: AlertScope,
        alertScopeName: string
    ) {
        this.alertTypeId = alertTypeId;
        this.alertTypeName = alertTypeName;
        this.alertCategoryId = alertCategoryId;
        this.alertCategoryName = alertCategoryName;
        this.alertScopeId = alertScopeId;
        this.alertScopeName = alertScopeName;
    }
    alertTypeId: number;
    alertTypeName: string;
    alertCategoryId: AlertCategory;
    alertCategoryName: string;
    alertScopeId: AlertScope;
    alertScopeName: string;
}
