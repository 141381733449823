import styled, {css} from "styled-components";
import {IButtonProps} from "../../alert-list-filters/priority-filter/PriorityFilter.styles";

export const Div = styled.div`
    cursor: pointer;

    .active-link {
        border-bottom: solid 1px;
    }
    padding: 0 25px;

   .disabled {
        pointer-events: none;
        color: #ccc;
    }
`;

export const StyledDiv = styled.div<IButtonProps>`
  cursor: pointer;
  padding: 0 15px;
  &.is-selected {
    border-bottom: 2px solid #007bff;
    padding-bottom: 4px;
  }
`;

export const Icon = styled.div`
  width: 20px;
  height: 19px;
  text-align: center;
  border-radius: 50%;
  padding: 5px;
  background: #12427d;
  color: #fff;
  margin: 3px auto;
  svg{
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const StyledLabel = styled.span<IButtonProps>`    
   display: block;
   margin: 0; 
   color: #12427d;
    font-size: 13px;
   ${({ isSelected }) =>
    isSelected && css`
      font-weight: bold;
      border-bottom: 1px solid;
    `}
`;

export const StyledButton = styled.button<IButtonProps>`
  background: #00aeef;
  border: 1px solid #00aeef;
  color: #fff;
  height: 24px;
  padding: 2px 8px;
  font-size: 12px;
  margin: 1px;
  outline: 0;
  cursor: pointer;
  border-radius: 3px;

  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;
