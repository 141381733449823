import {ChangeLogBM} from "../types/BusinessModel/ChangeLogBM";
import {ChangeLog} from "../types/ViewModel/ChangeLog";

export class ChangeLogFactory {
    constructor(
    ) {
    }

    public getChangeLogItem(item: ChangeLogBM): ChangeLog {
        return new ChangeLog(
            item.alertChangeId,
            item.alertSavingsPerFuelTypeId,
            item.alertChangeTypeId,
            item.alertChangeTypeName,
            item.oldValue,
            item.newValue,
            item.lastUpdatedUtc,
            item.lastUpdatedBy);
    }

    public getChangeLogList(changeLogListBM: ChangeLogBM[]): ChangeLog[] {
        return changeLogListBM.map(o => this.getChangeLogItem(o));
    }
}
