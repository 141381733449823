import { Wrapper, LeftContentWrapper, RightContentWrapper, Title, IconWrapper} from './PageHeader.styles';
import React from 'react';
import {ThemeContext} from 'styled-components';
import HeaderItem from './header-item/HeaderItem';
import SavingsPendingApprovalComponent from './savings-pending-approval/SavingsPendingApproval'
import SavingsLastMonthComponent from './savings-last-month/SavingsLastMonth';
import MyUnresolvedAlertsComponent from './my-unresolved-alerts/MyUnresolvedAlerts';
import UserInfoComponent from '../user-info/UserInfo';


export default () => {
    const theme = React.useContext(ThemeContext);
   
    return (
        <Wrapper theme={theme}>
            <LeftContentWrapper>
                <Title>Centralized Alert Dashboard</Title>
            </LeftContentWrapper>
            <RightContentWrapper>
                <SavingsPendingApprovalComponent />
                <SavingsLastMonthComponent />
                <MyUnresolvedAlertsComponent />
                <IconWrapper>
                    <HeaderItem title={'Alerts'} path={'alertoverview'} disabled={false} />
                    <HeaderItem title={'Savings'} path={'savingsoverview'} disabled={false} />
                    <HeaderItem title={'Statistics'} path={'statistics'} disabled={false} />
                </IconWrapper>
                <UserInfoComponent />
            </RightContentWrapper>
        </Wrapper>
    );
}
