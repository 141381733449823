import React, {useState} from 'react'
import { ContextProps } from '../types/ContextProps';
import { User } from '../types/ViewModel/User';

type AuthContextType = {
    loggedInUser: User | null,
    setLoggedInUser: (user: User | null) => void,
    userIsUpdated: boolean,
    setUserIsUpdated: (isUpdated: boolean) => void,
    rolesAreUpdated: boolean,
    setRolesAreUpdated: (isUpdated: boolean) => void,
    isAuthenticating: boolean,
    setIsAuthenticating: (isAuthenticating: boolean) => void,
    isVerifyingToken: boolean,
    setIsVerifyingToken: (isVerifyingToken: boolean) => void,
    token: string,
    setToken: (token: string) => void,
}

export const AuthContext = React.createContext<Partial<AuthContextType>>({});

export const AuthContextProvider = ({ children }: ContextProps) => {
    const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
    const [userIsUpdated, setUserIsUpdated] = useState<boolean>(false);
    const [rolesAreUpdated, setRolesAreUpdated] = useState<boolean>(false);
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
    const [isVerifyingToken, setIsVerifyingToken] = useState<boolean>(true);
    const [token, setToken] = useState<string>('');

    return <AuthContext.Provider value={{
        loggedInUser,
        setLoggedInUser,
        userIsUpdated,
        setUserIsUpdated,
        rolesAreUpdated,
        setRolesAreUpdated,
        isAuthenticating,
        setIsAuthenticating,
        isVerifyingToken,
        setIsVerifyingToken, 
        token, 
        setToken,
    }}>
        {children}
    </AuthContext.Provider >
}
