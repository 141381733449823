import {AlertDetails} from "../types/ViewModel/AlertDetails";
import {AlertDetailsBM} from "../types/BusinessModel/AlertDetailsBM";
import {AlertItemFactory} from "./AlertItemFactory";
import {AlertCommentFactory} from "./AlertCommentFactory";
import {AlertSavingsPerFuelTypeFactory} from "./AlertSavingsPerFuelTypeFactory";
import {ChangeLogFactory} from "./ChangeLogFactory";

export class AlertDetailsFactory {
    constructor(
    ) {
    }

    public getAlertDetails(item: AlertDetailsBM): AlertDetails {
        const alertItemFactory = new AlertItemFactory();
        const alertSavingsPerFuelTypeFactory = new AlertSavingsPerFuelTypeFactory();
        const changeLogFactory = new ChangeLogFactory();
        return new AlertDetails(
            alertItemFactory.getAlertItem(item.alertItem),
            changeLogFactory.getChangeLogList(item.changeLog),
            alertSavingsPerFuelTypeFactory.getAlertSavingsPerFuelTypeList(item.alertSavingsPerFuelTypeList)
            );
    }
}
