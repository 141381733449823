export class StatisticsAlert {
    constructor(
        alertId: number,
        alertTypeId: number,
        alertStatusId: number,
        savingsStatusId: number,
        alertDate: Date,
        assignee: string,
        totalSavingsInUsd: number,
        imoNumber: number,
        shipName: string,
        reportingGroup: string
    ) {
        this.alertId = alertId;
        this.alertTypeId = alertTypeId;
        this.alertStatusId = alertStatusId;
        this.savingsStatusId = savingsStatusId;
        this.alertDate = alertDate;
        this.assignee = assignee;
        this.totalSavingsInUsd = totalSavingsInUsd;
        this.imoNumber = imoNumber;
        this.shipName = shipName;
        this.reportingGroup = reportingGroup;
    }
    alertId: number;
    alertTypeId: number;
    alertStatusId: number;
    savingsStatusId: number;
    alertDate: Date;
    assignee: string;
    totalSavingsInUsd: number;
    imoNumber: number;
    shipName: string;
    reportingGroup: string;
}
