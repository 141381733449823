export class SelectedUser {
    constructor(
        label: string,
        value: string
    ) {
        this.label = label;
        this.value = value;
    }
    label: string;
    value: string;
}
