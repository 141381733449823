import './Details.scss';
import {
    ButtonLeftContentWrapper,
    ButtonRightContentWrapper,
    CancelSaveButtonWrapper,
    DropdownWrapper,
    EditButtonWrapper,
    RightContentWrapper,
    StyledButton,
    Wrapper
} from './Details.styles';
import React, {useContext, useEffect, useState} from 'react'
import usePostAlertItem from '../../../api/usePostAlertItem';
import {PrettifyDate} from '../../../utils/dateUtils';
import {SetProcedureUrlForAlert} from '../../../utils/alertTypeUtils';
import {SelectedUser} from "../../../types/ViewModel/SelectedUser";
import MultiSelect from "react-multi-select-component";
import {AuthContext} from "../../../context/AuthContext";
import {AlertItemFactory} from "../../../factory/AlertItemFactory";
import {AlertTypeMute} from "../../../types/ViewModel/AlertTypeMute";
import DatePicker from "react-datepicker";
import {CanEdit} from "../../../utils/userUtils";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {
}

const DetailsComponent: React.FC<Props> = () => {
    var [isMuted, setIsMuted] = useState<boolean>(false);
    var [isAssigneeUpdated, setIsAssigneeUpdated] = useState<boolean>(false);
    var [isMuteDateUpdated, setIsMuteDateUpdated] = useState<boolean>(false);
    var [alertTypeMute, setAlertTypeMute] = useState<AlertTypeMute | null>(null);
    var [mutedUntilDate, setMutedUntilDate] = useState<Date>(new Date());
    var [initialMutedUntilDate, setInitialMutedUntilDate] = useState<Date>(new Date());
    var [initialSelected, setInitialSelected] = useState<SelectedUser | null>(null);
    var [selected, setSelected] = useState<SelectedUser[]>([]);
    var [prevSelected, setPrevSelected] = useState<SelectedUser | null>(null);
    var [btnEnabled, setBtnEnabled] = useState<boolean>(false);
    var [formChanged, setFormChanged] = useState<boolean>(false);

    const { updateAlertItem } = usePostAlertItem();
    const {
        alertDetails,
        adGroupUserList,
        alertTypeList
    } = useContext(AppStateContext)!;
    const {
        isAlertItemUpdated,
        setIsAlertItemUpdated,
    } = useContext(AppAlertListContext)!;
    const { loggedInUser } = React.useContext(AuthContext);
    const options: SelectedUser[] = adGroupUserList.map(o => new SelectedUser(o.displayName, String(o.id)));
    const alertItemFactory = new AlertItemFactory();

    const initDetails = () => {
        if (alertDetails && alertDetails.alertItem) {
            alertDetails.alertItem.alertTypeProcedureUrl = SetProcedureUrlForAlert(alertDetails.alertItem, alertTypeList);
            var selList = initSelected();
            if (selList.length > 0) {
                setInitialSelected(selList[0]);
                setPrevSelected(selList[0]);
            }
            setSelected(initSelected());
            setBtnEnabled(true);
            var alertType = alertTypeList.find(o => o.value === String(alertDetails.alertItem.alertTypeDetails.alertTypeId));
            if (alertType) {
                var alertTypeMute = alertType.alertTypeMuteList.find(o => o.imoNumber === alertDetails.alertItem.alertContext.imoNumber);
                if (alertTypeMute) {
                    setIsMuted(true);
                    var date = new Date(alertTypeMute.mutedUntilDate);
                    setMutedUntilDate(date);
                    setInitialMutedUntilDate(date);
                    setAlertTypeMute(alertTypeMute);
                } else {
                    setIsMuted(false);
                }
            } else {
                setIsMuted(false);
            }
        }
    };

    useEffect(() => {
        if (isAlertItemUpdated) {
            initDetails();
            setIsAlertItemUpdated(false);
        }
    }, [isAlertItemUpdated]);

    useEffect(() => {
        initDetails();
    }, [alertDetails]);

    const getInitialSelected = (): SelectedUser[] => {
        if (initialSelected) {
            return [
                new SelectedUser(initialSelected.label, initialSelected.value)
            ];
        }
        return [];
    };
    const getAlertTypeProcedureLink = () => {
        return (alertDetails && alertDetails.alertItem.alertTypeProcedureUrl)
          ? <a href={alertDetails.alertItem.alertTypeProcedureUrl} target="_blank">Click to view procedure</a>
          : <div>N/A</div>;
    }

    const initSelected = (): SelectedUser[] => {
        if (alertDetails && alertDetails.alertItem) {
            const user = adGroupUserList.find(o => o.displayName === alertDetails.alertItem.assignee);
            if (user) {
                return [
                    new SelectedUser(alertDetails.alertItem.assignee, String(user.id))
                ];
            }
            return [];
        }
        return [];
    };

    const handleChangeEditAssignee = (selectedItems) => {
        if (!selectedItems || selectedItems.length === 0) {
            prevSelected = null;
            setPrevSelected(prevSelected);
            isAssigneeUpdated = false
            setIsAssigneeUpdated(isAssigneeUpdated);
            setFormUpdated();
        } else {
            if (prevSelected) {
                // @ts-ignore
                const ind =  selectedItems.findIndex((o: SelectedUser) => o.value === prevSelected.value);
                if (ind !== -1) {
                    selectedItems.splice(ind, 1);
                    prevSelected = selectedItems[0]
                    setPrevSelected(prevSelected);
                }
            } else {
                prevSelected = selectedItems[0]
                setPrevSelected(prevSelected);
            }
            if (initialSelected) {
                if (selectedItems[0].label === initialSelected.label) {
                    isAssigneeUpdated = false;
                } else {
                    isAssigneeUpdated = true;
                }
                setIsAssigneeUpdated(isAssigneeUpdated);
                setFormUpdated();
            }
        }
        setSelected(selectedItems);
    };

    const saveBtn = () => () => {
        setBtnEnabled(true);
        // Post update assignee
        if (alertDetails && alertDetails.alertItem) {
            const postAlertItem = alertItemFactory.createAlertItemFromCode(alertDetails.alertItem);
            postAlertItem.assignee = selected[0].label;
            if (isMuted && mutedUntilDate) {
                postAlertItem.alertMutedUntil = mutedUntilDate;
            }
            postAlertItem.lastUpdatedUtc = new Date();
            postAlertItem.lastUpdatedBy = (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A'
            updateAlertItem(postAlertItem);
        }
        setInitialSelected(selected[0]);
        setFormChanged(true);
    };

    const cancelBtn = () => () => {
        setBtnEnabled(true);
        setSelected(getInitialSelected());
        setMutedUntilDate(initialMutedUntilDate);
        setPrevSelected(initialSelected);
    };

    const setFormUpdated = () => {
        setFormChanged((isMuteDateUpdated || isAssigneeUpdated) && (prevSelected != null));
    }

    const onDateChanged = (date: Date) => {
        setMutedUntilDate(date);
        if (
            date.getFullYear() !== initialMutedUntilDate.getFullYear() ||
            date.getMonth() !== initialMutedUntilDate.getMonth() ||
            date.getDay() !== initialMutedUntilDate.getDay()) {
            isMuteDateUpdated = true;
        } else {
            isMuteDateUpdated = false;
        }
        setIsMuteDateUpdated(isMuteDateUpdated);
        setFormUpdated();
    };

    return (
        <>
            { alertDetails && alertDetails.alertItem && 
            <Wrapper>
                {CanEdit(loggedInUser) &&
                    <CancelSaveButtonWrapper>
                        <RightContentWrapper>
                            <StyledButton className="cancel-btn" disabled={false} isSelected={true}
                                          onClick={cancelBtn()}>Cancel</StyledButton>
                            <StyledButton className="primary-btn" disabled={!formChanged} isSelected={true}
                                          onClick={saveBtn()}>Save</StyledButton>
                        </RightContentWrapper>
                    </CancelSaveButtonWrapper>
                }

                <table className="details-table">
                    <tbody>
                        <tr className="details-row">
                            <td className="details-key-col">Vessel</td>
                            <td>{alertDetails.alertItem.alertContext.vesselName}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Alert date</td>
                            <td>{PrettifyDate(alertDetails.alertItem.alertDate)}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">AoL date</td>
                            <td>{PrettifyDate(alertDetails.alertItem.aolDate)}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Status</td>
                            <td>{alertDetails.alertItem.alertStatusName}</td>
                        </tr>
                        {isMuted && alertTypeMute &&
                            <tr className="details-row">
                                <td className="details-key-col">Muted until</td>
                                <td className="details-val-col">
                                    <DatePicker className="from-date-picker" disabled={!CanEdit(loggedInUser)} dateFormat="dd.MM.yyyy" selected={mutedUntilDate} onChange={onDateChanged}/>
                                </td>
                            </tr>
                        }
                        {isMuted && alertTypeMute &&
                            <tr className="details-row">
                                <td className="details-key-col">Mute created</td>
                                <td>{PrettifyDate(alertTypeMute.createdUtc)}</td>
                            </tr>
                        }
                        {isMuted && alertTypeMute &&
                            <tr className="details-row">
                                <td className="details-key-col">Mute created by</td>
                                <td>{alertTypeMute.createdBy}</td>
                            </tr>
                        }
                        {isMuted && alertTypeMute &&
                        <tr className="details-row">
                            <td className="details-key-col">Mute last updated</td>
                            <td>{PrettifyDate(alertTypeMute.lastUpdatedUtc)}</td>
                        </tr>
                        }
                        {isMuted && alertTypeMute &&
                        <tr className="details-row">
                            <td className="details-key-col">Mute last updated by</td>
                            <td>{alertTypeMute.lastUpdatedBy}</td>
                        </tr>
                        }
                        <tr className="details-row">
                            <td className="details-key-col-assignee">Assignee</td>
                            <td className="details-val-col">
                                <DropdownWrapper>
                                    <MultiSelect
                                        options={options}
                                        value={selected}
                                        onChange={handleChangeEditAssignee}
                                        labelledBy={"Select"}
                                        disabled={!CanEdit(loggedInUser)}
                                        hasSelectAll={false}
                                    />
                                </DropdownWrapper>
                            </td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Priority</td>
                            <td>{alertDetails.alertItem.priorityName}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Severity</td>
                            <td>{alertDetails.alertItem.severityName}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Value</td>
                            <td>{alertDetails.alertItem.value?.toLocaleString('no-NO')}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Limit</td>
                            <td>{alertDetails.alertItem.limit?.toLocaleString('no-NO')}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Cargo Number</td>
                            <td>{alertDetails.alertItem.alertContext.cargoNumber}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Tank Name</td>
                            <td>{alertDetails.alertItem.alertContext.tankName}</td>
                        </tr>
                        <tr className="details-row">
                            <td className="details-key-col">Procedure</td>
                            <td>
                                {getAlertTypeProcedureLink()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Wrapper>
            }
        </>
    );
};

export default DetailsComponent;
