import { useContext, useEffect } from 'react';
import useApi from './useApi';
import { VesselBM } from '../types/BusinessModel/VesselBM';
import {VesselFactory} from "../factory/VesselFactory";
import {AppStateContext} from "../context/AppStateContext";

export default () => {
    const endpoint = 'GetShipList';
    const { state, getApiData } = useApi();
    const { setVesselList, setFilteredVesselList, setIsLoadingVesselList, setIsVesselListRetrieved
    } = useContext(AppStateContext)!;

    const vesselFactory = new VesselFactory();

    const getVesselList = () => {
        getApiData(endpoint, '');
    }

    useEffect(() => {
        setIsLoadingVesselList(state.isLoading)
        if (state.status === 'success'){
            const vessels = state.result as VesselBM[];
            const vesselListView = vesselFactory.getVesselList(vessels);
            setVesselList(vesselListView);
            //setFilteredVesselList(vesselListView);
            setIsVesselListRetrieved(true);
        }

    }, [state])

    return { state, getVesselList };
}
