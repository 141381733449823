import './DetailsOverview.scss';
import {DetailWrapper, Title} from './DetailsOverview.styles';
import {
    ButtonLeftContentWrapper,
    EditButtonWrapper,
    StyledButton
} from "../details/Details.styles";
import React, {useContext, useEffect, useState} from 'react'
import SpinnerLoader from "../../loader/SpinnerLoader";
import {AlertDetails} from "../../../types/ViewModel/AlertDetails";
import AlertDetailsTabsComponent from '../alert-details-tabs/AlertDetailsTabs';
import {DetailComponent} from "../../../enums/DetailComponent";
import {GetAlertStatusMap} from "../../../utils/workFlowUtils";
import WorkflowCompletedComponent from "../workflow-completed/WorkflowCompleted";
import WorkflowExcludedComponent from "../workflow-excluded/WorkflowExcluded";
import WorkflowInprogressComponent from "../workflow-inprogress/WorkflowInprogress";
import SavingsWorkflowRegisteredComponent from "../savings-workflow-registered/SavingsWorkflowRegistered";
import SavingsWorkflowApprovedComponent from "../savings-workflow-approved/SavingsWorkflowApproved";
import SavingsWorkflowRejectedComponent from "../savings-workflow-rejected/SavingsWorkflowRejected";
import {AlertStatusMap} from "../../../types/ViewModel/AlertStatusMap";
import WorkflowReopenComponent from "../workflow-reopen/WorkflowReopen";
import {AuthContext} from "../../../context/AuthContext";
import {AlertListMode} from "../../../enums/AlertListMode";
import {CanEdit} from "../../../utils/userUtils";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {

}

const DetailsOverviewComponent: React.FC<Props> = () => {
    const {
        alertDetails,
        detailComponentId,
        setDetailComponentId,
        setPreviousDetailTab,
        isLoadingAlertDetails,
        alertListMode
    } = useContext(AppStateContext)!;
    const {
        isAlertItemUpdated,
        setIsAlertItemUpdated,
    } = useContext(AppAlertListContext)!;
    const { loggedInUser } = React.useContext(AuthContext);
    var [alertStatusMap, setAlertStatusMap] = useState<AlertStatusMap>(new AlertStatusMap(false, false, false, false, false));

    useEffect(() => {
        if (alertDetails && alertDetails.alertItem) {
            var detId = detailComponentId;
        }
    }, [detailComponentId]);

    useEffect(() => {
        initDetails();
    }, [alertDetails]);

    useEffect(() => {
        if (isAlertItemUpdated) {
            initDetails();
            setIsAlertItemUpdated(false);
        }
    }, [isAlertItemUpdated]);

    const initDetails = () => {
        if (alertDetails && alertDetails.alertItem) {
            setAlertStatusMap(GetAlertStatusMap(alertDetails.alertItem.alertStatusId));
        }
    };

    const getAlertTypeName = (alertDetails: AlertDetails | null) => {
        if (alertDetails && alertDetails.alertItem) {
            return alertDetails.alertItem.alertTypeDetails.alertTypeName;
        }
        return '';
    };

    const excludeBtn = () => () => {
        setPreviousDetailTab('detailsTab');
        setDetailComponentId(DetailComponent.AlertWorkflowExclude);
    };

    const inProgressBtn = () => () => {
        setPreviousDetailTab('detailsTab');
        setDetailComponentId(DetailComponent.AlertWorkflowInProgress);
    };

    const completeBtn = () => () => {
        setPreviousDetailTab('detailsTab');
        setDetailComponentId(DetailComponent.AlertWorkflowComplete);
    };

    const reOpenBtn = () => () => {
        setPreviousDetailTab('detailsTab');
        setDetailComponentId(DetailComponent.AlertWorkflowReopen);
    };

    return (
        <>
            {alertDetails &&
            <div className="alert-details-wrapper">
                {
                    isLoadingAlertDetails &&
                    <SpinnerLoader showOverlay={true} />
                }
                <Title>{getAlertTypeName(alertDetails)}</Title>
                <DetailWrapper>
                    {(CanEdit(loggedInUser) && alertListMode === AlertListMode.Default &&
                        (alertStatusMap.completeIsEnabled || alertStatusMap.excludeIsEnabled || alertStatusMap.inProgressIsEnabled || alertStatusMap.reOpenIsEnabled)) &&
                        <div className="workflow-buttons-wrapper">
                            <ButtonLeftContentWrapper>
                                {alertStatusMap.inProgressIsEnabled &&
                                <StyledButton className="in-progress-btn" disabled={false} isSelected={true}
                                              onClick={inProgressBtn()}>Start</StyledButton>
                                }
                                {alertStatusMap.completeIsEnabled &&
                                <StyledButton className="complete-btn" disabled={false} isSelected={true}
                                              onClick={completeBtn()}>Complete</StyledButton>
                                }
                                {alertStatusMap.excludeIsEnabled &&
                                <StyledButton className="exclude-btn" disabled={false} isSelected={true}
                                              onClick={excludeBtn()}>Exclude</StyledButton>
                                }
                                {alertStatusMap.reOpenIsEnabled &&
                                <StyledButton className="reopen-btn" disabled={false} isSelected={true}
                                              onClick={reOpenBtn()}>Reopen</StyledButton>
                                }
                            </ButtonLeftContentWrapper>
                        </div>
                    }
                    <div className="workflow-content-wrapper">
                        {detailComponentId === DetailComponent.Tabs &&
                            <AlertDetailsTabsComponent/>
                        }
                        {detailComponentId === DetailComponent.AlertWorkflowComplete &&
                            <WorkflowCompletedComponent/>
                        }
                        {detailComponentId === DetailComponent.AlertWorkflowExclude &&
                            <WorkflowExcludedComponent/>
                        }
                        {detailComponentId === DetailComponent.AlertWorkflowInProgress &&
                            <WorkflowInprogressComponent/>
                        }
                        {detailComponentId === DetailComponent.AlertWorkflowReopen &&
                            <WorkflowReopenComponent/>
                        }
                        {detailComponentId === DetailComponent.SavingsWorkflowRegister &&
                            <SavingsWorkflowRegisteredComponent/>
                        }
                        {detailComponentId === DetailComponent.SavingsWorkflowApprove &&
                            <SavingsWorkflowApprovedComponent/>
                        }
                        {detailComponentId === DetailComponent.SavingsWorkflowReject &&
                            <SavingsWorkflowRejectedComponent/>
                        }
                    </div>
                </DetailWrapper>
            </div>
            }
        </>
    );
};

export default DetailsOverviewComponent;
