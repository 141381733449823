import {AdGroupMember} from "../types/ViewModel/AdGroupMember";
import {AzureAdGroupDataBM} from "../types/BusinessModel/AzureAdGroupDataBM";
import {AzureAdGroupMemberFactory} from "./AzureAdGroupMemberFactory";

export class AzureAdGroupDataFactory {
    private adGroupMemberFactory: AzureAdGroupMemberFactory;
    constructor(
    ) {
        this.adGroupMemberFactory = new AzureAdGroupMemberFactory();
    }

    public getAdGroupData(adGroupMemberBM: AzureAdGroupDataBM): AdGroupMember[] {
        return this.adGroupMemberFactory.getAdGroupMemberList(adGroupMemberBM.adGroupMemberList);
    }
}
