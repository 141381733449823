import React, {useContext, useEffect, useState} from 'react';
import {ContentWrapper, DropdownWrapper, TextWrapper, Title, Wrapper} from "./LastPeriodFilter.styles";
import {AppStateContext} from "../../../context/AppStateContext";
import { SelectOption } from '../../../types/ViewModel/SelectOption';
import moment from 'moment';
import Select from 'react-select';

type Props = {}

const LastPeriodFilterComponent: React.FC<Props> = () => {
    const [optionPeriods, setOptionPeriods] = useState<SelectOption[]>([
                                                {label: 'Last month', value: 'LAST_MONTH'},
                                                {label: 'Last quarter', value: 'LAST_QUARTER'},
                                                {label: 'Last 6 months', value: 'LAST_SIX_MONTH'},
                                                {label: 'Last year', value: 'LAST_YEAR'},
                                                {label: 'Last 3 years', value: 'LAST_THREE_YEARS'}
                                            ]);

    const {
        statisticsAlertList,
        selectedStatisticsPeriod,
        setSelectedStatisticsPeriod,
    } = useContext(AppStateContext)!;

    useEffect(() => {
        if(statisticsAlertList && statisticsAlertList.length > 0){
            setSelectedStatisticsPeriod(optionPeriods[1]);
        }
    }, [statisticsAlertList]);

    const styles = {
        control: (css) => ({ ...css, display: "inline-flex", width: "100%", height: 30, minHeight: 30 }),
        placeholder: (defaultStyles) => {
          return {
              ...defaultStyles,
              color: '#04265a',
              height: '24px'
          }
        },
        indicatorsContainer: (defaultStyles) => {
          return {
              ...defaultStyles,
              color: '#04265a',
              height: '30px'
          }
        }
    };

    const handleChange = (selectedItem: SelectOption) => {
        setSelectedStatisticsPeriod(selectedItem);
    };

    return (
        <>
            {optionPeriods && 
            <Wrapper>
               <ContentWrapper>
                    <TextWrapper>
                        <Title>Period</Title>
                    </TextWrapper>
                    <DropdownWrapper>
                        <Select className="select-container"
                            value={selectedStatisticsPeriod}
                            options={optionPeriods}
                            onChange={handleChange}
                            isSearchable={false}
                            styles={styles}
                        />
                    </DropdownWrapper>
                </ContentWrapper>
            </Wrapper>
            }
        </>
    );
}

export default LastPeriodFilterComponent;
