export const snackbarOptionsOk = {
    position: 'top-center'
    // style: {
    //     backgroundColor: 'midnightblue',
    //     border: '2px solid lightgreen',
    //     color: 'lightblue',
    //     fontFamily: 'Menlo, monospace',
    //     fontSize: '20px',
    //     textAlign: 'center',
    // },
    // closeStyle: {
    //     color: 'lightcoral',
    //     fontSize: '16px',
    // },
};

export const snackbarOptionsError = {
    position: 'top-center',
    style: {
        backgroundColor: '#e91d26',
        // border: '2px solid lightgreen',
        // color: 'lightblue',
        // fontFamily: 'Menlo, monospace',
        // fontSize: '20px',
        // textAlign: 'center',
    },
    // closeStyle: {
    //     color: 'lightcoral',
    //     fontSize: '16px',
    // },
};
