import React, {useContext, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {ContentWrapper, DateWrapper, TextWrapper, Title} from '../DateFilter.styles';
import {AlertListMode} from "../../../enums/AlertListMode";
import {DateContext} from "../../../context/DateContext";
import {AppStateContext} from "../../../context/AppStateContext";
import moment from 'moment';
import './FromDateFilter.scss';
type Props = {}

const FromDateFilterComponent: React.FC<Props> = () => {
    const { alertListMode
    } = useContext(AppStateContext)!;
    const {
        alertListFromDate,
        setAlertListFromDate,
        setAlertListToDate,
        alertSavingsListFromDate,
        setAlertSavingsListFromDate,
    } = useContext(DateContext);

    const handleChangeAlertList = (date: Date) => {
        setAlertListFromDate(date);
        var toDateAlertList = moment(date).add(1, 'month').toDate();
        if(moment(toDateAlertList).isAfter()){
            toDateAlertList = moment().toDate();
        }
        setAlertListToDate(toDateAlertList);
    };

    const handleChangeAlertSavingsList = (date: Date) => {
        setAlertSavingsListFromDate(date);
    };

    return (
        <ContentWrapper>
            <TextWrapper>
                <Title>From </Title>
            </TextWrapper>
            <DateWrapper>
                {alertListMode === AlertListMode.Default &&
                    <DatePicker className="from-date-picker" dateFormat="dd.MM.yyyy" selected={alertListFromDate} onChange={handleChangeAlertList} />
                }
                {alertListMode === AlertListMode.Savings &&
                    <DatePicker className="from-date-picker" dateFormat="dd.MM.yyyy" selected={alertSavingsListFromDate} onChange={handleChangeAlertSavingsList} />
                }
            </DateWrapper>
        </ContentWrapper>
    );
}

export default FromDateFilterComponent;
