import React, {useContext, useEffect} from 'react';
import {SetFilterAlertType, SetFilterUser, SetFilterVessel} from "../utils/alertFilterUtils";
import {SelectedUserFactory} from "../factory/SelectedUserFactory";
import {AuthContext} from "../context/AuthContext";
import {IsAdmin} from "../utils/userUtils";
import useAppAlertList from "./useAppAlertList";
import {SetVesselNameForAlertList} from "../utils/vesselUtils";
import {AppStateContext} from "../context/AppStateContext";
import {AppAlertListContext} from "../context/AppAlertListContext";
import useGetAlertSavingsPerUserList from '../api/useGetAlertSavingsPerUserList';
import useGetAlertTypeList from '../api/useGetAlertTypeList';
import useGetAzureAdGroup from '../api/useGetAzureAdGroup';
import useGetUserRole from '../api/useGetUserRole';
import useGetVesselList from '../api/useGetVesselList';
import { RoleType } from '../enums/RoleType';

export default () => {
    const { loggedInUser } = React.useContext(AuthContext);
    const {
        adGroupUserList,
        filterAlertData,
        filterSavingsData,
        vesselList,
        alertTypeList,
        setFilterAlertData,
        setFilterSavingsData,
    } = useContext(AppStateContext)!;
    const {
        filterAlertList,
        filterSavingsAlertList,
    } = useAppAlertList()!;
    const {
        alertListView,
        setAlertListView,
        alertSavingsListView,
        setAlertSavingsListView
    } = useContext(AppAlertListContext)!;

    const selectedUserFactory = new SelectedUserFactory();

    const { getAdGroupMemberList} = useGetAzureAdGroup();
    const { getUserRole } = useGetUserRole();
    const { getVesselList } = useGetVesselList();
    const { getAlertTypeList } = useGetAlertTypeList();
    const { getAlertSavingsPerUserList } = useGetAlertSavingsPerUserList();

    useEffect(() => {
        if (alertListView.length > 0 && vesselList.length > 0) {
            SetVesselNameForAlertList(alertListView, vesselList);
            setAlertListView(alertListView);
        }
    }, [alertListView, vesselList]);

    useEffect(() => {
        if (alertSavingsListView.length > 0 && vesselList.length > 0) {
            SetVesselNameForAlertList(alertSavingsListView, vesselList);
            setAlertSavingsListView(alertSavingsListView);
        }
    }, [alertSavingsListView, vesselList]);

    useEffect(() => {
        if (
            filterAlertData &&
            filterSavingsData &&
            // alertListView.length > 0 &&
            vesselList.length > 0 &&
            alertTypeList.length > 0 &&
            adGroupUserList.length > 0) {
            if (!filterAlertData.updated) {
                filterAlertData.alertTypeList = SetFilterAlertType(alertTypeList);
                filterAlertData.vesselList = SetFilterVessel(filterAlertData.vesselList);
                if (loggedInUser && !IsAdmin(loggedInUser)) {
                    filterAlertData.userList = SetFilterUser(selectedUserFactory.getSelectedUser(adGroupUserList, loggedInUser.userName));
                } else {
                    filterAlertData.userList = SetFilterUser(selectedUserFactory.getSelectedUserList(adGroupUserList));
                }
                setFilterAlertData(filterAlertData);
            }
            if (!filterSavingsData.updated) {
                filterSavingsData.alertTypeList = SetFilterAlertType(alertTypeList);
                filterSavingsData.vesselList = SetFilterVessel(filterAlertData.vesselList);
                if (loggedInUser && !IsAdmin(loggedInUser)) {
                    filterSavingsData.userList = SetFilterUser(selectedUserFactory.getSelectedUser(adGroupUserList, loggedInUser.userName));
                } else {
                    filterSavingsData.userList = SetFilterUser(selectedUserFactory.getSelectedUserList(adGroupUserList));
                }
                setFilterSavingsData(filterSavingsData);
            }

            filterAlertList(alertListView, filterAlertData);
            filterSavingsAlertList(alertSavingsListView, filterSavingsData);
        }
    }, [alertListView, alertSavingsListView, vesselList, alertTypeList, adGroupUserList]);


    useEffect(() => {
        if (loggedInUser) {
            getVesselList();
            getAlertTypeList();
            getAlertSavingsPerUserList();
            getUserRole();
            getAdGroupMemberList(RoleType.Admin);
            getAdGroupMemberList(RoleType.User);
        }
    }, [loggedInUser]);


    return {  };
}
