import React, {useContext, useEffect, useState} from 'react';
import MultiSelect from "react-multi-select-component";
import {Wrapper, ContentWrapper, DropdownWrapper, TextWrapper, Title} from "./DropDownFilter.styles";
import {SetFilterAlertType} from '../../../utils/alertFilterUtils';
import {AlertListMode} from "../../../enums/AlertListMode";
import useAppAlertList from "../../../app/useAppAlertList";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {}

const AlertTypeFilterComponent: React.FC<Props> = () => {
    const {
        filterAlertData,
        filterSavingsData,
        alertListMode,
        alertTypeList
    } = useContext(AppStateContext)!;
    const {
        filterAlertList,
        filterSavingsAlertList,
    } = useAppAlertList()!;
    const {
        alertListView,
        alertSavingsListView
    } = useContext(AppAlertListContext)!;

    const setFilter = (selectedItems) => {
        setSelected(selectedItems);
        filterAlertData.alertTypeList = SetFilterAlertType(selectedItems);
        filterAlertData.updated = true;
        filterSavingsData.alertTypeList = SetFilterAlertType(selectedItems);
        filterSavingsData.updated = true;
        if (alertListMode === AlertListMode.Default) {
            filterAlertList(alertListView, filterAlertData);
        } else {
            filterSavingsAlertList(alertSavingsListView, filterSavingsData);
        }
    };

    useEffect(() => {
        if (alertTypeList && alertTypeList.length > 0) {
            setSelected(alertTypeList);
            filterAlertData.alertTypeList = SetFilterAlertType(alertTypeList);
            filterAlertData.updated = true;
            filterSavingsData.alertTypeList = SetFilterAlertType(alertTypeList);
            filterSavingsData.updated = true;
        }
    }, [alertTypeList]);

    const [selected, setSelected] = useState(alertTypeList);

    const handleChange = (selectedItems) => {
        setFilter(selectedItems);
    };

    let filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    return (
        <>
            <Wrapper>
                <ContentWrapper>
                    <TextWrapper>
                        <Title>Alert type</Title>
                    </TextWrapper>
                    <DropdownWrapper>
                        <MultiSelect
                            className="select-alert-type"
                            options={alertTypeList}
                            filterOptions = {filterOptions}
                            value={selected}
                            onChange={handleChange}
                            labelledBy={"Select"}
                            overrideStrings={{
                                selectSomeItems: "Select alert types",
                                allItemsAreSelected: "All",
                                selectAll: "Select All",
                                search: "Search",
                            }}
                        />
                    </DropdownWrapper>
                </ContentWrapper>
            </Wrapper>
        </>
    );
}

export default AlertTypeFilterComponent;
