import React, {useContext} from 'react'
import { AppAlertListContext } from '../../../context/AppAlertListContext';
import { AuthContext } from '../../../context/AuthContext';
import { GetNumberOfSavingsPendingApproval } from '../../../utils/savingsUtils';
import { IsAdmin } from '../../../utils/userUtils';
import { TextWrapper, Title } from './SavingsPendingApproval.styles';

type Props = {}

const SavingsPendingAprovalComponent: React.FC<Props> = () => {
  const { loggedInUser } = React.useContext(AuthContext);
  const { alertSavingsListView
  } = useContext(AppAlertListContext)!;

    return (
    IsAdmin(loggedInUser)
      ? <TextWrapper title="# of savings pending approval in selected period">
            <Title>Pending approval</Title>
            {GetNumberOfSavingsPendingApproval(alertSavingsListView).toLocaleString('no-NO')}
        </TextWrapper>
      : <></>
    );
}

export default SavingsPendingAprovalComponent;
