import React, {useContext, useEffect, useState} from 'react';
import MultiSelect from "react-multi-select-component";
import {ContentWrapper, DropdownWrapper, TextWrapper, Title, Wrapper} from "./DropDownFilter.styles";
import {SetFilterVessel} from '../../../utils/alertFilterUtils';
import {AlertListMode} from "../../../enums/AlertListMode";
import useAppAlertList from "../../../app/useAppAlertList";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {}

const VesselFilterComponent: React.FC<Props> = () => {
    const {
        filterAlertData,
        filterSavingsData,
        vesselList,
        alertListMode
    } = useContext(AppStateContext)!;
    const {
        filterAlertList,
        filterSavingsAlertList
    } = useAppAlertList()!;
    const {
        alertListView,
        alertSavingsListView,
    } = useContext(AppAlertListContext)!;

    const setFilter = (selectedItems) => {
        setSelected(selectedItems);
        filterAlertData.vesselList = SetFilterVessel(selectedItems);
        filterAlertData.updated = true;
        filterSavingsData.vesselList = SetFilterVessel(selectedItems);
        filterSavingsData.updated = true;
        if (alertListMode === AlertListMode.Default) {
            filterAlertList(alertListView, filterAlertData);
        } else {
            filterSavingsAlertList(alertSavingsListView, filterSavingsData);
        }
    };

    useEffect(() => {
        if (vesselList && vesselList.length > 0) {
            setSelected(vesselList);
            filterAlertData.vesselList = SetFilterVessel(vesselList);
            filterAlertData.updated = true;
            filterSavingsData.vesselList = SetFilterVessel(vesselList);
            filterSavingsData.updated = true;
//            setFilter(vesselList);
        }
    }, [vesselList]);

    const [selected, setSelected] = useState(vesselList);

    const handleChange = (selectedItems) => {
        setFilter(selectedItems);
    };

    let filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    return (
        <>
            <Wrapper>
                <ContentWrapper>
                    <TextWrapper>
                        <Title>Vessel</Title>
                    </TextWrapper>
                    <DropdownWrapper>
                        <MultiSelect
                            options={vesselList}
                            filterOptions = {filterOptions}
                            value={selected}
                            onChange={handleChange}
                            labelledBy={"Select"}
                            overrideStrings={{
                                selectSomeItems: "Select vessels",
                                allItemsAreSelected: "All",
                                selectAll: "Select All",
                                search: "Search"
                            }}
                        />
                    </DropdownWrapper>
                </ContentWrapper>
            </Wrapper>
        </>
    );
};

export default VesselFilterComponent;
