import './SavingsWorkflowApproved.scss';
import {
    AddCommentWrapper,
    CancelSaveRightContentWrapper,
    CommentHeaderWrapper,
    CommentTextWrapper,
    StyledButton,
    Title,
    Wrapper
} from './SavingsWorkflowApproved.styles';
import React, {useContext, useEffect, useRef, useState} from 'react'
import {AppStateContext} from "../../../context/AppStateContext";
import {DetailComponent} from "../../../enums/DetailComponent";
import usePostAlertSavings from "../../../api/usePostAlertSavings";
import {AlertSavingsStatus} from "../../../enums/AlertSavingsStatus";
import {AuthContext} from "../../../context/AuthContext";

type Props = {
}

const SavingsWorkflowApprovedComponent: React.FC<Props> = () => {
    const { updateSavingsStatus } = usePostAlertSavings();
    const { alertDetails, setDetailComponentId } = useContext(AppStateContext)!;
    const [commentTxt, setCommentTxt] = useState('');
    const commentTextInput = useRef(null);
    const { loggedInUser } = React.useContext(AuthContext);

    useEffect(() => {
        if (alertDetails && alertDetails.alertItem) {
            setCommentTxt('');
        }
    }, [alertDetails]);

    const saveBtn = () => () => {
        // Post update savings status
        if (alertDetails && alertDetails.alertItem) {
            updateSavingsStatus(AlertSavingsStatus.Approved, commentTxt, new Date(), (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A');
        }
        setCommentTxt('');
        // @ts-ignore
        commentTextInput.current.hidden = true;
        setDetailComponentId(DetailComponent.Tabs);
    };

    const cancelBtn = () => () => {
        setCommentTxt('');
        // @ts-ignore
        commentTextInput.current.hidden = true;
        setDetailComponentId(DetailComponent.Tabs);
    };

    const hasCommentTxt = (): boolean => {
        if (commentTxt) {
            return (commentTxt.length > 0);
        }
        return false;
    };

    return (
        <>
            {alertDetails &&
            <Wrapper>
                <AddCommentWrapper>
                    <CommentHeaderWrapper>
                        <Title>Send to approval</Title>
                    </CommentHeaderWrapper>

                    <CommentTextWrapper>
                        <input
                            className="input"
                            type="text"
                            ref={commentTextInput}
                            value={commentTxt}
                            placeholder="Comment"
                            onChange={e => setCommentTxt(e.target.value)}
                        />
                    </CommentTextWrapper>
                    <CancelSaveRightContentWrapper>
                        <StyledButton className="cancel-btn" disabled={false} isSelected={true} onClick={cancelBtn()}>Cancel</StyledButton>
                        <StyledButton className="primary-btn" disabled={!commentTxt || commentTxt.length === 0} isSelected={hasCommentTxt()} onClick={saveBtn()}>Save</StyledButton>
                    </CancelSaveRightContentWrapper>
                </AddCommentWrapper>
            </Wrapper>
            }
        </>
    );
};

export default SavingsWorkflowApprovedComponent;
