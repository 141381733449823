import React, {useContext, useEffect, useState} from 'react';
import MultiSelect from "react-multi-select-component";
import { AppStateContext } from '../../../context/AppStateContext';
import {ContentWrapper, DropdownWrapper, TextWrapper, Title, Wrapper} from "./DropDownFilter.styles";

type Props = {}

const VesselStatsFilterComponent: React.FC<Props> = () => {
    const {
        vesselList,
        statisticsAlertList,
        selectedStatisticsVessels,
        setSelectedStatisticsVessels
    } = useContext(AppStateContext)!;
    
    useEffect(() => {
        if(statisticsAlertList && vesselList.length > 0){
            setSelectedStatisticsVessels(vesselList);
        }
    }, [statisticsAlertList]);
    
    const handleChange = (selectedItems) => {
        setSelectedStatisticsVessels(selectedItems);
    };

    let filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    return (
        <>
            <Wrapper>
                <ContentWrapper>
                    <TextWrapper>
                        <Title>Vessel</Title>
                    </TextWrapper>
                    <DropdownWrapper>
                        <MultiSelect
                            options={vesselList}
                            filterOptions = {filterOptions}
                            value={selectedStatisticsVessels}
                            onChange={handleChange}
                            labelledBy={"Select"}
                            overrideStrings={{
                                selectSomeItems: "Select vessels",
                                allItemsAreSelected: "All",
                                selectAll: "Select All",
                                search: "Search"
                            }}
                        />
                    </DropdownWrapper>
                </ContentWrapper>
            </Wrapper>
        </>
    );
};

export default VesselStatsFilterComponent;