import {AdGroupMember} from "../types/ViewModel/AdGroupMember";
import {SelectedUser} from "../types/ViewModel/SelectedUser";

export class SelectedUserFactory {
    constructor(
    ) {
    }

    public getSelectedUserItem(item: AdGroupMember): SelectedUser {
        return new SelectedUser(
            item.displayName,
            String(item.id));
    }

    public getSelectedUserList(adGroupMember: AdGroupMember[]): SelectedUser[] {
        return adGroupMember.map(o => this.getSelectedUserItem(o));
    }

    public getSelectedUser(adGroupMember: AdGroupMember[], userId: string | null): SelectedUser[] {
        var userList: SelectedUser[] = [];
        if (userId) {
            var user = adGroupMember.find(o => o.userPrincipalName === userId);
            if (user) {
                userList.push(this.getSelectedUserItem(user));
            }
        }
        return userList;;
    }
}
