import React, {useContext} from 'react'
import {
    TextWrapper,
    Title
} from './MyUnresolvedAlerts.style';
import {GetNumberOfUnresolvedAlerts, GetTotalNumberOfAlerts} from "../../../utils/alertFilterUtils";
import {AuthContext} from "../../../context/AuthContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {}

const MyUnresolvedAlertsComponent: React.FC<Props> = () => {
    const { loggedInUser } = React.useContext(AuthContext);
    const {
        alertListView,
    } = useContext(AppAlertListContext)!;

    let numberOfUnresolvedAlerts: number = 0;
    let totalNumberOfAlerts: number = 0;
    if (loggedInUser && alertListView.length > 0) {
        numberOfUnresolvedAlerts = GetNumberOfUnresolvedAlerts(alertListView, loggedInUser.displayName);
        totalNumberOfAlerts = GetTotalNumberOfAlerts(alertListView, loggedInUser.displayName);
    }

    return (
    <TextWrapper title="# of unresolved alerts assigned to me in selected period" className={numberOfUnresolvedAlerts > 0 ? 'alert-bg' : 'confirm-bg'}>
        <Title>Alerts</Title>
        {numberOfUnresolvedAlerts.toLocaleString('no-NO')}
    </TextWrapper>
    );
};

export default MyUnresolvedAlertsComponent;
