import React, {useContext, useState} from 'react'
import './SavingsStatusFilter.scss';
import ThemeContext from '../../../context/ThemeContext';
import {StyledButton, Wrapper} from './SavingsStatusFilter.styles';
import {AlertSavingsStatus} from "../../../enums/AlertSavingsStatus";
import {AddFilterSavingsStatus, RemoveFilterSavingsStatus} from "../../../utils/alertFilterUtils";
import {FilterSavingsData} from "../../../types/ViewModel/FilterSavingsData";
import useAppAlertList from "../../../app/useAppAlertList";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {}

const SavingsStatusFilterComponent: React.FC<Props> = () => {
    var [newStatus, setNewStatus] = useState<boolean>(true);
    var [registeredStatus, setRegisteredStatus] = useState<boolean>(true);
    var [inProgressStatus, setInProgressStatus] = useState<boolean>(false);
    var [approvedStatus, setApprovedStatus] = useState<boolean>(false);
    var [rejectedStatus, setRejectedStatus] = useState<boolean>(false);
    const { filterSavingsData, setFilterSavingsData
    } = useContext(AppStateContext)!;
    const {
        filterSavingsAlertList,
    } = useAppAlertList()!;
    const {
        alertSavingsListView
    } = useContext(AppAlertListContext)!;

    const statusBtn = (alertSavingsStatus: AlertSavingsStatus) => () => {
        var updatedFilterData: FilterSavingsData = filterSavingsData;
        switch (alertSavingsStatus) {
            case AlertSavingsStatus.NoSavings:
                if (newStatus) {
                    updatedFilterData = RemoveFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                } else {
                    updatedFilterData = AddFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                }
                newStatus = !newStatus;
                setNewStatus(newStatus);
                break;
            case AlertSavingsStatus.PendingApproval:
                if (registeredStatus) {
                    updatedFilterData = RemoveFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                } else {
                    updatedFilterData = AddFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                }
                registeredStatus = !registeredStatus;
                setRegisteredStatus(registeredStatus);
                break;
            case AlertSavingsStatus.Draft:
                if (inProgressStatus) {
                    updatedFilterData = RemoveFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                } else {
                    updatedFilterData = AddFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                }
                inProgressStatus = !inProgressStatus;
                setInProgressStatus(inProgressStatus);
                break;
            case AlertSavingsStatus.Approved:
                if (approvedStatus) {
                    updatedFilterData = RemoveFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                } else {
                    updatedFilterData = AddFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                }
                approvedStatus = !approvedStatus;
                setApprovedStatus(approvedStatus);
                break;
            case AlertSavingsStatus.Rejected:
                if (rejectedStatus) {
                    updatedFilterData = RemoveFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                } else {
                    updatedFilterData = AddFilterSavingsStatus(filterSavingsData, alertSavingsStatus);
                }
                rejectedStatus = !rejectedStatus;
                setRejectedStatus(rejectedStatus);
                break;
        }
        setFilterSavingsData(updatedFilterData);
        filterSavingsAlertList(alertSavingsListView, updatedFilterData);
    };

    return <>
        <Wrapper>
            <StyledButton className="status-draft" isSelected={inProgressStatus} onClick={statusBtn(AlertSavingsStatus.Draft)}>Draft</StyledButton>
            <StyledButton className="status-pending" isSelected={registeredStatus} onClick={statusBtn(AlertSavingsStatus.PendingApproval)}>Pending approval</StyledButton>
            <StyledButton className="status-approved" isSelected={approvedStatus} onClick={statusBtn(AlertSavingsStatus.Approved)}>Approved</StyledButton>
            <StyledButton className="status-rejected" isSelected={rejectedStatus} onClick={statusBtn(AlertSavingsStatus.Rejected)}>Rejected</StyledButton>
        </Wrapper>
    </>;
};

export default SavingsStatusFilterComponent;
