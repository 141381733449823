import {VesselBM} from "../types/BusinessModel/VesselBM";
import {VesselOption} from "../types/ViewModel/VesselOption";

export class VesselFactory {
    constructor(
    ) {
    }

    public getVessel(vessel: VesselBM): VesselOption {
        return new VesselOption(vessel.imoNumber, vessel.name, vessel.reportingGroup);
    }

    public getVesselList(vesselList: VesselBM[]): VesselOption[] {
        return vesselList
          .filter(o => o.isActive)
          .map(o => this.getVessel(o));
    }
}
