import styled, { css } from "styled-components";

export const Wrapper = styled.div`
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2px;
    padding-right: 8px;
    text-align: right;
`;

export const ContentWrapper = styled.div`
    flex: 0 30% 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    heigth: 100%;
    margin-right: 10px;
`;

export const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #f4f4f4;
    &.input-style{
        width: 75px;
        text-align: center;
        padding: 5px 10px;
        color: #04265a;
        outline: 0;
        background: #eee;
        border: 1px solid #ccc;
        border-radius: 3px;
        font-size: 14px;
    }
    .dropdown-heading {
        background:#f4f4f4;
        height: 25px;
    }
`;

export const Title = styled.div`
        font-weight: 500;
        font-size: 0.8rem;
    `;
