import React, { useContext, useEffect, useState } from 'react';
import useApi from './useApi';
import { RoleType } from '../enums/RoleType';
import { AzureAdGroupDataFactory } from '../factory/AzureAdGroupDataFactory';
import { AzureAdGroupDataBM } from '../types/BusinessModel/AzureAdGroupDataBM';
import { AdGroupMember } from '../types/ViewModel/AdGroupMember';
import { AuthContext } from '../context/AuthContext';
import { AppStateContext } from '../context/AppStateContext';
import { useSnackbar } from 'react-simple-snackbar';
import { snackbarOptionsError } from '../utils/snackbarUtils';

export default () => {
    const endpoint = 'GetAdGroupMembers';
    const { state, getApiData } = useApi();
    const { loggedInUser, setLoggedInUser, setRolesAreUpdated } = React.useContext(AuthContext);
    const [role, setRole] = useState<RoleType>(RoleType.Admin);
    const { setAdGroupUserList, setAdGroupAdminList, setIsLoadingUserList, setIsLoadingAdminList } =
        useContext(AppStateContext)!;
    const [openSnackbarError] = useSnackbar(snackbarOptionsError);
    const createQueryParam = (roleType: RoleType) => {
        if (roleType === RoleType.Admin) {
            return '?role=admin';
        } else {
            return '?role=user';
        }
    };

    const getAdGroupMemberList = (roleType: RoleType) => {
        if (!endpoint) return;
        setRole(roleType);
        const queryParam = createQueryParam(roleType);
        getApiData(endpoint, queryParam);
    };

    const adGroupDataFactory = new AzureAdGroupDataFactory();

    useEffect(() => {
        if (role === RoleType.Admin) {
            setIsLoadingAdminList(state.isLoading);
        } else if (role === RoleType.User) {
            setIsLoadingUserList(state.isLoading);
        }
        if (state.status === 'success') {
            const adGroup = state.result as AzureAdGroupDataBM;
            if (adGroup) {
                const adGroupMemberList: AdGroupMember[] = adGroupDataFactory
                    .getAdGroupData(adGroup)
                    .sort((a: AdGroupMember, b: AdGroupMember) => a.displayName.localeCompare(b.displayName));
                if (loggedInUser) {
                    const adUser = adGroupMemberList.find(
                        (o) =>
                            loggedInUser.userName &&
                            o.userPrincipalName.toLowerCase() === loggedInUser.userName.toLowerCase()
                    );

                    if (adUser) {
                        loggedInUser.displayName = adUser.displayName;
                        if (setLoggedInUser) {
                            setLoggedInUser(loggedInUser);
                        }
                    }
                }
                if (adGroup.roleName === 'admin') {
                    setAdGroupAdminList(adGroupMemberList);
                } else {
                    setAdGroupUserList(adGroupMemberList);
                }
            }
        }
    }, [state]);

    return { state, getAdGroupMemberList };
};
