import styled from "styled-components";

export const ListTable = styled.table`
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    .alert-type-col {
      width: 30%;
      text-align: left;
    }
   
    .vessel-name{
      width: 10%;
    }
    
    .limit-col {
      width: 40px;
    }
    
    .value-col {
      width: 40px;
    }
    
    .status-col {
      width: 200px;
      text-align: left;
    }

    thead {
        background: #efedeb;
        font-weight: 400;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        color: #190b05
    }

    th, td {
        padding: 0.5rem;
        font-size: 0.8rem;
        text-align: left;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
    }

    th {
        background: #12427d;
        color: #fff;
        font-weight: 500;
    }

    .active-item {
      background: #ccc;
    }

    .sortable-th {
      cursor: pointer;
      position: relative;
    }

    td {
        font-size: 0.8rem;
    }

    tbody {
        tr {
            cursor: pointer;
            background: #f7fbff;
            border: 1px solid #d4d4d4;
            
            &:hover {
                background: #eee;
            }
            &.selected {
                border: 3px solid #4e9adc;
                background: #fff;
                border-left: 5px solid #4e9adc;
                border-right: 7px solid #4e9adc;
                font-weight: 700;
            }
        }
        .status-complete {
            border: 1px solid #4cb944;
            background: #4cb944;
        }

        .status-exclude {
            border: 1px solid #ef5656;
            background: #ef5656;
        }

        .status-in-progress {
            border: 1px solid #f1be39;
            background: #f1be39;
        }
        .status-new {
            border: 1px solid #aaa;
            background: #aaa;
        }
        .status-muted {
            border: 1px solid #aaa;
            background: #a34198;
        }

        .status-dot-marker {
            margin: 0 4px 0 0;
            font-size: 0.7rem;
            width: 13px;
            display: inline-block;
            height: 13px;
            border-radius: 50%;
        }
    }

    .active-item {
        background: #ccc;
    }

    .alert-type-col {
        width: 40%;
        text-align: left;
    }
        
    .vessel-name{
        width: 10%;
    }

    @media only screen and (max-width: 1280px) {
        .vessel-name{
            width: auto;
        }
    }
    
    .date-col{
        width: 80px;
    }
    .limit-col {
        width: 40px;
    }
        
    .value-col {
        width: 40px;
    }

    .status-col {
        width: 95px;
    }
`;

