import styled, {css} from "styled-components";
import {IButtonProps} from "../../alert-list-filters/priority-filter/PriorityFilter.styles";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  box-shadow: 0 2px 6px 2px #ddd;
  margin: 0 0 1rem;
  padding: 0.75rem;
  height: 75px;
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    .dropdown-heading{
        cursor: pointer;
    }
`;

export const EditButtonWrapper = styled.div`
    display: flex;
    background: #fff;
    margin: 0;
    margin-bottom: 2.5rem;
    margin-right: 1rem;
    padding-right: 1rem;
    height: 50px;
`;

export const CancelSaveButtonWrapper = styled.div`
    display: flex;
    background: #fff;
    height: 50px;
`;

export const WorkflowButtonWrapper = styled.div`
    display: flex;
    background: #fff;
    margin: 0;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    margin-right: 1rem;
    padding-right: 1rem;
    height: 50px;
`;

export const RightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const LeftContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 20%;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const ButtonLeftContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    flex: 0 0 85%;
    max-width: 85%;
    padding-left: 5px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
`;

export const ButtonRightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 15%;
    max-width: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledButton = styled.button<IButtonProps>`
        font-size: 1em;
        margin: 1px;
        padding: 5px 10px;
        border-radius: 4px;
        color: #fff;
        border: 1px solid;
        outline: none;
        cursor: pointer;

    @media only screen and (max-width: 1280px) {
        font-size: 0.8rem;
        padding: 0.4em 0.8em;
    }

    &:disabled{
        opacity: 0.25;  
    }
  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;
