import {useContext, useEffect} from 'react';
import useApi from './useApi';
import {AlertItemFactory} from "../factory/AlertItemFactory";
import {AlertItemBM} from "../types/BusinessModel/AlertItemBM";
import {AlertItem} from "../types/ViewModel/AlertItem";
import {AppAlertListContext} from "../context/AppAlertListContext";

export default () => {
    const endpoint = 'GetHistoricalAlerts';
    const { state, getApiData } = useApi();
    const {
        setHistoricalAlerts,
        setIsLoadingHistoricalAlerts,
    } = useContext(AppAlertListContext)!;

    const createQueryParam = (alertId: number) => {
        return '?alertId=' + String(alertId);
    };

    const getHistoricalAlerts = (alertId: number) => {
        if (!endpoint) return;
        const queryParam = createQueryParam(alertId);
        getApiData(endpoint, queryParam);
    };

    const alertItemFactory = new AlertItemFactory();

    useEffect(() => {
        setIsLoadingHistoricalAlerts(state.isLoading);
        if (state.status === 'success'){
            const alertItemList = state.result as unknown as AlertItemBM[];
            const alertListView: AlertItem[] = alertItemFactory.getAlertItemList(alertItemList);
            setHistoricalAlerts(alertListView);
        }
    }, [state]);

    return { state, getHistoricalAlerts };
}
