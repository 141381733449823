/* eslint-disable import/no-anonymous-default-export */
import * as Msal from "@azure/msal-browser";
import { AuthConfig, LoginRequest } from './AuthConfig';
import Axios from 'axios';
import {User} from "../types/ViewModel/User";
import React from "react";
import {AuthContext} from "../context/AuthContext";
import useAuth from "./useAuth";
import {Role} from "../types/ViewModel/Role";
import {RoleType} from "../enums/RoleType";


export default () => {
    const {
        setLoggedInUser,
        loggedInUser,
        setUserIsUpdated,
        setIsAuthenticating,
        setIsVerifyingToken,
        setToken
    } = React.useContext(AuthContext)!;

    const myMsalObj = new Msal.PublicClientApplication(AuthConfig);
    const { isTokenAuthorized } = useAuth();

    const interceptAxiosRequest = (account: Msal.AccountInfo) => {
        console.log('interceptAxiosRequest');
        Axios.interceptors.request.use(async config => {
            console.log(' Axios.interceptors.request.use ' + config.url);

            var signalRConnectionJson = localStorage.getItem('signalRConnection');            
            const signalRConnection = signalRConnectionJson && JSON.parse(signalRConnectionJson);
            if (signalRConnection?.url && config?.url && sameParam(signalRConnection!.url, config!.url ?? "", "hub")) {
                config.headers.Authorization = 'Bearer ' + signalRConnection!.accessToken;
            } else {
                const authResult = await myMsalObj.acquireTokenSilent({
                    account,
                    scopes: LoginRequest.scopes,
                    authority: AuthConfig.auth.authority
                }).catch((error) => {
                    console.log('refreshAccessToken error interceptAxiosRequest ' + error);
                    loginAzAd();
                });   

                if (authResult && authResult.accessToken) {
                    config.headers.Authorization = 'Bearer ' + authResult.accessToken;
                    if (setToken){
                        setToken(authResult.accessToken);
                    }                
                } else {
                    loginAzAd();
                }
            }
            return config;
        });
    };

    const sameParam = (str1: string, str2: string, param: string ): boolean => {
        const params1 = new URLSearchParams(new URL(str1).search);
        const params2 = new URLSearchParams(new URL(str2).search);
        return params1.has(param) && params2.has(param) && params1.get(param) === params2.get(param);
    }

    const checkTokenAndSetUser = async (authenticationResult: Msal.AuthenticationResult) => {
        console.log('checkTokenAndSetUser');
        try {
            interceptAxiosRequest(authenticationResult.account!);
            if (await isTokenAuthorized()) {
                setUser(authenticationResult);
            } else {
                console.log('LoginOut 3');
                logoutAzAd();
            }
        }
        catch (error) {
            console.log('checkTokenAndSetUser  catch (error)');
        }
    };

    const loginAzAd = () => {
        console.log('loginAzAd');
        if (setIsAuthenticating) {
            setIsAuthenticating(true);
        }
                
        myMsalObj.loginRedirect();

        if (setIsAuthenticating) {
            setIsAuthenticating(false);
        }
    }

    const logoutAzAd = () => {
        if( loggedInUser && loggedInUser?.userName){
            myMsalObj.logoutRedirect({
                account: myMsalObj.getAccountByUsername(loggedInUser?.userName),
                postLogoutRedirectUri: '/login'
            }).then(result => {
            }).catch(error => {
                console.log('logout error ', error);
            });
        } else if(myMsalObj.getActiveAccount() !== null) {
            myMsalObj.logoutRedirect({
                account: myMsalObj.getActiveAccount(),
                postLogoutRedirectUri: '/login'
            }).then(result => {
            }).catch(error => {
                console.log('logout error ', error);
            });
        } else {
            myMsalObj.logoutRedirect({
                account: myMsalObj.getAllAccounts()[0],
                postLogoutRedirectUri: '/login'
            }).then(result => {
            }).catch(error => {
                console.log('logout error ', error);
            });
        }
    };

    const checkAuthStatus = async () => {
        let msalAccount: Msal.AccountInfo | null = null;
        console.log('checkAuthStatus');

        if (setIsVerifyingToken) {
            setIsVerifyingToken(true);
        }
     
        const account = myMsalObj.getActiveAccount();

        if(account && AuthConfig.auth){
            msalAccount = account;
        } else {
            const accounts = myMsalObj.getAllAccounts();
            if (accounts && accounts.length > 0 && AuthConfig.auth) {
                msalAccount = accounts[0];
            }
        }

        if (msalAccount && AuthConfig.auth) {
            console.log('acquireTokenSilent ' + AuthConfig.auth); 
            const authResult = await myMsalObj.acquireTokenSilent({
                account: msalAccount,
                scopes: LoginRequest.scopes,
                authority: AuthConfig.auth.authority
            }).catch((error) => {
                console.log('refreshAccessToken error  msalAccount && AuthConfig.auth ' + error);
                myMsalObj.logoutRedirect({
                    account: myMsalObj.getActiveAccount(),
                    postLogoutRedirectUri: '/login'
                }).then(result => {
                }).catch(error => {
                    console.log('logout error ', error);
                });
            });   

            if (authResult && authResult.accessToken) {
                if (setToken){
                    setToken(authResult.accessToken);
                }
                await checkTokenAndSetUser(authResult);
            } else {
                const signedInAccounts = myMsalObj.getAllAccounts();
                if (signedInAccounts && signedInAccounts.length > 0) {
                    logoutAzAd();
                }
            }
        } else {
            if (setLoggedInUser) {
                setLoggedInUser(null);
            }
        }
        
        if (setIsVerifyingToken) {
            setIsVerifyingToken(false);
        }
        if (setIsAuthenticating) {
            setIsAuthenticating(false);
        }
    }

    const setUser = (authenticationResult: Msal.AuthenticationResult) => {
        console.log('setUser: ' + authenticationResult);
        if (authenticationResult && authenticationResult.account) {
            const myAccount = myMsalObj.getAccountByUsername(authenticationResult.account?.username);
            myMsalObj.setActiveAccount(myAccount);
            if (myAccount) {
                const user = new User(
                  myAccount.homeAccountId,
                  '',
                  myAccount.username,
                  myAccount.username.split('@')[0].replace('.', ' '), // TODO: replace this by something else...
                  
                  null,
                  new Role(RoleType.NoRole, 'N/A'));

                if (setLoggedInUser) {
                    console.log('setLoggedInUser USER');
                    setLoggedInUser(user);
                }
                if (setUserIsUpdated) {
                    setUserIsUpdated(true);
                }
                if (setIsAuthenticating) {
                    setIsAuthenticating(false);
                }
            } else {
                if (setLoggedInUser) {
                    console.log('setLoggedInUser NULL');
                    setLoggedInUser(null);
                }
            }
        } else {
            if (setLoggedInUser) {
                console.log('setLoggedInUser NULL');
                setLoggedInUser(null);
            }
           if (setUserIsUpdated) {
                setUserIsUpdated(true);
            }
        }
    }

    const initHandleRedirect = () => {
        console.log('initHandleRedirect');
        myMsalObj.handleRedirectPromise().then(result => {
            console.log('handleRedirectPromise', result)
            if (result) {
                checkTokenAndSetUser(result);
            }

        }).catch((error: Msal.AuthError) => {
            // User has clicked forgot password
            console.error('msal handleRedirectPromise');
        })
    }
    

    return { loginAzAd, logoutAzAd, checkAuthStatus, initHandleRedirect }
}
