import React from 'react';

type Props = {
    name: string,
    label: string,
    id: string,
    isSelected: boolean,
    onChange: (e: any) => void
}

const ToggleInput: React.FC<Props> = props => {
    return (
        <>
            <input id={props.id} type='checkbox' name={props.name} checked={props.isSelected} onChange={props.onChange}></input>
            <label htmlFor={props.id}>{props.label}</label>
        </>
    );
}

export default ToggleInput;
