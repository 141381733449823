import {useContext} from 'react';
import axios from 'axios';
import getContext from '../context/AppSettingsContext';
import {AlertStatusUpdateBM} from "../types/BusinessModel/AlertStatusUpdateBM";
import {AlertStatus} from "../enums/AlertStatus";
import {AlertStatusUpdateFactory} from "../factory/AlertStatusUpdateFactory";
import {GetAlertStatusName} from '../utils/workFlowUtils';
import {AlertCommentFactory} from "../factory/AlertCommentFactory";
import {AlertCommentCategory} from "../enums/AlertCommentCategory";
import {AlertComment} from "../types/ViewModel/AlertComment";
import { useSnackbar } from 'react-simple-snackbar'
import {snackbarOptionsError, snackbarOptionsOk} from "../utils/snackbarUtils";
import {AppStateContext} from "../context/AppStateContext";
import {AppAlertListContext} from "../context/AppAlertListContext";

export default () => {
    const endpoint = 'UpdateAlertStatus';
    const appSettings = useContext(getContext());
    const {
        alertDetails,
    } = useContext(AppStateContext)!;
    const {
        setIsAlertItemUpdated
    } = useContext(AppAlertListContext)!;
    const [openSnackbarOk] = useSnackbar(snackbarOptionsOk);
    const [openSnackbarError] = useSnackbar(snackbarOptionsError);

    const alertStatusUpdateFactory = new AlertStatusUpdateFactory();
    const alertCommentFactory = new AlertCommentFactory();

    const url = appSettings.api + endpoint;

    const updateAlertStatus = async (
        alertStatusId: AlertStatus,
        comment: string,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string) => {
        if (alertDetails && alertDetails.alertItem) {
            openSnackbarOk('Updating alert status');
            var newComment: AlertComment | null = null;
            if (comment && comment.length > 0) {
                newComment = alertCommentFactory.createAlertCommentFromCode(
                    0,
                    alertDetails.alertItem.alertId,
                    comment,
                    AlertCommentCategory.GeneralComment,
                    '',
                    lastUpdatedUtc,
                    lastUpdatedBy);
            }
            const alertStatusUpdateBM = alertStatusUpdateFactory.getAlertStatusUpdateBM(alertDetails.alertItem.alertId, alertStatusId, newComment, lastUpdatedUtc, lastUpdatedBy);

            const response = await axios
                .post<AlertStatusUpdateBM>(url, alertStatusUpdateBM, {})
                .then(postResponse => {
                    openSnackbarOk('Alert status updated');
                    return postResponse;
                })
                .catch(error => {
                    return error.response;
                });

            if (!response) {
                openSnackbarError('Failed: no connection to server');
            } else if (response.status !== 200) {
                if (response.data) {
                    openSnackbarError('Failed: ' + String(response.data.statusCode) + ' (' + response.data.errorMessage + ')');
                } else {
                    openSnackbarError('Updating alert status failed');
                }
            } else {
                alertDetails.alertItem.alertStatusId = alertStatusId;
                alertDetails.alertItem.alertStatusName = GetAlertStatusName(alertStatusId);
                alertDetails.alertItem.lastUpdatedUtc = lastUpdatedUtc;
                alertDetails.alertItem.lastUpdatedBy = lastUpdatedBy;
                if (newComment) {
                    alertDetails.alertItem.commentList.splice(0, 0, newComment);
                }
            }
            setIsAlertItemUpdated(true);
        }
    };
    return { updateAlertStatus }
}
