import {AlertSavingsStatusUpdateBM} from "../types/BusinessModel/AlertSavingsStatusUpdateBM";
import {AlertSavingsStatus} from "../enums/AlertSavingsStatus";
import {AlertStatus} from "../enums/AlertStatus";
import {AlertComment} from "../types/ViewModel/AlertComment";
import {AlertStatusUpdateBM} from "../types/BusinessModel/AlertStatusUpdateBM";
import {AlertCommentFactory} from "./AlertCommentFactory";

export class AlertSavingsStatusUpdateFactory {
    constructor(
    ) {
    }

    public getAlertStatusUpdateBM(
        alertId: number,
        alertSavingsStatusId: AlertSavingsStatus,
        alertSavingsDescription: string,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string): AlertSavingsStatusUpdateBM | null {
        const alertCommentFactory = new AlertCommentFactory();
        return {
            alertId: alertId,
            alertSavingsStatusId: alertSavingsStatusId,
            alertSavingsDescription: alertSavingsDescription,
            lastUpdatedUtc: lastUpdatedUtc,
            lastUpdatedBy: lastUpdatedBy
        }
    }
}
