import {AlertSavingsPerUserBM} from "../types/BusinessModel/AlertSavingsPerUserBM";
import {AlertSavingsPerUser} from "../types/ViewModel/AlertSavingsPerUser";

export class AlertSavingsPerUserFactory {
    constructor(
    ) {
    }

    public getAlertSavingsPerUser(item: AlertSavingsPerUserBM): AlertSavingsPerUser {
        return new AlertSavingsPerUser(item.username, item.savingsInUsd, item.savingsInMt);
    }

    public getAlertSavingsPerUserList(AlertSavingsPerUserListBM: AlertSavingsPerUserBM[]): AlertSavingsPerUser[] {
        return AlertSavingsPerUserListBM.map(o => this.getAlertSavingsPerUser(o));
    }

    public createSavingsPerUser( username: string, savingsInUsd: number, savingsInMt: number): AlertSavingsPerUser {
        return new AlertSavingsPerUser(username, savingsInUsd, savingsInMt);
    }
}
