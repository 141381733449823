export class AlertSavingsPerUser {
    constructor(
        username: string,
        savingsInUsd: number,
        savingsInMt: number
    ) {
        this.username = username;
        this.savingsInUsd = savingsInUsd;
        this.savingsInMt = savingsInMt;
    }
    username: string;
    savingsInUsd: number;
    savingsInMt: number;
}
