import React, {useContext, useState} from 'react'
import ThemeContext from '../../../context/ThemeContext';
import {IButtonProps, StyledButton, Wrapper} from './StatusFilter.styles';
import {AlertStatus} from "../../../enums/AlertStatus";
import {FilterAlertData} from "../../../types/ViewModel/FilterAlertData";
import {
    AddFilterStatus,
    RemoveFilterStatus
} from "../../../utils/alertFilterUtils";
import useAppAlertList from "../../../app/useAppAlertList";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {}

const StatusFilterComponent: React.FC<Props> = () => {
    const theme = React.useContext(ThemeContext);
    var [newStatus, setNewStatus] = useState<boolean>(true);
    var [inProgressStatus, setInProgressStatus] = useState<boolean>(true);
    var [excludedStatus, setExcludedStatus] = useState<boolean>(false);
    var [completedStatus, setCompletedStatus] = useState<boolean>(false);
    var [mutedStatus, setMutedStatus] = useState<boolean>(false);
    const { filterAlertData, setFilterAlertData
    } = useContext(AppStateContext)!;
    const {
        filterAlertList,
    } = useAppAlertList()!;
    const {
        alertListView,
    } = useContext(AppAlertListContext)!;

    const statusBtn = (alertStatus: AlertStatus) => () => {
        var updatedFilterData: FilterAlertData = filterAlertData;
        switch (alertStatus) {
            case AlertStatus.New:
                if (newStatus) {
                    updatedFilterData = RemoveFilterStatus(filterAlertData, alertStatus);
                } else {
                    updatedFilterData = AddFilterStatus(filterAlertData, alertStatus);
                }
                newStatus = !newStatus;
                setNewStatus(newStatus);
                break;
            case AlertStatus.InProgress:
                if (inProgressStatus) {
                    updatedFilterData = RemoveFilterStatus(filterAlertData, alertStatus);
                } else {
                    updatedFilterData = AddFilterStatus(filterAlertData, alertStatus);
                }
                inProgressStatus = !inProgressStatus;
                setInProgressStatus(inProgressStatus);
                break;
            case AlertStatus.Excluded:
                if (excludedStatus) {
                    updatedFilterData = RemoveFilterStatus(filterAlertData, alertStatus);
                } else {
                    updatedFilterData = AddFilterStatus(filterAlertData, alertStatus);
                }
                excludedStatus = !excludedStatus;
                setExcludedStatus(excludedStatus);
                break;
            case AlertStatus.Completed:
                if (completedStatus) {
                    updatedFilterData = RemoveFilterStatus(filterAlertData, alertStatus);
                } else {
                    updatedFilterData = AddFilterStatus(filterAlertData, alertStatus);
                }
                completedStatus = !completedStatus;
                setCompletedStatus(completedStatus);
                break;
            case AlertStatus.Muted:
                if (mutedStatus) {
                    updatedFilterData = RemoveFilterStatus(filterAlertData, alertStatus);
                } else {
                    updatedFilterData = AddFilterStatus(filterAlertData, alertStatus);
                }
                mutedStatus = !mutedStatus;
                setMutedStatus(mutedStatus);
                break;
        }
        setFilterAlertData(updatedFilterData);
        filterAlertList(alertListView, updatedFilterData);
    };

    return <>
        <Wrapper>
            <StyledButton className="status-new" isSelected={newStatus} onClick={statusBtn(AlertStatus.New)}>New</StyledButton>
            <StyledButton className="status-in-progress" isSelected={inProgressStatus} onClick={statusBtn(AlertStatus.InProgress)}>In progress</StyledButton>
            <StyledButton className="status-complete" isSelected={completedStatus} onClick={statusBtn(AlertStatus.Completed)}>Completed</StyledButton>
            <StyledButton className="status-exclude" isSelected={excludedStatus} onClick={statusBtn(AlertStatus.Excluded)}>Excluded</StyledButton>
            <StyledButton className="status-muted" isSelected={mutedStatus} onClick={statusBtn(AlertStatus.Muted)}>Muted</StyledButton>
        </Wrapper>
    </>;
}

export default StatusFilterComponent;
