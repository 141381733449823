import {useContext} from 'react';
import axios from 'axios';
import getContext from '../context/AppSettingsContext';
import {AlertStatus} from "../enums/AlertStatus";
import {GetAlertStatusName} from '../utils/workFlowUtils';
import {AlertCommentFactory} from "../factory/AlertCommentFactory";
import {AlertCommentCategory} from "../enums/AlertCommentCategory";
import {AlertComment} from "../types/ViewModel/AlertComment";
import {useSnackbar} from 'react-simple-snackbar'
import {snackbarOptionsError, snackbarOptionsOk} from "../utils/snackbarUtils";
import {AlertTypeMuteFactory} from "../factory/AlertTypeMuteFactory";
import {AlertTypeMuteBM} from "../types/BusinessModel/AlertTypeMuteBM";
import {AppStateContext} from "../context/AppStateContext";
import {AppAlertListContext} from "../context/AppAlertListContext";

export default () => {
    const endpoint = 'MuteAlertType';
    const appSettings = useContext(getContext());
    const {
        alertDetails,
        alertTypeList,
        setAlertTypeList
    } = useContext(AppStateContext)!;
    const {
        setIsAlertItemUpdated
    } = useContext(AppAlertListContext)!;
    const [openSnackbarOk] = useSnackbar(snackbarOptionsOk);
    const [openSnackbarError] = useSnackbar(snackbarOptionsError);

    const alertTypeMuteFactory = new AlertTypeMuteFactory();
    const alertCommentFactory = new AlertCommentFactory();

    const url = appSettings.api + endpoint;

    const muteAlert = async (
        mutedUntilDate: Date,
        allTanks: boolean,
        comment: string,
        createdUtc: Date,
        createdBy: string) => {
        if (alertDetails && alertDetails.alertItem) {
            openSnackbarOk('Muting alert');
            var newComment: AlertComment | null = null;
            if (comment && comment.length > 0) {
                newComment = alertCommentFactory.createAlertCommentFromCode(
                    0,
                    alertDetails.alertItem.alertId,
                    comment,
                    AlertCommentCategory.GeneralComment,
                    '',
                    createdUtc,
                    createdBy);
            }
            const alertTypeMuteBM = alertTypeMuteFactory.getAlertTypeMuteBM(
                alertDetails.alertItem.alertContext.imoNumber,
                alertDetails.alertItem.alertTypeDetails.alertTypeId,
                alertDetails.alertItem.alertId,
                mutedUntilDate,
                alertDetails.alertItem.alertContext.cargoNumber,
                alertDetails.alertItem.alertContext.tankName,
                allTanks,
                newComment,
                createdUtc,
                createdBy,
                createdUtc,
                createdBy
            );

            const response = await axios
                .post<AlertTypeMuteBM>(url, alertTypeMuteBM, {})
                .then(postResponse => {
                    openSnackbarOk('Alert muted');
                    return postResponse;
                })
                .catch(error => {
                    return error.response;
                });

            if (!response) {
                openSnackbarError('Failed: no connection to server');
            } else if (response.status !== 200) {
                if (response.data) {
                    openSnackbarError('Failed: ' + String(response.data.statusCode) + ' (' + response.data.errorMessage + ')');
                } else {
                    openSnackbarError('Alert mute failed');
                }
            } else {
                alertDetails.alertItem.alertStatusId = AlertStatus.Muted;
                alertDetails.alertItem.alertStatusName = GetAlertStatusName(alertDetails.alertItem.alertStatusId);
                alertDetails.alertItem.lastUpdatedUtc = createdUtc;
                alertDetails.alertItem.lastUpdatedBy = createdBy;
                if (newComment) {
                    alertDetails.alertItem.commentList.splice(0, 0, newComment);
                }
                var newAlertTypeMute = alertTypeMuteFactory.createAlertTypeMuteFromCode(
                    alertDetails.alertItem.alertContext.imoNumber,
                    alertDetails.alertItem.alertTypeDetails.alertTypeId,
                    alertDetails.alertItem.alertId,
                    mutedUntilDate,
                    alertDetails.alertItem.alertContext.cargoNumber,
                    alertDetails.alertItem.alertContext.tankName,
                    allTanks,
                    createdUtc,
                    createdBy,
                    createdUtc,
                    createdBy
                );
                var alertType = alertTypeList.find(o => o.value === String(alertDetails.alertItem.alertTypeDetails.alertTypeId));
                if (alertType) {
                    alertType.alertTypeMuteList.push(newAlertTypeMute);
                    setAlertTypeList(alertTypeList);
                }
            }
            setIsAlertItemUpdated(true);
        }
    };
    return { muteAlert }
}
