import { useContext } from 'react';
import axios from 'axios';
import {AlertItemBM} from "../types/BusinessModel/AlertItemBM";
import {AlertItem} from "../types/ViewModel/AlertItem";
import {AlertItemFactory} from "../factory/AlertItemFactory";
import getContext from "../context/AppSettingsContext";
import { useSnackbar } from 'react-simple-snackbar'
import {snackbarOptionsError, snackbarOptionsOk} from "../utils/snackbarUtils";
import {GetSavingsLastMonthForSelectedUsers, UpdateAlertSavingsPerUser} from "../utils/savingsUtils";
import {AppStateContext} from "../context/AppStateContext";
import {AppAlertListContext} from "../context/AppAlertListContext";

export default () => {
    const endpoint = 'UpdateAlertItem';
    const appSettings = useContext(getContext());
    const {
        alertDetails,
        setAlertDetails,
        setIsAlertSavingsUpdated,
        setSavingsLastMonth,
        selectedUserList,
        alertSavingsPerUserList,
        setAlertSavingsPerUserList,
        alertTypeList,
        setAlertTypeList
    } = useContext(AppStateContext)!;
    const {
        setIsAlertItemUpdated,
    } = useContext(AppAlertListContext)!;
    const [openSnackbarOk] = useSnackbar(snackbarOptionsOk);
    const [openSnackbarError] = useSnackbar(snackbarOptionsError);

    const alertItemFactory = new AlertItemFactory();

    const url = appSettings.api + endpoint;

    const updateAlertItem = async (alertItem: AlertItem) => {
        if (alertDetails) {
            const alertItemBM = alertItemFactory.getAlertItemBM(alertItem);
            openSnackbarOk('Saving alert');

            const response = await axios
                .post<AlertItemBM>(url, alertItemBM, {})
                .then(postResponse => {
                    openSnackbarOk('Alert saved');
                    return postResponse;
                })
                .catch(error => {
                    return error.response;
                });

            if (!response) {
                openSnackbarOk('Failed: no connection to server');
            } else if (response.status !== 200) {
                if (response.data) {
                    openSnackbarError('Failed: ' + String(response.data.statusCode) + ' (' + response.data.errorMessage + ')');
                } else {
                    openSnackbarError('Updating alert failed');
                }
            } else {
                const alertSavings = UpdateAlertSavingsPerUser(alertSavingsPerUserList, alertDetails.alertItem.assignee, alertItem.assignee, alertItem.totalSavingsInUsd);
                setAlertSavingsPerUserList(alertSavings);
                setSavingsLastMonth(GetSavingsLastMonthForSelectedUsers(selectedUserList, alertSavings));
                alertDetails.alertItem.assignee = alertItem.assignee;
                setAlertDetails(alertDetails);
                var alertType = alertTypeList.find(o => o.value === String(alertDetails.alertItem.alertTypeDetails.alertTypeId));
                if (alertType) {
                    var alertTypeMute = alertType.alertTypeMuteList.find(o => o.imoNumber === alertItem.alertContext.imoNumber);
                    if (alertTypeMute && alertItem.alertMutedUntil) {
                        alertTypeMute.mutedUntilDate = alertItem.alertMutedUntil;
                    }
                    setAlertTypeList(alertTypeList);
                }
            }
            setIsAlertItemUpdated(true);
            setIsAlertSavingsUpdated(true);
        }
    };

    return { updateAlertItem }
}
