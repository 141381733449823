export enum DetailComponent {
    Tabs = 1,
    AlertWorkflowInProgress = 2,
    AlertWorkflowComplete = 3,
    AlertWorkflowExclude = 4,
    AlertWorkflowReopen = 5,
    SavingsWorkflowRegister = 6,
    SavingsWorkflowApprove = 7,
    SavingsWorkflowReject = 8
}
