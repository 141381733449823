import {AlertSavingsPerFuelType} from "./AlertSavingsPerFuelType";
import {AlertComment} from "./AlertComment";
import {ChangeLog} from "./ChangeLog";
import {AlertItem} from "./AlertItem";

export class AlertDetails {
    constructor(
        alertItem: AlertItem,
        changeLog: ChangeLog[],
        alertSavingsPerFuelTypeList: AlertSavingsPerFuelType[]
    ) {
        this.alertItem = alertItem;
        this.changeLog = changeLog;
        this.alertSavingsPerFuelTypeList = alertSavingsPerFuelTypeList;
    }
    alertItem: AlertItem;
    changeLog: ChangeLog[];
    historicalAlerts: AlertItem[] | undefined;
    alertSavingsPerFuelTypeList: AlertSavingsPerFuelType[];
}
