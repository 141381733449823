import styled from 'styled-components';

export const LoginWrapper = styled.div`
    text-align: center;
    position: absolute;
    top: 200px;
    left: 200px;
    background: #fff;
    border-radius: 4px;
    padding: 10px 40px 40px 40px;
    width: 300px;
    h1 {
        font-weight: 200;
    }
    p {
        font-size: 17px;
        line-height: 1.5;
    }
    button {
        margin: 40px 0 0;
        background: #00447e;
        padding: 15px 30px;
        border-radius: 4px;
        border: 0;
        color: #fff;
        font-size: 15px;
        outline: none;
        cursor: pointer;
    }
`;
