import './SeverityFilter.scss';
import React, {useContext, useState} from 'react'
import ThemeContext from '../../../context/ThemeContext';
import {IButtonProps, StyledButton, Wrapper} from './SeverityFilter.styles';
import {AlertSeverity} from "../../../enums/AlertSeverity";
import {
    FilterAlertList,
    AddFilterPriority,
    RemoveFilterPriority,
    RemoveFilterSeverity, AddFilterSeverity
} from '../../../utils/alertFilterUtils';
import {FilterAlertData} from "../../../types/ViewModel/FilterAlertData";
import useAppAlertList from "../../../app/useAppAlertList";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {}

const SeverityFilterComponent: React.FC<Props> = () => {
    const theme = React.useContext(ThemeContext);
    var [criticalSeverity, setCriticalSeverity] = useState<boolean>(true);
    var [majorSeverity, setMajorSeverity] = useState<boolean>(true);
    var [normalSeverity, setNormalSeverity] = useState<boolean>(true);
    var [minorSeverity, setMinorSeverity] = useState<boolean>(true);
    const { filterAlertData, setFilterAlertData
    } = useContext(AppStateContext)!;
    const {
        filterAlertList,
    } = useAppAlertList()!;
    const {
        alertListView,
    } = useContext(AppAlertListContext)!;

    const severityBtn = (alertSeverity: AlertSeverity) => () => {
        var updatedFilterData: FilterAlertData = filterAlertData;
        switch (alertSeverity) {
            case AlertSeverity.Critical:
                if (criticalSeverity) {
                    updatedFilterData = RemoveFilterSeverity(filterAlertData, alertSeverity);
                } else {
                    updatedFilterData = AddFilterSeverity(filterAlertData, alertSeverity);
                }
                criticalSeverity = !criticalSeverity;
                setCriticalSeverity(criticalSeverity);
                break;
            case AlertSeverity.Major:
                if (majorSeverity) {
                    updatedFilterData = RemoveFilterSeverity(filterAlertData, alertSeverity);
                } else {
                    updatedFilterData = AddFilterSeverity(filterAlertData, alertSeverity);
                }
                majorSeverity = !majorSeverity;
                setMajorSeverity(majorSeverity);
                break;
            case AlertSeverity.Normal:
                if (normalSeverity) {
                    updatedFilterData = RemoveFilterSeverity(filterAlertData, alertSeverity);
                } else {
                    updatedFilterData = AddFilterSeverity(filterAlertData, alertSeverity);
                }
                normalSeverity = !normalSeverity;
                setNormalSeverity(normalSeverity);
                break;
            case AlertSeverity.Minor:
                if (minorSeverity) {
                    updatedFilterData = RemoveFilterSeverity(filterAlertData, alertSeverity);
                } else {
                    updatedFilterData = AddFilterSeverity(filterAlertData, alertSeverity);
                }
                minorSeverity = !minorSeverity;
                setMinorSeverity(minorSeverity);
                break;
        }
        setFilterAlertData(updatedFilterData);
        filterAlertList(alertListView, updatedFilterData);
    };

    return <>
        <Wrapper>
            <StyledButton isSelected={minorSeverity} onClick={severityBtn(AlertSeverity.Minor)}>Minor</StyledButton>
            <StyledButton isSelected={normalSeverity} onClick={severityBtn(AlertSeverity.Normal)}>Normal</StyledButton>
            <StyledButton isSelected={majorSeverity} onClick={severityBtn(AlertSeverity.Major)}>Major</StyledButton>
            <StyledButton isSelected={criticalSeverity} onClick={severityBtn(AlertSeverity.Critical)}>Critical</StyledButton>
        </Wrapper>
    </>;
};

export default SeverityFilterComponent;
