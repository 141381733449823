export class AlertStatusMap {
    constructor(
        inProgressIsEnabled: boolean,
        excludeIsEnabled: boolean,
        completeIsEnabled: boolean,
        reOpenIsEnabled: boolean,
        mutedIsEnabled: boolean
    ) {
        this.inProgressIsEnabled = inProgressIsEnabled;
        this.excludeIsEnabled = excludeIsEnabled;
        this.completeIsEnabled = completeIsEnabled;
        this.reOpenIsEnabled = reOpenIsEnabled;
        this.mutedIsEnabled = mutedIsEnabled;
    }
    inProgressIsEnabled: boolean;
    excludeIsEnabled: boolean;
    completeIsEnabled: boolean;
    reOpenIsEnabled: boolean;
    mutedIsEnabled: boolean;
}
