import {AlertSeverity} from "../../../enums/AlertSeverity";
import styled, {css} from "styled-components";

export interface IButtonProps {
    isSelected?: boolean;
    onClick?: (alertSeverity: AlertSeverity) => void;
}

export const Wrapper = styled.div`
  padding: 0 0 0 15px;
`;

export const StyledButton = styled.button<IButtonProps>`
  background: #007bff;
  border: 1px solid #007bff;
  color: #fff;  
  height: 24px;
  padding: 2px 6px;
  font-size: 12px;
  margin: 1px;
  outline: 0;
  cursor: pointer;
  border-radius: 3px;


  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;

