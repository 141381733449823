import {AlertComment} from "./AlertComment";

export class AlertTypeMute {
    constructor(
        imoNumber: number,
        alertTypeId: number,
        mutedUntilDate: Date,
        cargoNumber: string,
        tankName: string,
        allTanks: boolean,
        comment: AlertComment | null,
        createdUtc: Date,
        createdBy: string,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string

) {
        this.imoNumber = imoNumber;
        this.alertTypeId = alertTypeId;
        this.mutedUntilDate = mutedUntilDate;
        this.cargoNumber = cargoNumber;
        this.tankName = tankName;
        this.allTanks = allTanks;
        this.comment = comment;
        this.createdUtc = createdUtc;
        this.createdBy = createdBy;
        this.lastUpdatedUtc = lastUpdatedUtc;
        this.lastUpdatedBy = lastUpdatedBy;
    }
    imoNumber: number;
    alertTypeId: number;
    mutedUntilDate: Date;
    cargoNumber: string;
    tankName: string;
    allTanks: boolean;
    comment: AlertComment | null;
    createdUtc: Date;
    createdBy: string;
    lastUpdatedUtc: Date;
    lastUpdatedBy: string;
}
