import styled, {css} from "styled-components";
import {IButtonProps} from "../../alert-list-filters/priority-filter/PriorityFilter.styles";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

export const EditButtonWrapper = styled.div`
    display: flex;
    background: #fff;
    padding: 0.5rem;
    height: 50px;
`;

export const AddCommentWrapper = styled.div`
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    background: #fff;
    margin: 0;
`;

export const CommentTextWrapper = styled.div`
    display: flex;
    flex: 0 0 100%;
    background: #fff;
    margin: 0;
    width: 100%
    height: 50px;
    
    textarea{
        border: 1px solid #ddd;
        width: 100%;
        padding: 5px;
        margin: 5px;
        min-height: 80px;
        outline: none;
        background: #f8f8f8;
        font-family: inherit;
    }
`;

export const RightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const CancelSaveRightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem;
`;

export const StyledButton = styled.button<IButtonProps>`
    font-size: 1em;
    margin: 3px;
    padding: 0.5em 1.5em;
    border-radius: 4px;
    color: #fff;
    border: 1px solid;
    outline: none;
    cursor: pointer;
    
    @media only screen and (max-width: 1280px) {
        font-size: 0.8rem;
        padding: 0.4em 0.8em;
    }

  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.25;
    `}
`;
