import styled, {css} from "styled-components";
import {IButtonProps} from "../../alert-list-filters/priority-filter/PriorityFilter.styles";

export const Wrapper = styled.div`
    margin-top: 15px;
    width: 100%;
    height: 100%;
`;

export const Title = styled.h1`
  padding: 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 1rem;
`;

export const AddCommentWrapper = styled.div`
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    background: #fff;
    margin: 0;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    padding-right: 1rem;
`;

export const CommentTextWrapper = styled.div`
    display: flex;
    flex: 0 0 100%;
    background: #fff;
    margin: 0;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    width: 100%
    height: 50px;
`;

export const CommentHeaderWrapper = styled.div`
    display: flex;
    flex: 0 0 100%;
    background: #efefef;
    margin: 0;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    width: 100%
    height: 50px;
`;

export const RightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const CancelSaveRightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 20px;
`;

export const StyledButton = styled.button<IButtonProps>`
    font-size: 1em;
    margin: 3px;
    padding: 0.5em 1.5em;
    border-radius: 4px;
    color: #fff;
    border: 1px solid;
    outline: none;
    cursor: pointer;
    
    @media only screen and (max-width: 1280px) {
        font-size: 0.8rem;
        padding: 0.4em 0.8em;
    }

  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;
