import React, {useContext, useEffect, useState} from 'react'
import {
    FilterWrapperBottom,
    FilterWrapperTop,
    LeftBottomContentWrapper,
    LeftUpperContentWrapper,
    RightBottomContentWrapper,
    RightUpperContentWrapper,
    Title,
    Wrapper
} from '../AlertListFilters.styles';
import PriorityFilterComponent from "../priority-filter/PriorityFilter";
import SeverityFilterComponent from "../severity-filter/SeverityFilter";
import StatusFilterComponent from "../status-filter/StatusFilter";
import FromDateFilterComponent from "../from-date-filter/FromDateFilter";
import AssigneeFilterComponent from "./AssigneeFilter";
import ToDateComponent from "../to-date/ToDate"
import AlertTypeFilterComponent from "./AlertTypeFilter";
import VesselFilterComponent from "./VesselFilter";
import {AlertListMode} from "../../../enums/AlertListMode";
import SavingsStatusFilterComponent from "../savings-status-filter/SavingsStatusFilter";
import {AppAlertListContext} from "../../../context/AppAlertListContext";
import {AppStateContext} from "../../../context/AppStateContext";

type Props = {}

const AlertListFiltersComponent: React.FC<Props> = () => {
    var [numberOfAlerts, setNumberOfAlerts] = useState<number>(0);
    const { alertListMode
    } = useContext(AppStateContext)!;
    const {
        numberOfFilteredAlerts,
        numberOfFilteredSavingsAlerts
    } = useContext(AppAlertListContext)!;

    const getNumberOfAlerts = (): number => {
        if (alertListMode === AlertListMode.Default) {
            return numberOfFilteredAlerts;
        } else {
            return numberOfFilteredSavingsAlerts;
        }
    };

    useEffect(() => {
        if (numberOfFilteredAlerts) {
            setNumberOfAlerts(numberOfAlerts)
        }
    }, [numberOfFilteredAlerts]);

    return <>
        <Wrapper>
            <FilterWrapperTop>
                <LeftUpperContentWrapper>
                    <Title>Overview {alertListMode === AlertListMode.Default ? 'alerts' : 'savings'} ({getNumberOfAlerts()})</Title>
                </LeftUpperContentWrapper>
                <RightUpperContentWrapper>
                    <VesselFilterComponent />
                    <AlertTypeFilterComponent />
                    <AssigneeFilterComponent />
                </RightUpperContentWrapper>
            </FilterWrapperTop>
            <FilterWrapperBottom>
                {alertListMode === AlertListMode.Default &&
                <>
                    <LeftBottomContentWrapper>
                        <StatusFilterComponent/>
                        <PriorityFilterComponent />
                        <SeverityFilterComponent />
                    </LeftBottomContentWrapper>
                    <RightBottomContentWrapper>
                        <FromDateFilterComponent />
                        <ToDateComponent />
                    </RightBottomContentWrapper>
                </>
                }
                {alertListMode === AlertListMode.Savings &&
                <>
                    <LeftBottomContentWrapper>
                        <SavingsStatusFilterComponent/>
                        </LeftBottomContentWrapper>
                    <RightBottomContentWrapper>
                        <FromDateFilterComponent />
                        <ToDateComponent />
                    </RightBottomContentWrapper>
                </>
                }
            </FilterWrapperBottom>
        </Wrapper>
    </>;
}

export default AlertListFiltersComponent;
