import './Comments.scss';
import {
    AddCommentWrapper,
    CancelSaveRightContentWrapper,
    CommentTextWrapper,
    EditButtonWrapper,
    RightContentWrapper,
    StyledButton,
    Wrapper
} from './Comments.styles';
import React, {useContext, useEffect, useRef, useState} from 'react'
import {PrettifyDate} from '../../../utils/dateUtils';
import usePostAlertComment from "../../../api/usePostAlertComment";
import {AlertCommentFactory} from "../../../factory/AlertCommentFactory";
import {AppStateContext} from "../../../context/AppStateContext";
import {AlertCommentCategory} from "../../../enums/AlertCommentCategory";
import {AuthContext} from "../../../context/AuthContext";
import {CanEdit} from "../../../utils/userUtils";

type Props = {
}

const CommentsComponent: React.FC<Props> = () => {
    const { addAlertComment } = usePostAlertComment();
    const { alertDetails } = useContext(AppStateContext)!;
    var [btnEnabled, setBtnEnabled] = useState<boolean>(true);
    const alertCommentFactory = new AlertCommentFactory();
    const [commentTxt, setCommentTxt] = useState('');
    const commentTextInput = useRef(null);
    const { loggedInUser } = React.useContext(AuthContext);

    useEffect(() => {
        if (alertDetails && alertDetails.alertItem) {
            setBtnEnabled(true);
            setCommentTxt('');
            // @ts-ignore
            commentTextInput.current.hidden = true;
        }
    }, [alertDetails]);

    const addBtn = () => () => {
        setBtnEnabled(false);
        // @ts-ignore
        commentTextInput.current.hidden = false;
        // @ts-ignore
        commentTextInput.current.focus();
    };

    const saveBtn = () => () => {
        setBtnEnabled(true);
        // Post update assignee
        if (alertDetails && alertDetails.alertItem) {
            const alertComment= alertCommentFactory.createAlertCommentFromCode(
                0,
                alertDetails.alertItem.alertId,
                commentTxt,
                AlertCommentCategory.GeneralComment,
                '',
                new Date(),
                (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A')
            addAlertComment(alertComment);
        }
        setCommentTxt('');
        // @ts-ignore
        commentTextInput.current.hidden = true;
    };

    const cancelBtn = () => () => {
        setBtnEnabled(true);
        setCommentTxt('');
        // @ts-ignore
        commentTextInput.current.hidden = true;
    };

    const hasCommentTxt = (): boolean => {
        if (commentTxt) {
            return (commentTxt.length > 0);
        }
        return false;
    };

    // @ts-ignore
    return (
        <>
            {alertDetails && alertDetails.alertItem.commentList &&
            <Wrapper>
                {CanEdit(loggedInUser) && btnEnabled &&
                <EditButtonWrapper>
                    <RightContentWrapper>
                        <StyledButton className="add-btn" disabled={!btnEnabled || !CanEdit(loggedInUser)} isSelected={btnEnabled && CanEdit(loggedInUser)} onClick={addBtn()}>Add</StyledButton>
                    </RightContentWrapper>
                </EditButtonWrapper>
                }
                {!btnEnabled &&
                    <CancelSaveRightContentWrapper>
                        <StyledButton className="cancel-btn" disabled={false} isSelected={true} onClick={cancelBtn()}>Cancel</StyledButton>
                        <StyledButton className="primary-btn" disabled={!commentTxt || commentTxt.length === 0} isSelected={hasCommentTxt()} onClick={saveBtn()}>Save</StyledButton>
                    </CancelSaveRightContentWrapper>
                }
                
                <AddCommentWrapper>
                    <CommentTextWrapper>
                        <textarea
                            className="input"
                            hidden={true}
                            ref={commentTextInput}
                            value={commentTxt}
                            placeholder="Add a comment"
                            onChange={e => setCommentTxt(e.target.value)}
                        />
                    </CommentTextWrapper>
                    
                </AddCommentWrapper>
                    <ul className="comments-list">
                    {       
                        alertDetails.alertItem.commentList.map((alertComment, index) => {
                        return  (
                        <>
                            {  
                                alertComment.comment && alertComment.comment.length > 0 && 
                                    <li key={`comment-` + alertComment.alertCommentId}>
                                        <div  className="date-field-wrp field-wrp">
                                            <span className="date">{PrettifyDate(alertComment.createdUtc)}</span>
                                            <span className="asignee">{alertComment.createdBy}</span>
                                        </div>
                                        <p>{alertComment.comment}</p>
                                    </li>
                            }
                        </>)
                        })
                    }
                    </ul>

            </Wrapper>
            }
        </>
    );
};

export default CommentsComponent;
