import styled, {css} from "styled-components";
import {IButtonProps} from "../../alert-list-filters/priority-filter/PriorityFilter.styles";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

export const GridItemWrapper = styled.div`
    display: block;
    overflow: hidden;
`;

export const CommentTextWrapper = styled.div`
    display: flex;
    flex: 0 0 100%;
    background: #fff;
    margin-top: 1rem;
    width: 100%
    height: 50px;
    
    textarea{
        border: 1px solid #ddd;
        width: 100%;
        padding: 5px;
        margin: 5px;
        min-height: 80px;
        outline: none;
        background: #f8f8f8;
        font-family: inherit;
    }
`;

export const AddCommentWrapper = styled.div`
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    background: #fff;
    padding-right: 1rem;
`;

export const EditButtonWrapper = styled.div`
    display: flex;
    height: 50px;
`;

export const CancelSaveButtonWrapper = styled.div`
    display: flex;
    height: 50px;
    padding: 0.5rem;
`;

export const RightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const LeftContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 20%;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;


export const ButtonWrapper = styled.div`
    display: flex;
    padding: 1rem 0.5rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
`;

export const ButtonLeftContentWrapper = styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    justify-content: flex-start;
    display: flex;
`;

export const ButtonRightContentWrapper = styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: flex-end;
`;

export const StyledButton = styled.button<IButtonProps>`
    font-size: 1em;
    margin: 3px;
    padding: 0.5em 1em;
    border-radius: 4px;
    color: #fff;
    border: 1px solid;
    outline: none;
    cursor: pointer;
    
    @media only screen and (max-width: 1280px) {
        font-size: 0.8rem;
        padding: 0.4em 0.8em;
    }

    &:disabled{
        opacity: 0.25;  
    }
  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;

export const ListTable = styled.table`
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    thead {
        background: #efedeb;
        font-weight: 400;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        color: #190b05
    }

    th, td {
        padding: 0.5rem;
        font-size: 0.8rem;
        text-align: left;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
    }

    th {
        background: #eee;
        color: #222;
        font-weight: 500;
    }

    td {
        font-size: 0.8rem;
    }
`;
