import {AlertCommentCategory} from "../../enums/AlertCommentCategory";

export class AlertComment {
    constructor(
        alertCommentId: number,
        alertId: number,
        comment: string,
        alertCommentCategoryId: AlertCommentCategory,
        alertCommentCategoryName: string,
        createdUtc: Date,
        createdBy: string
    ) {
        this.alertCommentId = alertCommentId;
        this.alertId = alertId;
        this.comment = comment;
        this.alertCommentCategoryId = alertCommentCategoryId;
        this.alertCommentCategoryName = alertCommentCategoryName;
        this.createdUtc = createdUtc;
        this.createdBy = createdBy;
    }
    alertCommentId: number;
    alertId: number;
    comment: string;
    alertCommentCategoryId: AlertCommentCategory;
    alertCommentCategoryName: string;
    createdUtc: Date;
    createdBy: string;
}
