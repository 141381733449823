import { useContext, useEffect } from 'react';
import useApi from './useApi';
import {AlertTypeDataBM} from "../types/BusinessModel/AlertTypeDataBM";
import {AlertTypeFactory} from "../factory/AlertTypeFactory";
import {AppStateContext} from "../context/AppStateContext";

export default () => {
    const endpoint = 'GetAlertTypeData';
    const { state, getApiData } = useApi();
    const { setAlertTypeList, setIsLoadingAlertTypeList
    } = useContext(AppStateContext)!;

    const alertTypeFactory = new AlertTypeFactory();

    const getAlertTypeList = () => {
        getApiData(endpoint, '');
    };

    useEffect(() => {
        setIsLoadingAlertTypeList(state.isLoading);
        if (state.status === 'success'){
            const alertTypeData = state.result as unknown as AlertTypeDataBM;
            const alertTypeList = alertTypeFactory.getAlertTypeList(alertTypeData.alertTypeList);
            setAlertTypeList(alertTypeList);
        }
    }, [state]);

    return { state, getAlertTypeList };
}
