import { ContentWrapper, ChartsWrapper, UpperWrapper, LowerWrapper, RightWrapper } from './Statistics.styles';
import React, {useContext, useEffect, useState} from 'react';
import PageHeader from "../../components/page-header/PageHeader";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { AppStateContext } from '../../context/AppStateContext';
import drilldown from "highcharts/modules/drilldown";
import { HighChartModel } from '../../types/ViewModel/HighChartModel';
import { HighChartDrilldownParent } from '../../types/ViewModel/HighChartDrilldownParent';
import { HighChartDrilldownChild } from '../../types/ViewModel/HighChartDrilldownChild';
import StatisticsFiltersComponent from '../../components/statistics-filter/StatisticsFilters';
import SpinnerLoader from '../../components/loader/SpinnerLoader';
import useGetStatistics from '../../api/useGetStatistics';
import {groupAlertsByReportingGroup, groupAlertsByMonth, groupSavingsByMonth, 
        groupAlertsByShipDrillDown, groupAlertsPrAlertResponsible, groupAlertTypes, 
        groupAlertsByShip,
        generateHighChartsArray} from '../../utils/statsGroupingUtils';
import './Statistics.scss';

type Props = {
    title: string
}

drilldown(Highcharts);

const StatisticsComponent: React.FC<Props> = ({title}: Props) => {
    const [reportingGroupHighChartsArray, setReportingGroupHighChartsArray] = useState<HighChartDrilldownParent[]>([]);
    const [shipAlertsHighChartsArray, setShipAlertsHighChartsArray] = useState<HighChartDrilldownChild[]>([]);
    const [alertsPrPeriodGroupedHighChartsArray, setAlertsPrPeriodGroupedHighChartsArray] = useState<HighChartModel[]>([]);
    const [alertResponsibleGroupedHighChartsArray, setAlertResponsibleGroupedHighChartsArray] = useState<HighChartDrilldownParent[]>([]);
    const [shipsResponsibleHighChartsArray, setShipsResponsibleHighChartsArray] = useState<HighChartDrilldownChild[]>([]);
    const [shipsMostAlertsHighChartsArray, setShipsMostAlertsHighChartsArray] = useState<HighChartModel[]>([]);
    const [alertTypeGroupedHighChartsArray, setAlertTypeGroupedHighChartsArray] = useState<HighChartModel[]>([]);
    const [userGroupedLabelsArray, setUserGroupedLabelsArray] = useState<string[]>([]);
    const [savingsHighChartsArray, setSavingsHighChartsArray] = useState<HighChartModel[]>([]);
    
    const {
        alertTypeList,
        isLoadingStatistics,
        filteredStatisticsAlertList,
        setIsLoadingStatistics,
        vesselList
    } = useContext(AppStateContext)!;
    const { getStatistics } = useGetStatistics();

    
    const defaultColorPalette = () => {
        return ['#DE8231', '#64E572', '#4495DB', '#00447E', '#5D97C9', '#01203B', '#D0E3C4','#874913'];
    }

    const setAlertsPrPeriodChartOptions = () => {
        return {
            chart: {
                colors: defaultColorPalette()
            },
            legend: {
                enabled: false
            },
            title: {
                text: 'Alert trending'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            xAxis: {
                categories:  userGroupedLabelsArray,
                tickmarkPlacement: 'on',
                title: {
                    enabled: false,
                    text: 'Time'
                }
            },
            yAxis: {
                title: {
                    text: 'Number of alerts'
                },
            },
            series: [{
                name: 'Time',
                data: alertsPrPeriodGroupedHighChartsArray
            }]
        }
    }

    const setSavingsPrPeriodChartOptions = () => {
        return {
            colors: defaultColorPalette(),
            title: {
                text: 'Savings by period'
            },
            credits: {
                enabled: false
            },
            chart: {
                type: 'column',
            },
            plotOptions: {
                column: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            xAxis: {
                categories:  userGroupedLabelsArray,
                tickmarkPlacement: 'on',
                title: {
                    enabled: false,
                    text: 'Months'
                }
            },
            yAxis: {
                title: {
                    text: 'Savings in $'
                },
            },
            series: [
            {
                type: 'spline',
                name: 'Saving',
                data: savingsHighChartsArray
            }]
        }
    }

    const setReportingGroupChartOptions = () => {
        return {
            chart: {
                type: 'column'
            },
            legend: {
                enabled: false
            },
            colors: defaultColorPalette(),
            title: {
                text: 'Alerts grouped by reporting group'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
           
            yAxis: {
                title: {
                    text: 'Number of alerts'
                },
            },
            xAxis: {
                type: 'category',
                tickmarkPlacement: 'on',
                title: {
                    enabled: false,
                    text: 'Reporting groups'
                }
            },
            series: [{
                name: 'Reporting groups',
                data: reportingGroupHighChartsArray
            }],
            drilldown: {
                series: shipAlertsHighChartsArray,
            }
        }
    }
    
    const setAlertResposibleChartOptions = () => {
        return {
            chart: {
                type: 'bar',
            },
            legend: {
                enabled: false
            },
            colors: defaultColorPalette(),
            title: {
                text: 'Alert responsible by period'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            xAxis: {
                type: 'category',
                tickmarkPlacement: 'on',
                title: {
                    enabled: false,
                }
            },
            yAxis: {
                title: {
                    text: 'Number of alerts'
                },
            },
            series: [{
                name: 'Alert Responsible',
                data: alertResponsibleGroupedHighChartsArray
            }],
            drilldown: {
                series: shipsResponsibleHighChartsArray,
            }
        }
    }
    
    const setAlertTypeChartOptions = () => {
        return {
            chart: {
                type: 'bar',
                height: '800'
            },
            legend: {
                enabled: false
            },
            colors: defaultColorPalette(),
            title: {
                text: 'Alert types'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            xAxis: {
                type: 'category',
                tickmarkPlacement: 'on',
                title: {
                    enabled: false,
                }
            },
            yAxis: {
                title: {
                    text: 'Number of alerts'
                },
            },
            series: [{
                name: 'Months',
                data: alertTypeGroupedHighChartsArray
            },
            ]
        }
    }
    
    const setShipChartOptions = () => {
        return {
            chart: {
                type: 'column',
            },
            legend: {
                enabled: false
            },
            colors: defaultColorPalette(),
            title: {
                text: 'Ships with most alerts'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            yAxis: {
                title: {
                    text: 'Number of alerts'
                },
            },
            xAxis: {
                type: 'category',
                tickmarkPlacement: 'on',
                title: {
                    enabled: false,
                    text: 'Reporting groups'
                }
            },
            series: [{
                name: 'Ships',
                data: shipsMostAlertsHighChartsArray
            }]
        }
    }

    const initTrendingAlertsData = () => {
        const processedStatisticsAlertList = groupAlertsByMonth(filteredStatisticsAlertList);
        const highChartsArray = generateHighChartsArray(processedStatisticsAlertList);

        setUserGroupedLabelsArray(Object.keys(processedStatisticsAlertList))
        setAlertsPrPeriodGroupedHighChartsArray(highChartsArray);
    };

    const initSavingsByPeriod = () => {
        const savingsHighChartsArray: HighChartModel[] = [];

        const processedStatisticsSavingsList = groupSavingsByMonth(filteredStatisticsAlertList);

        Object.entries(processedStatisticsSavingsList).forEach(([key, value]) => {
            const highChartModel: HighChartModel = {
                name: key,
                fullname: key,
                y: processedStatisticsSavingsList[key].totalSavings
            };
            savingsHighChartsArray.push(highChartModel);
        });

        setSavingsHighChartsArray(savingsHighChartsArray);
    };

    const initReportingGroupAlertsData = () => {
        const reportingGroupHighChartsArray: HighChartDrilldownParent[] = [];
        const shipsHighChartsArray: HighChartDrilldownChild[] = [];

        const reportingGroupAlertList = groupAlertsByReportingGroup(filteredStatisticsAlertList);
        
        Object.entries(reportingGroupAlertList).forEach(([key, value]) => {
            const highChartModel: HighChartDrilldownParent = {
                name: key,
                y: reportingGroupAlertList[key].length,
                drilldown: key
            };
                reportingGroupHighChartsArray.push(highChartModel);
        });

        reportingGroupHighChartsArray.sort((a,b) => a.y > b.y ? -1 : 1);

        Object.entries(reportingGroupAlertList).forEach(([key, value]) => {
            const groupedShipAlerts = groupAlertsByShipDrillDown(reportingGroupAlertList[key]);
            groupedShipAlerts.sort((a,b) => a[1] > b[1] ? -1 : 1);
            const highChartModel: HighChartDrilldownChild = {
                    name: key,
                    id: key,
                    data: groupedShipAlerts     
                };
                shipsHighChartsArray.push(highChartModel);
        });

        setReportingGroupHighChartsArray(reportingGroupHighChartsArray);
        setShipAlertsHighChartsArray(shipsHighChartsArray);
    };

    const initAlertResponsibleAlertsData = () => {
        const alertsResponsibleHighChartsArray: HighChartDrilldownParent[] = [];
        const shipsHighChartsArray: HighChartDrilldownChild[] = [];
        const alertResponsibleAlertList = groupAlertsPrAlertResponsible(filteredStatisticsAlertList);

        Object.entries(alertResponsibleAlertList).forEach(([key, value]) => {
            const highChartModel: HighChartDrilldownParent = {
                name: key,
                y: alertResponsibleAlertList[key].length,
                drilldown: key
            };
            alertsResponsibleHighChartsArray.push(highChartModel);
        });

        alertsResponsibleHighChartsArray.sort((a,b) => a.y > b.y ? -1 : 1);

        Object.entries(alertResponsibleAlertList).forEach(([key]) => {
            const groupedShipAlerts = groupAlertsByShipDrillDown(alertResponsibleAlertList[key]);
            groupedShipAlerts.sort((a,b) => a[1] > b[1] ? -1 : 1);
            const highChartModel: HighChartDrilldownChild = {
                name: key,
                id: key,
                data: groupedShipAlerts     
            };

            shipsHighChartsArray.push(highChartModel);
        });

        setAlertResponsibleGroupedHighChartsArray(alertsResponsibleHighChartsArray);
        setShipsResponsibleHighChartsArray(shipsHighChartsArray);
    };

    const initAlertTypeAlertsData = () => {
        const processedStatisticsAlertList = groupAlertTypes(filteredStatisticsAlertList, alertTypeList);
        const highChartsArray = generateHighChartsArray(processedStatisticsAlertList);
        highChartsArray.sort((a,b) => a.y > b.y ? -1 : 1);
        setAlertTypeGroupedHighChartsArray(highChartsArray);
    };

    const initShipsAlertsData = () => {
        const alertsPrShipList = groupAlertsByShip(filteredStatisticsAlertList);
        const highChartsArray = generateHighChartsArray(alertsPrShipList);

        highChartsArray.sort((a,b) => a.y > b.y ? -1 : 1);
        setShipsMostAlertsHighChartsArray(highChartsArray);
    };

    useEffect(() => {
        setIsLoadingStatistics(true);
        getStatistics();
    }, []);

    useEffect(() => {
        if(vesselList && vesselList.length > 0){
            filteredStatisticsAlertList.forEach(statisticsAlert => {
                if(statisticsAlert && vesselList && vesselList.length > 0){
                   const foundVessel = vesselList?.find(vessel => vessel.imoNumber === statisticsAlert.imoNumber);
                   const reportingGroup = foundVessel?.reportingGroup;
                   const shipName = foundVessel?.name;
                   if(reportingGroup){
                    statisticsAlert.reportingGroup = reportingGroup;
                   }

                   if(shipName){
                    statisticsAlert.shipName = shipName;
                   }
                }
            });
                
            Highcharts.setOptions({
                colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
            });

            initTrendingAlertsData();
            initSavingsByPeriod();
            initReportingGroupAlertsData();
            initAlertResponsibleAlertsData();
            initAlertTypeAlertsData();
            initShipsAlertsData();
        }
    }, [filteredStatisticsAlertList]);

       return (
        <>
        <PageHeader />
        <ContentWrapper>
        { isLoadingStatistics && <SpinnerLoader showOverlay={true} /> }
            <StatisticsFiltersComponent/>
            <ChartsWrapper>
            <UpperWrapper>
                <div className="cell">
                    <HighchartsReact width="100%" height="100%" highcharts={Highcharts} options={setShipChartOptions()}/>
                </div>
                <div className="cell double-row">
                    <HighchartsReact class="chart-full-height" width="100%" height="100%" highcharts={Highcharts} options={setAlertTypeChartOptions()}/>
                </div>
                <div className="cell">
                    <HighchartsReact width="100%" height="100%" highcharts={Highcharts} options={setReportingGroupChartOptions()}/>
                </div>
            </UpperWrapper>
            <LowerWrapper>
                <div className="cell">
                    <HighchartsReact width="100%" height="100%" highcharts={Highcharts} options={setSavingsPrPeriodChartOptions()}/>
                </div>
                <div className="cell">
                    <HighchartsReact width="100%" height="100%" highcharts={Highcharts} options={setAlertResposibleChartOptions()}/>
                </div>
                <div className="cell">
                    <HighchartsReact width="100%" height="100%" highcharts={Highcharts} options={setAlertsPrPeriodChartOptions()}/>
                </div>
            </LowerWrapper>
            </ChartsWrapper>
        </ContentWrapper>
        </>
    );
}
export default StatisticsComponent;

