import { ListTable } from './AlertListDefault.styles';
import React, { useContext, useEffect, useState } from 'react';
import { AlertItem } from '../../../types/ViewModel/AlertItem';
import { PrettifyDate } from '../../../utils/dateUtils';
import useGetAlertDetails from '../../../api/useGetAlertDetails';
import { DetailComponent } from '../../../enums/DetailComponent';
import { PaginatorComponent } from '../../paginator/Paginator';
import useAppAlertList from '../../../app/useAppAlertList';
import { GetAlertStatusName } from '../../../utils/workFlowUtils';
import { AppStateContext } from '../../../context/AppStateContext';
import { AppAlertListContext } from '../../../context/AppAlertListContext';
import SortIconComponent from '../../sort-icon/SortIcon';
import moment from 'moment';

type Props = {};

const AlertListDefaultComponent: React.FC<Props> = () => {
    const [sortColumn, setSortColumn] = useState<SortColumn>('aoldate');
    const [isSortDesc, setIsSortDesc] = useState<boolean>(false);
    const [paginatedFilteredAlerts, setPaginatedFilteredAlerts] = useState<AlertItem[]>([]);
    const [sortedFilteredAlerts, setSortedFilteredAlerts] = useState<AlertItem[]>([]);
    const { setDetailComponentId, filterAlertData, adGroupUserList, adGroupAdminList } = useContext(AppStateContext)!;
    const { filterAlertList } = useAppAlertList()!;
    const { isAlertItemUpdated, filteredAlertListView, setSelectedAlertItem, selectedAlertItem, alertListView } =
        useContext(AppAlertListContext)!;
    const { getAlertDetails } = useGetAlertDetails();

    const showAlertDetails = (alertViewItem: AlertItem) => {
        setSelectedAlertItem(alertViewItem);
        getAlertDetails(alertViewItem.alertId);
        setDetailComponentId(DetailComponent.Tabs);
    };

    useEffect(() => {
        if (isAlertItemUpdated) {
            filterAlertList(alertListView, filterAlertData);
        }
    }, [isAlertItemUpdated]);

    useEffect(() => {
        if (!adGroupUserList || !adGroupAdminList) {
            return;
        }

        const alertList = getSortedAlertItems(sortColumn, isSortDesc, filteredAlertListView);
        setSortedFilteredAlerts(alertList);
    }, [filteredAlertListView]);

    type SortColumn =
        | 'alerttype'
        | 'vessel'
        | 'alertdate'
        | 'aoldate'
        | 'assignee'
        | 'priority'
        | 'severity'
        | 'alertstatus';

    const getSortedAlertItems = (column: SortColumn, sortDesc: boolean, items: AlertItem[]): AlertItem[] => {
        let sortetAlertItems = [...items];

        if (column === 'alerttype') {
            sortetAlertItems = sortetAlertItems.sort((a, b) =>
                sortDesc
                    ? a.alertTypeDetails.alertTypeName.toLowerCase() > b.alertTypeDetails.alertTypeName.toLowerCase()
                        ? 1
                        : -1
                    : a.alertTypeDetails.alertTypeName.toLowerCase() < b.alertTypeDetails.alertTypeName.toLowerCase()
                    ? 1
                    : -1
            );
        }
        if (column === 'vessel') {
            sortetAlertItems = sortetAlertItems.sort((a, b) =>
                sortDesc
                    ? a.alertContext.vesselName > b.alertContext.vesselName
                        ? 1
                        : -1
                    : a.alertContext.vesselName < b.alertContext.vesselName
                    ? 1
                    : -1
            );
        }
        if (column === 'assignee') {
            sortetAlertItems = sortetAlertItems.sort((a, b) =>
                sortDesc
                    ? a.assignee.toLowerCase() > b.assignee.toLowerCase()
                        ? 1
                        : -1
                    : a.assignee.toLowerCase() < b.assignee.toLowerCase()
                    ? 1
                    : -1
            );
        }
        if (column === 'priority') {
            sortetAlertItems = sortetAlertItems.sort((a, b) =>
                sortDesc
                    ? a.priorityName.toLowerCase() > b.priorityName.toLowerCase()
                        ? 1
                        : -1
                    : a.priorityName.toLowerCase() < b.priorityName.toLowerCase()
                    ? 1
                    : -1
            );
        }
        if (column === 'severity') {
            sortetAlertItems = sortetAlertItems.sort((a, b) =>
                sortDesc
                    ? a.severityName.toLowerCase() > b.severityName.toLowerCase()
                        ? 1
                        : -1
                    : a.severityName.toLowerCase() < b.severityName.toLowerCase()
                    ? 1
                    : -1
            );
        }
        if (column === 'alertstatus') {
            sortetAlertItems = sortetAlertItems.sort((a, b) =>
                sortDesc
                    ? a.alertStatusName.toLowerCase() > b.alertStatusName.toLowerCase()
                        ? 1
                        : -1
                    : a.alertStatusName.toLowerCase() < b.alertStatusName.toLowerCase()
                    ? 1
                    : -1
            );
        }
        if (column === 'alertdate') {
            sortetAlertItems = sortetAlertItems.sort((a, b) => {
                if (sortDesc) {
                    return moment(b.alertDate).isAfter(moment(a.alertDate)) ? 1 : -1;
                }
                return moment(a.alertDate).isAfter(moment(b.alertDate)) ? 1 : -1;
            });
        }
        if (column === 'aoldate') {
            sortetAlertItems = sortetAlertItems.sort((a, b) => {
                if (sortDesc) {
                    return moment(b.aolDate).isAfter(moment(a.aolDate)) ? 1 : -1;
                }
                return moment(a.aolDate).isAfter(moment(b.aolDate)) ? 1 : -1;
            });
        }

        return sortetAlertItems;
    };

    const sortByColumn = (column: SortColumn) => {
        const sortDesc = !isSortDesc;
        const sortedAlertItems = getSortedAlertItems(column, sortDesc, sortedFilteredAlerts);
        setSortColumn(column);
        setSortedFilteredAlerts(sortedAlertItems);
        setIsSortDesc(sortDesc);
    };

    return (
        <>
            <ListTable>
                <thead>
                    <tr>
                        <th
                            className="sortable-th alert-type-col"
                            onClick={() => sortByColumn('alerttype')}
                            title="Order by alert type"
                        >
                            Alert type
                            {sortColumn === 'alerttype' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th
                            className="sortable-th vessel-name"
                            onClick={() => sortByColumn('vessel')}
                            title="Order by vessel name"
                        >
                            Vessel
                            {sortColumn === 'vessel' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th
                            className="sortable-th date-col"
                            onClick={() => sortByColumn('alertdate')}
                            title="Order by alert date"
                        >
                            Alert date
                            {sortColumn === 'alertdate' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th
                            className="sortable-th date-col"
                            onClick={() => sortByColumn('aoldate')}
                            title="Order by AoL date"
                        >
                            AoL date
                            {sortColumn === 'aoldate' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th className="value-col">Value</th>
                        <th className="limit-col">Limit</th>
                        <th
                            className="sortable-th assignee-col"
                            onClick={() => sortByColumn('assignee')}
                            title="Order by assignee"
                        >
                            Assignee
                            {sortColumn === 'assignee' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th
                            className="sortable-th priority-col"
                            onClick={() => sortByColumn('priority')}
                            title="Order by priority"
                        >
                            Priority
                            {sortColumn === 'priority' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th
                            className="sortable-th severity-col"
                            onClick={() => sortByColumn('severity')}
                            title="Order by severity"
                        >
                            Severity
                            {sortColumn === 'severity' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th
                            className="sortable-th status-col"
                            onClick={() => sortByColumn('alertstatus')}
                            title="Order by alert status"
                        >
                            Alert status
                            {sortColumn === 'alertstatus' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>

                        {/*<th className="alert-type-col">Alert type</th>*/}
                        {/*<th className="vessel-name">Vessel</th>*/}
                        {/*<th className="date-col">Alert date</th>*/}
                        {/*<th className="date-col">AoL date</th>*/}
                        {/*<th className="value-col">Value</th>*/}
                        {/*<th className="limit-col">Limit</th>*/}
                        {/*<th className="assignee-col">Assignee</th>*/}
                        {/*<th className="priority-col">Priority</th>*/}
                        {/*<th className="severity-col">Severity</th>*/}
                        {/*<th className="status-col">Alert status</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {paginatedFilteredAlerts.map((alertItem, i) => {
                        return (
                            <tr
                                key={i}
                                onClick={() => showAlertDetails(alertItem)}
                                className={`${alertItem.alertId === selectedAlertItem?.alertId ? 'selected' : ''}`}
                            >
                                <td className="alert-type-col">{alertItem.alertTypeDetails.alertTypeName}</td>
                                <td className="vessel-name">{alertItem.alertContext.vesselName}</td>
                                <td className="date-col">{PrettifyDate(alertItem.alertDate)}</td>
                                <td className="date-col">{PrettifyDate(alertItem.aolDate)}</td>
                                <td className="value-col">{alertItem.value?.toLocaleString('no-NO')}</td>
                                <td className="limit-col">{alertItem.limit?.toLocaleString('no-NO')}</td>
                                <td className="assignee-col">{alertItem.assignee}</td>
                                <td className="priority-col">{alertItem.priorityName}</td>
                                <td className="severity-col">{alertItem.severityName}</td>
                                <td className="status-col">
                                    {alertItem.alertStatusId === 1 && (
                                        <>
                                            <span className="status-dot-marker status-new"></span>
                                            {GetAlertStatusName(alertItem.alertStatusId)}
                                        </>
                                    )}
                                    {alertItem.alertStatusId === 2 && (
                                        <>
                                            <span className="status-dot-marker status-in-progress"></span>
                                            {GetAlertStatusName(alertItem.alertStatusId)}
                                        </>
                                    )}
                                    {alertItem.alertStatusId === 3 && (
                                        <>
                                            <span className="status-dot-marker status-exclude"></span>
                                            {GetAlertStatusName(alertItem.alertStatusId)}
                                        </>
                                    )}
                                    {alertItem.alertStatusId === 4 && (
                                        <>
                                            <span className="status-dot-marker status-complete"></span>
                                            {GetAlertStatusName(alertItem.alertStatusId)}
                                        </>
                                    )}
                                    {alertItem.alertStatusId === 5 && (
                                        <>
                                            <span className="status-dot-marker status-muted"></span>
                                            {GetAlertStatusName(alertItem.alertStatusId)}
                                        </>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </ListTable>
            <div className="paginator-wrapper">
                <PaginatorComponent list={sortedFilteredAlerts} setList={setPaginatedFilteredAlerts} />
            </div>
        </>
    );
};
export default AlertListDefaultComponent;
