import React from 'react'
import {Redirect, Route} from 'react-router-dom';
import {AuthContext} from "../context/AuthContext";

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
    const { loggedInUser } = React.useContext(AuthContext);

    const toComp = () => {
        if (loggedInUser) {
            return true;
        }
        return false;
    }

    return (<Route {...rest}
         render = {props => {
             return (toComp()) ? (<Component {...props} />) : <Redirect to={
                 {
                     pathname: '/login',
                     state: { from: props.location }
                 }
             }
             />
         }}
    />);
}
