import React, {useContext, useEffect} from 'react';
import MultiSelect from "react-multi-select-component";
import {Wrapper, ContentWrapper, DropdownWrapper, TextWrapper, Title} from "./DropDownFilter.styles";
import {AppStateContext} from "../../../context/AppStateContext";

type Props = {}

const AlertTypeStatsFilterComponent: React.FC<Props> = () => {
    const {
        alertTypeList,
        statisticsAlertList,
        selectedStatisticsAlertTypes,
        setSelectedStatisticsAlertTypes
    } = useContext(AppStateContext)!;
    
    useEffect(() => {
        if(statisticsAlertList && alertTypeList.length > 0){
            setSelectedStatisticsAlertTypes(alertTypeList);
        }
    }, [statisticsAlertList]);
    
    const handleChange = (selectedItems) => {
        setSelectedStatisticsAlertTypes(selectedItems);
    };

    let filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    return (
        <>
            <Wrapper>
                <ContentWrapper>
                    <TextWrapper>
                        <Title>Alert type</Title>
                    </TextWrapper>
                    <DropdownWrapper>
                        <MultiSelect
                            className="select-alert-type"
                            options={alertTypeList}
                            filterOptions = {filterOptions}
                            value={selectedStatisticsAlertTypes}
                            onChange={handleChange}
                            labelledBy={"Select"}
                            overrideStrings={{
                                selectSomeItems: "Select alert types",
                                allItemsAreSelected: "All",
                                selectAll: "Select All",
                                search: "Search",
                            }}
                        />
                    </DropdownWrapper>
                </ContentWrapper>
            </Wrapper>
        </>
    );
}

export default AlertTypeStatsFilterComponent;
