import styled from "styled-components";

export const ContentWrapper = styled.div`
    height: 100%;
    overflow: hidden;
`;

export const ChartsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 155px);
    width: 100%;
    overflow: auto;
  
`;

export const UpperWrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 50% 50%;
    width: calc(100% - 20px);
    margin-bottom: 40px;
    
    .double-row{
        grid-row: 1 / 3;
        grid-column: 2 / 2;
    }
`;

export const LowerWrapper = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns:  repeat(3, 1fr);
    width: calc(100% - 40px);
`;

export const RightWrapper = styled.div`
    display: block;
    width: calc(100% - 40px);
    height: 100%!important;
`;


