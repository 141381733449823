import {AlertTypeMute} from "../types/ViewModel/AlertTypeMute";
import {AlertTypeMuteBM} from "../types/BusinessModel/AlertTypeMuteBM";
import {AlertComment} from "../types/ViewModel/AlertComment";
import {AlertCommentFactory} from "./AlertCommentFactory";

export class AlertTypeMuteFactory {
    _alertCommentFactory: AlertCommentFactory;

    constructor(
    ) {
        this._alertCommentFactory = new AlertCommentFactory();
    }

    public getAlertTypeMute(item: AlertTypeMuteBM): AlertTypeMute {
        return new AlertTypeMute(
            item.imoNumber,
            item.alertTypeId,
            item.mutedUntilDate,
            item.cargoNumber,
            item.tankName,
            item.allTanks,
            null,
            item.createdUtc,
            item.createdBy,
            item.lastUpdatedUtc,
            item.lastUpdatedBy);
    }

    public getAlertTypeMuteList(alertTypeListBM: AlertTypeMuteBM[]): AlertTypeMute[] {
        return alertTypeListBM.map(o => this.getAlertTypeMute(o));
    }

    public createAlertTypeMuteFromCode(
        imoNumber: number,
        alertTypeId: number,
        alertId: number,
        mutedUntilDate: Date,
        cargoNumber: string,
        tankName: string,
        allTanks: boolean,
        createdUtc: Date,
        createdBy: string,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string
    ): AlertTypeMute
    {
        return new AlertTypeMute(
            imoNumber,
            alertTypeId,
            mutedUntilDate,
            cargoNumber,
            tankName,
            allTanks,
            null,
            createdUtc,
            createdBy,
            lastUpdatedUtc,
            lastUpdatedBy);
    }

    public getAlertTypeMuteBM(
        imoNumber: number,
        alertTypeId: number,
        alertId: number,
        mutedUntilDate: Date,
        cargoNumber: string,
        tankName: string,
        allTanks: boolean,
        comment: AlertComment | null,
        createdUtc: Date,
        createdBy: string,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string
    ): AlertTypeMuteBM {
        return {
            imoNumber: imoNumber,
            alertTypeId: alertTypeId,
            alertId: alertId,
            mutedUntilDate: mutedUntilDate,
            cargoNumber: cargoNumber,
            tankName: tankName,
            allTanks: allTanks,
            comment: comment ? this._alertCommentFactory.getAlertCommentBM(comment) : null,
            createdUtc: createdUtc,
            createdBy: createdBy,
            lastUpdatedUtc: lastUpdatedUtc,
            lastUpdatedBy: lastUpdatedBy
        }
    }
}
