import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Prop = {
    sortFunction?: (params: any) => void,
    sortDesc: boolean,
    title?: string
}

const SortIcon = styled.div`
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    cursor: pointer;

    .sort-container {
        position: relative;
        right: 0.6rem;

        svg {
            position: absolute;
            color: #f1f1f1;
        }

        .active-icon {
            color: #000000;
        }
    }
`;

const SortIconComponent: React.FC<Prop> = (props) => {
    return (
        <>
            <SortIcon onClick={props.sortFunction}>
                <div className="sort-container" title={props.title}>
                    <FontAwesomeIcon className={!props.sortDesc ? 'active-icon' : ''} icon="sort-up" />
                    <FontAwesomeIcon className={props.sortDesc ? 'active-icon' : ''} icon="sort-down" />
                </div>
            </SortIcon>
        </>
    );
}

export default SortIconComponent;
