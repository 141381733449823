import moment from "moment";

export const PadStr = (str: string, size: number) => {
    var paddedStr: string = str;
    while (paddedStr.length < (size || 2)) {paddedStr = '0' + paddedStr;}
    return paddedStr;
};

export const PrettifyDate = (date: Date) => date ? moment(date).format('DD.MM.YYYY') : '';

export const PrettifyDateTime = (date: Date) => date ? moment(date).format('DD.MM.YYYY HH:ss') : '';

export const AddDays = (date: Date, numberOfDays: number): Date => {
    return moment(date).add(numberOfDays, 'd').toDate();
};
