import React, {useContext, useEffect} from 'react';
import useApi from './useApi';
import {AppStateContext} from "../context/AppStateContext";
import { StatisticsAlertBM } from '../types/BusinessModel/StatisticsAlertBM';
import { StatisticsAlertFactory } from '../factory/StatisticsAlertFactory';
import { StatisticsAlert } from '../types/ViewModel/StatisticsAlert';

export default () => {
    const endpoint = 'GetStatisticsAlerts';
    const { state, getApiData } = useApi();
    var {
        setStatisticsAlertList,
        setIsLoadingStatistics
    } = useContext(AppStateContext)!;
   
    const getStatistics = () => {
        if (!endpoint) return;
        getApiData(endpoint, '');
    };

    const statisticsAlertFactory = new StatisticsAlertFactory();

    useEffect(() => {
        setIsLoadingStatistics(state.isLoading);
        if (state.status === 'success'){
            setIsLoadingStatistics(false);
            const statisticsAlertData = state.result as unknown as StatisticsAlertBM[];
            const statisticsAlertList: StatisticsAlert[] = statisticsAlertFactory.getStatisticsAlertList(statisticsAlertData);
            if (statisticsAlertList) {
                setStatisticsAlertList([...statisticsAlertList]);
            }
        }
    }, [state]);

    return { state, getStatistics };
}
