import styled from "styled-components";

export const OverlayLoader = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    bottom:0;
    left: 0;
    z-index: 999;
    opacity: 0.7;

    .loader-wrapper {
        text-align: center;
        top: 30%;
        position: absolute;
        left: 50%;
    }
    
    .loader-text {
        color: #12427d;
    }
`;

