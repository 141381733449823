import {AlertItem} from "../types/ViewModel/AlertItem";
import {AlertType} from "../types/ViewModel/AlertType";

export const SetProcedureUrlForAlert = (alert: AlertItem, alertTypeList: AlertType[]): string => {
    const alertType = alertTypeList.find(o => +o.value === alert.alertTypeDetails.alertTypeId);
    if (alertType) {
        return alertType.procedureUrl;
    }
    return '';
};
