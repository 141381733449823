import {AlertSavingsPerFuelType} from "../types/ViewModel/AlertSavingsPerFuelType";
import {AlertSavings} from "../types/ViewModel/AlertSavings";
import {AlertSavingsBM} from "../types/BusinessModel/AlertSavingsBM";
import {AlertSavingsPerFuelTypeFactory} from "./AlertSavingsPerFuelTypeFactory";
import {AlertSavingsStatus} from "../enums/AlertSavingsStatus";

export class AlertSavingsFactory {
    constructor(
    ) {
    }

    public createAlertSavingsFromCode(
        alertId: number,
        alertSavingsPerFuelTypeList: AlertSavingsPerFuelType[],
        alertSavingsDescription: string,
        alertSavingsStatusId: AlertSavingsStatus,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string): AlertSavings {
        return new AlertSavings(
            alertId,
            alertSavingsPerFuelTypeList,
            alertSavingsDescription,
            alertSavingsStatusId,
            lastUpdatedUtc,
            lastUpdatedBy);
    }

    public getAlertSavingsBM(alertSavings: AlertSavings): AlertSavingsBM {
        const alertSavingsPerFuelTypeFactory = new AlertSavingsPerFuelTypeFactory();
        return {
            alertId: alertSavings.alertId,
            alertSavingsPerFuelTypeList: alertSavingsPerFuelTypeFactory.getAlertSavingsPerFuelTypeListBM(alertSavings.alertSavingsPerFuelTypeList),
            alertSavingsDescription: alertSavings.alertSavingsDescription,
            lastUpdatedUtc: alertSavings.lastUpdatedUtc,
            lastUpdatedBy: alertSavings.lastUpdatedBy
        }
    }
}
