import {AlertSeverity} from "../../enums/AlertSeverity";
import {AlertPriority} from "../../enums/AlertPriority";
import {AlertStatus} from "../../enums/AlertStatus";
import {AlertSavingsStatus} from "../../enums/AlertSavingsStatus";
import {AlertContext} from "./AlertContext";
import {AlertComment} from "./AlertComment";
import {AlertTypeDetails} from "./AlertTypeDetails";

export class AlertItem {
    constructor(
        alertId: number,
        // alertTypeId: number,
        // alertTypeName: string,
        alertTypeDetails: AlertTypeDetails,
        alertSourceId: number,
        alertSourceName: string,
        commentList: AlertComment[],
        alertDate: Date,
        aolDate: Date,
        value: number,
        limit: number,
        hfoPrice: number,
        mgoPrice: number,
        severityId: AlertSeverity,
        severityName: string,
        priorityId: AlertPriority,
        priorityName: string,
        assignee: string,
        alertStatusId: AlertStatus,
        alertStatusName: string,
        alertSavingsStatusId: AlertSavingsStatus,
        alertSavingsStatusName: string,
        totalSavingsInUsd: number,
        alertContext: AlertContext,
        alertSavingsDescription: string,
        alertMutedUntil: Date | null,
        createdUtc: Date,
        createdBy: string,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string
    )
    {
        this.alertId = alertId;
        // this.alertTypeId = alertTypeId;
        // this.alertTypeName = alertTypeName;
        this.alertTypeDetails = alertTypeDetails;
        this.alertTypeProcedureUrl = '';
        this.alertSourceId = alertSourceId;
        this.alertSourceName = alertSourceName;
        this.commentList = commentList;
        this.alertDate = alertDate;
        this.aolDate = aolDate;
        this.value = value;
        this.limit = limit;
        this.hfoPrice = hfoPrice;
        this.mgoPrice = mgoPrice;
        this.severityId = severityId;
        this.severityName = severityName;
        this.priorityId = priorityId;
        this.priorityName = priorityName;
        this.assignee = assignee;
        this.alertStatusId = alertStatusId;
        this.alertStatusName = alertStatusName;
        this.alertSavingsStatusId = alertSavingsStatusId;
        this.alertSavingsStatusName = alertSavingsStatusName;
        this.totalSavingsInUsd = totalSavingsInUsd;
        this.alertContext = alertContext;
        this.alertSavingsDescription = alertSavingsDescription;
        this.alertMutedUntil = alertMutedUntil;
        this.createdUtc = createdUtc;
        this.createdBy = createdBy;
        this.lastUpdatedUtc = lastUpdatedUtc;
        this.lastUpdatedBy= lastUpdatedBy;
    }
    alertId: number;
    alertTypeDetails: AlertTypeDetails;
    // alertTypeId: number;
    // alertTypeName: string;
    alertTypeProcedureUrl: string;
    alertSourceId: number;
    alertSourceName: string;
    commentList: AlertComment[];
    alertDate: Date;
    aolDate: Date;
    value: number;
    limit: number;
    hfoPrice: number;
    mgoPrice: number;
    severityId: AlertSeverity;
    severityName: string;
    priorityId: AlertPriority;
    priorityName: string;
    assignee: string;
    alertStatusId: AlertStatus;
    alertStatusName: string;
    alertSavingsStatusId: AlertSavingsStatus;
    alertSavingsStatusName: string;
    totalSavingsInUsd: number;
    alertContext: AlertContext;
    alertSavingsDescription: string;
    alertMutedUntil: Date | null;
    createdUtc: Date;
    createdBy: string;
    lastUpdatedUtc: Date;
    lastUpdatedBy: string;
}
