import {AlertType} from "../types/ViewModel/AlertType";
import {AlertTypeBM} from "../types/BusinessModel/AlertTypeBM";
import {AlertTypeMuteFactory} from "./AlertTypeMuteFactory";

export class AlertTypeFactory {
    _alertTypeMuteFactory: AlertTypeMuteFactory;

    constructor(
    ) {
        this._alertTypeMuteFactory = new AlertTypeMuteFactory();
    }

    public getAlertType(item: AlertTypeBM): AlertType {
        return new AlertType(
            item.alertTypeName,
            String(item.alertTypeId),
            item.procedureUrl,
            item.alertTypeMuteList ? this._alertTypeMuteFactory.getAlertTypeMuteList(item.alertTypeMuteList) : []);
    }

    public getAlertTypeList(alertTypeListBM: AlertTypeBM[]): AlertType[] {
        return alertTypeListBM.map(o => this.getAlertType(o));
    }
}
