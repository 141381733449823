import {AlertPriority} from "../../../enums/AlertPriority";
import styled, {css} from "styled-components";

export interface IButtonProps {
    isSelected?: boolean;
    onClick?: (alertPriority: AlertPriority) => void;
}

export const Wrapper = styled.div`
    padding: 0 0 0 15px;
`;

export const StyledButton = styled.button<IButtonProps>`
  background: #12427d;
  border: 1px solid #12427d;
  color: #fff;  
  height: 24px;
  padding: 2px 8px;
  font-size: 12px;
  margin: 1px;
  outline: 0;
  cursor: pointer;
  border-radius: 3px;

  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;

