import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    margin-top: 1rem;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: #fff;
    width: 98%;
    padding: 5px 10px;
    
    select {
        margin: 0 1rem;
    }
`;
