import { StatisticsAlertBM } from "../types/BusinessModel/StatisticsAlertBM";
import { StatisticsAlert } from "../types/ViewModel/StatisticsAlert";

export class StatisticsAlertFactory {
    public getStatisticsAlert(item: StatisticsAlertBM): StatisticsAlert {

        const reportingGroup = 'Reporting group' + item.alertStatusId;

        return new StatisticsAlert(
            item.alertId,
            item.alertTypeId,
            item.alertStatusId,
            item.savingsStatusId,
            item.alertDate,
            item.assignee,
            item.totalSavingsInUsd,
            item.imoNumber,
            '',
            ''
            );
    }

    public getStatisticsAlertList(itemListBM: StatisticsAlertBM[]): StatisticsAlert[] {
        return itemListBM.map(o => this.getStatisticsAlert(o));
    }
}
