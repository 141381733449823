import './WorkflowExcluded.scss';
import {
    Wrapper,
    Title,
    CommentHeaderWrapper,
    CommentTextWrapper,
    StyledButton,
    AddCommentWrapper,
    CancelSaveRightContentWrapper, MuteWrapper
} from './WorkflowExcluded.styles';
import React, {useContext, useEffect, useRef, useState} from 'react'
import {AppStateContext} from "../../../context/AppStateContext";
import {DetailComponent} from "../../../enums/DetailComponent";
import usePostAlertStatusUpdate from "../../../api/usePostAlertStatusUpdate";
import usePostMuteAlert from "../../../api/usePostMuteAlert";
import {AlertStatus} from "../../../enums/AlertStatus";
import {AuthContext} from "../../../context/AuthContext";
import ToggleInput from "../../toggle-input/ToggleInput";
import DatePicker from "react-datepicker";

type Props = {
}

var radio_props = [
    {label: 'Selected Tank', value: 0 },
    {label: 'All Tanks', value: 1 }
];

const WorkflowExcludedComponent: React.FC<Props> = () => {
    const { updateAlertStatus } = usePostAlertStatusUpdate();
    const { muteAlert } = usePostMuteAlert();
    const { alertDetails, setDetailComponentId, alertTypeList } = useContext(AppStateContext)!;
    const [commentTxt, setCommentTxt] = useState('');
    const commentTextInput = useRef(null);
    const { loggedInUser } = React.useContext(AuthContext);
    var [isMuted, setIsMuted] = useState<boolean>(false);
    var [allTanks, setAllTanks] = useState<boolean>(false);
    var [hasTank, setHasTank] = useState<boolean>(false);
    var [hasCargo, setHasCargo] = useState<boolean>(false);
    var [alreadyMuted, setAlreadyMuted] = useState<boolean>(false);
    var [mutedUntilDate, setMutedUntilDate] = useState<Date>(new Date());
    var [commentPlaceholder, setCommentPlaceholder] = useState<string>('Add a comment to status changed');

    useEffect(() => {
        if (alertDetails && alertDetails.alertItem) {
            setCommentTxt('');
            if (alertDetails && alertDetails.alertItem.alertContext.tankName && alertDetails.alertItem.alertContext.tankName.length > 0) {
                hasTank = true;
            } else {
                hasTank = false;
            }
            setHasTank(hasTank);
            if (alertDetails && alertDetails.alertItem.alertContext.cargoNumber && alertDetails.alertItem.alertContext.cargoNumber.length > 0) {
                hasCargo = true;
            } else {
                hasCargo = false;
            }
            setHasCargo(hasCargo);

            var alertType = alertTypeList.find(o => o.value === String(alertDetails.alertItem.alertTypeDetails.alertTypeId));
            if (alertType) {
                var alertTypeMute = alertType.alertTypeMuteList.find(o => o.imoNumber === alertDetails.alertItem.alertContext.imoNumber);
                if (alertTypeMute) {
                    setAlreadyMuted(true);
                } else {
                    setAlreadyMuted(false);
                }
            } else {
                setAlreadyMuted(false);
            }
        }
    }, [alertDetails]);

    const saveBtn = () => () => {
        if (isMuted) {
            muteAlert(mutedUntilDate, allTanks, commentTxt, new Date(), (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A');
        } else {
            // Post update alert status
            if (alertDetails && alertDetails.alertItem) {
                updateAlertStatus(AlertStatus.Excluded, commentTxt, new Date(), (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A');
            }
            setCommentTxt('');
            // @ts-ignore
            commentTextInput.current.hidden = true;
            setDetailComponentId(DetailComponent.Tabs);
        }
    };

    const cancelBtn = () => () => {
        setCommentTxt('');
        // @ts-ignore
        commentTextInput.current.hidden = true;
        setDetailComponentId(DetailComponent.Tabs);
    };

    const onChecked = (e: any) => {
        isMuted = !isMuted;
        setIsMuted(isMuted);
        if (isMuted) {
            setAllTanks(hasTank);
            commentPlaceholder = 'Add a mute comment';
        } else {
            setAllTanks(false);
            commentPlaceholder = 'Add a comment to status changed';
        }
        setCommentPlaceholder(commentPlaceholder);
    };

    const onDateChanged = (date: Date) => {
        setMutedUntilDate(date);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        if (value === 'SelectedTank') {
            allTanks = false;
        } else {
            allTanks = true;
        }
        setAllTanks(allTanks);
    };

    return (
        <>
            {alertDetails &&
            <Wrapper>
                <AddCommentWrapper>
                    <Title>Exclude alert</Title>

                    {!alreadyMuted &&
                        <div className='active-voyages-selection'>
                            <ToggleInput id='muteCheckbox' label='Mute alert type' onChange={onChecked}
                                         isSelected={isMuted} name='isMuted'></ToggleInput>
                        </div>
                    }

                    {isMuted &&
                        <MuteWrapper>
                            <form method="post">
                                <div className="fields-wrp">
                                    <label className="fields-wrp-label">Mute To</label>
                                    <div className="fields-wrp-input">
                                        <DatePicker className="from-date-picker" dateFormat="dd.MM.yyyy" selected={mutedUntilDate} onChange={onDateChanged}/>
                                    </div>
                                </div>
                                {hasCargo &&
                                    <div className="fields-wrp">
                                        <label className="fields-wrp-label">Cargo Number</label>
                                        <label
                                            className="fields-wrp-input">{alertDetails.alertItem.alertContext.cargoNumber}</label>
                                    </div>
                                }
                                {hasTank &&
                                    <div className="fields-wrp">
                                        <label className="fields-wrp-label">Tank Name</label>
                                        <label className="fields-wrp-input">{alertDetails.alertItem.alertContext.tankName}</label>
                                    </div>
                                }
                                {hasTank &&
                                <div className="fields-wrp">
                                    <div className="radio">
                                        <div>
                                            <input type="radio" value="AllTanks" checked={allTanks} name="gender" onChange={handleChange} /> All Tanks
                                        </div>
                                        <div>
                                            <input type="radio" value="SelectedTank" checked={!allTanks} name="gender" onChange={handleChange} /> Selected Tank
                                        </div>
                                    </div>
                                </div>
                                }
                            </form>
                        </MuteWrapper>
                    }
                    <CommentTextWrapper>
                        <textarea
                            className="input"
                            ref={commentTextInput}
                            value={commentTxt}
                            placeholder={commentPlaceholder}
                            onChange={e => setCommentTxt(e.target.value)}
                        />
                    </CommentTextWrapper>
                    <CancelSaveRightContentWrapper>
                        <StyledButton className="cancel-btn" disabled={false} isSelected={true} onClick={cancelBtn()}>Cancel</StyledButton>
                        <StyledButton className="primary-btn" disabled={false} isSelected={true} onClick={saveBtn()}>Save</StyledButton>
                    </CancelSaveRightContentWrapper>
                </AddCommentWrapper>
            </Wrapper>
            }
        </>
    );
};

export default WorkflowExcludedComponent;
