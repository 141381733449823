import {FuelType} from "../../enums/FuelType";
import {AlertSavingsStatus} from "../../enums/AlertSavingsStatus";

export class AlertSavingsPerFuelType {
    constructor(
        alertSavingsPerFuelTypeId: number,
        fuelTypeId: FuelType,
        fuelTypeName: string,
        fuelPrice: number,
        expectedConsumption: number,
        actualConsumption: number,
        numberOfDays: number,
        savingsInUsd: number,
        savingsInMt: number
    ) {
        this.alertSavingsPerFuelTypeId = alertSavingsPerFuelTypeId;
        this.fuelTypeId = fuelTypeId;
        this.fuelTypeName = fuelTypeName;
        this.fuelPrice = fuelPrice;
        this.expectedConsumption = expectedConsumption;
        this.actualConsumption = actualConsumption;
        this.numberOfDays = numberOfDays;
        this.savingsInUsd = savingsInUsd;
        this.savingsInMt = savingsInMt;
    }
    alertSavingsPerFuelTypeId: number;
    fuelTypeId: FuelType;
    fuelTypeName: string;
    fuelPrice: number;
    expectedConsumption: number;
    actualConsumption: number;
    numberOfDays: number;
    savingsInUsd: number;
    savingsInMt: number;
}
