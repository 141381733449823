import {ListTable} from './AlertListSavings.styles';
import React, {useContext, useEffect, useState} from 'react'
import {AlertItem} from "../../../types/ViewModel/AlertItem";
import {PrettifyDate} from '../../../utils/dateUtils';
import useGetAlertDetails from "../../../api/useGetAlertDetails";
import {DetailComponent} from "../../../enums/DetailComponent";
import {GetAlertSavingsStatusName} from "../../../utils/workFlowUtils";
import {PaginatorComponent} from "../../paginator/Paginator";
import useAppAlertList from "../../../app/useAppAlertList";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";
import SortIconComponent from "../../sort-icon/SortIcon";
import moment from "moment";

type Props = {
}

const AlertListSavingsComponent: React.FC<Props> = () => {
    const [sortColumn, setSortColumn] = useState<SortColumn>('alertdate');
    const [isSortDesc, setIsSortDesc] = useState<boolean>(false);
    const [paginatedFilteredAlerts, setPaginatedFilteredAlerts] = useState<AlertItem[]>([]);
    const [sortedFilteredAlerts, setSortedFilteredAlerts] = useState<AlertItem[]>([]);
    const {
        setDetailComponentId,
        isAlertSavingsUpdated,
        filterSavingsData,
    } = useContext(AppStateContext)!;
    const {
        filterSavingsAlertList,
    } = useAppAlertList()!;
    const {
        alertSavingsListView,
        alertListView,
        filteredAlertSavingsListView,
        setSelectedAlertItem,
        selectedAlertItem,
    } = useContext(AppAlertListContext)!;
    const { getAlertDetails } = useGetAlertDetails();

    const showAlertDetails = (alertViewItem: AlertItem) => {
        setSelectedAlertItem(alertViewItem);
        getAlertDetails(alertViewItem.alertId);
        setDetailComponentId(DetailComponent.Tabs);
    };

    useEffect(() => {
        if (isAlertSavingsUpdated) {
            filterSavingsAlertList(alertSavingsListView, filterSavingsData);
        }
    }, [isAlertSavingsUpdated]);

    useEffect(() => {
        const alertList = getSortedAlertItems(sortColumn, isSortDesc, filteredAlertSavingsListView);
        setSortedFilteredAlerts(alertList);
    }, [filteredAlertSavingsListView]);

    type SortColumn =
        | 'alerttype'
        | 'vessel'
        | 'alertdate'
        | 'assignee'
        | 'savingsstatus';

    const getSortedAlertItems = (column: SortColumn, sortDesc: boolean, items: AlertItem[]): AlertItem[] => {
        let sortetAlertItems = [...items];

        if (column === 'alerttype') {
            sortetAlertItems = sortetAlertItems.sort((a, b) => sortDesc ?
                a.alertTypeDetails.alertTypeName.toLowerCase() > b.alertTypeDetails.alertTypeName.toLowerCase() ? 1 : -1
                : a.alertTypeDetails.alertTypeName.toLowerCase() < b.alertTypeDetails.alertTypeName.toLowerCase() ? 1 : -1);
        }
        if (column === 'vessel') {
            sortetAlertItems = sortetAlertItems.sort((a, b) => sortDesc ?
                a.alertContext.vesselName > b.alertContext.vesselName ? 1 : -1
                : a.alertContext.vesselName < b.alertContext.vesselName ? 1 : -1);
        }
        if (column === 'assignee') {
            sortetAlertItems = sortetAlertItems.sort((a, b) => sortDesc ?
                a.assignee.toLowerCase() > b.assignee.toLowerCase() ? 1 : -1
                : a.assignee.toLowerCase() < b.assignee.toLowerCase() ? 1 : -1);
        }
        if (column === 'savingsstatus') {
            sortetAlertItems = sortetAlertItems.sort((a, b) => sortDesc ?
                a.alertSavingsStatusName.toLowerCase() > b.alertSavingsStatusName.toLowerCase() ? 1 : -1
                : a.alertSavingsStatusName.toLowerCase() < b.alertSavingsStatusName.toLowerCase() ? 1 : -1);
        }
        if (column === 'alertdate') {
            sortetAlertItems = sortetAlertItems.sort((a, b) => {
                if (sortDesc) {
                    return moment(b.alertDate).isAfter(moment(a.alertDate)) ? 1 : -1;
                }
                return moment(a.alertDate).isAfter(moment(b.alertDate)) ? 1 : -1;
            });
        }

        return sortetAlertItems;
    }

    const sortByColumn = (column: SortColumn) => {
        const sortDesc = !isSortDesc;
        const sortedAlertItems = getSortedAlertItems(column, sortDesc, sortedFilteredAlerts);

        setSortColumn(column);
        setSortedFilteredAlerts(sortedAlertItems);
        setIsSortDesc(sortDesc);
    }

    return (
        <>
            <ListTable>
                <thead>
                <tr>
                    <th className="sortable-th alert-type-col" onClick={() => sortByColumn('alerttype')} title="Order by alert type">Alert type
                        {sortColumn === 'alerttype' && <SortIconComponent sortDesc={isSortDesc} />}
                    </th>
                    <th className="sortable-th vessel-name" onClick={() => sortByColumn('vessel')} title="Order by vessel name">Vessel
                        {sortColumn === 'vessel' && <SortIconComponent sortDesc={isSortDesc} />}
                    </th>
                    <th className="sortable-th" onClick={() => sortByColumn('alertdate')} title="Order by alert date">Alert date
                        {sortColumn === 'alertdate' && <SortIconComponent sortDesc={isSortDesc} />}
                    </th>
                    <th className="sortable-th" onClick={() => sortByColumn('assignee')} title="Order by assignee">Assignee
                        {sortColumn === 'assignee' && <SortIconComponent sortDesc={isSortDesc} />}
                    </th>
                    <th>Tot. savings ($)</th>
                    <th className="sortable-th status-col" onClick={() => sortByColumn('savingsstatus')} title="Order by savings status">Savings status
                        {sortColumn === 'savingsstatus' && <SortIconComponent sortDesc={isSortDesc} />}
                    </th>
                </tr>
                </thead>
                <tbody>
                {paginatedFilteredAlerts.map((alertItem, i) => {
                    return <tr key={i} onClick={() => showAlertDetails(alertItem)}  className={`${alertItem.alertId === selectedAlertItem?.alertId ? "selected" : ""}`}>
                        <td className="alert-type-col">{alertItem.alertTypeDetails.alertTypeName}</td>
                        <td className="vessel-name">{alertItem.alertContext.vesselName}</td>
                        <td>{PrettifyDate(alertItem.alertDate)}</td>
                        <td>{alertItem.assignee}</td>
                        <td>{alertItem.totalSavingsInUsd.toLocaleString('no-NO')}</td>
                        <td className="status-col">
                            {alertItem.alertSavingsStatusId === 2 &&
                                <>
                                <span className="status-dot-marker status-draft">
                                    </span> 
                                    {GetAlertSavingsStatusName(alertItem.alertSavingsStatusId)}
                                </>
                            }
                            {alertItem.alertSavingsStatusId === 3 &&
                                <>
                                <span className="status-dot-marker status-pending">
                                    </span> 
                                    {GetAlertSavingsStatusName(alertItem.alertSavingsStatusId)}
                                </>
                            }
                            {alertItem.alertSavingsStatusId === 4 &&
                                <>
                                <span className="status-dot-marker status-approved">
                                    </span> 
                                    {GetAlertSavingsStatusName(alertItem.alertSavingsStatusId)}
                                </>
                            }
                            {alertItem.alertSavingsStatusId === 5 &&
                            <>
                                <span className="status-dot-marker status-rejected">
                                    </span>
                                {GetAlertSavingsStatusName(alertItem.alertSavingsStatusId)}
                            </>
                            }
                            </td>
                    </tr>
                })}
                </tbody>
            </ListTable>
            <div className="paginator-wrapper">
                <PaginatorComponent list={sortedFilteredAlerts} setList={setPaginatedFilteredAlerts}/>
            </div>
        </>
    );
}
export default AlertListSavingsComponent;
