import React, {useContext} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {ContentWrapper, DateWrapper, TextWrapper, Title} from '../DateFilter.styles';
import {AlertListMode} from "../../../enums/AlertListMode";
import {PrettifyDate} from '../../../utils/dateUtils';
import {DateContext} from "../../../context/DateContext";
import {AppStateContext} from "../../../context/AppStateContext";
type Props = {}

const ToDateComponent: React.FC<Props> = () => {
    const { alertListMode
    } = useContext(AppStateContext)!;
    const {
        alertListToDate,
        alertSavingsListToDate
    } = useContext(DateContext);

    return (
        <ContentWrapper>
            <TextWrapper>
                <Title>To </Title>
            </TextWrapper>
            {alertListMode === AlertListMode.Default &&
                <DateWrapper className="input-style">
                        {PrettifyDate(alertListToDate)}
                </DateWrapper>
            }
            {alertListMode === AlertListMode.Savings &&
                <DateWrapper className="input-style">
                    {PrettifyDate(alertSavingsListToDate)}
                </DateWrapper>
            }
        </ContentWrapper>
    );
}

export default ToDateComponent;
