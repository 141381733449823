export class VesselOption {
    constructor(
        imoNumber: number,
        name: string,
        reportingGroup,
    ) {
        this.imoNumber = imoNumber;
        this.name = name;
        this.label = name;
        this.value = String(imoNumber);
        this.reportingGroup = reportingGroup;
    }
    imoNumber: number;
    name: string;
    label: string;
    value: string;
    reportingGroup: string;
}
