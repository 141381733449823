import * as Msal from "@azure/msal-browser";
import {LogLevel} from "@azure/msal-browser";

export const AuthConfig: Msal.Configuration = {
    auth: {
        clientId: '5cdecbf2-7994-4d7e-92e0-6d89c9716749',
        authority: "https://login.microsoftonline.com/4309616f-0474-48d6-8ecc-d2abec18a877",
        redirectUri: '/login',
        postLogoutRedirectUri: '/login',
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 10000,
        loadFrameTimeout: 60000,
        // asyncPopups: false
    }
};

export const LoginRequest = {
    scopes: ["openid", "profile", "User.Read", "Directory.Read.All"]
};

export const TokenRequest = {
    scopes: ["User.Read", "Mail.Read"]
};
