import {ContentWrapper, ListContentWrapper, ListWrapper} from './SavingsOverview.styles';
import React, {useContext, useEffect} from 'react';
import PageHeader from "../../components/page-header/PageHeader";
import AlertListDefault from '../../components/alert-list/alert-list-default/AlertListDefault';
import AlertListSavings from '../../components/alert-list/alert-list-savings/AlertListSavings';
import DetailsOverviewComponent from '../../components/alert-details/details-overview/DetailsOverview';
import AlertListFiltersComponent from "../../components/alert-list-filters/drop-down-filters/AlertListFilters";
import useAppState from "../../app/useAppState";
import SpinnerLoader from "../../components/loader/SpinnerLoader";
import useSetData from "../../app/useSetData";
import {AlertListMode} from "../../enums/AlertListMode";
import {AppStateContext} from "../../context/AppStateContext";
import {AppAlertListContext} from "../../context/AppAlertListContext";
import useGetAlertSavingsList from '../../api/useGetAlertSavingsList';
import { DateContext } from '../../context/DateContext';
import moment from 'moment';

type Props = {
    title: string
}

const SavingsOverviewComponent: React.FC<Props> = ({title}: Props) => {
    const {
        initializeAlertFilter,
        initializeSavingsAlertFilter,
    } = useAppState()!;
    const {
        filterAlertData,
        filterSavingsData,
        setAlertDetails,
        isLoadingVesselList,
        isLoadingAlertTypeList,
        isLoadingAdminList,
        isLoadingUserList,
        isLoadingRole,
        isLoadingSavingsList,
        alertListMode
    } = useContext(AppStateContext)!;
    const {
        alertListView,
        alertSavingsListView,
        setAlertSavingsListView,
        isLoadingAlertListView,
        isLoadingAlertSavingsListView
    } = useContext(AppAlertListContext)!;

    const { setAlertListMode
    } = useContext(AppStateContext)!;
    
    const { getAlertSavingsList } = useGetAlertSavingsList();
    
    const {
        alertSavingsListFromDate,
        alertSavingsListToDate,
        setAlertSavingsListFromDate,
        setAlertSavingsListToDate,
    } = useContext(DateContext);

    const initialAlertSavingsListFromdate = moment().add(-1, 'month').toDate();

    useEffect(() => {
        setAlertListMode(AlertListMode.Savings);

        if(!alertSavingsListFromDate){
            setAlertSavingsListFromDate(initialAlertSavingsListFromdate);
        }

        if(!alertSavingsListToDate){
            setAlertSavingsListToDate(new Date());
        }
    }, []);

    useEffect(() => {
        if(alertSavingsListFromDate && alertSavingsListToDate){
            getAlertSavingsList(alertSavingsListFromDate, alertSavingsListToDate);
        }
        
    }, [alertSavingsListFromDate, alertSavingsListToDate]);


    useEffect(() => {
        if (alertListView && alertListView.length > 0 && filterAlertData && filterSavingsData) {
           initializeSavingsAlertFilter(alertSavingsListView, filterSavingsData);
           setAlertDetails(null);
        }
    }, [alertListMode]);

    return (
        <>
        <PageHeader />
        <ContentWrapper>
            <ListContentWrapper>
                { (isLoadingAlertListView || isLoadingAlertSavingsListView || isLoadingVesselList || isLoadingAlertTypeList || isLoadingSavingsList
                  || isLoadingRole || isLoadingAdminList || isLoadingUserList)
                && <SpinnerLoader showOverlay={true} /> }
                <AlertListFiltersComponent/>
                {
                    <ListWrapper>
                        { alertListMode === AlertListMode.Default && <AlertListDefault/> }
                        { alertListMode === AlertListMode.Savings && <AlertListSavings/> }
                    </ListWrapper>
                }
            </ListContentWrapper>
            <DetailsOverviewComponent />
        </ContentWrapper>
        </>
    );
}
export default SavingsOverviewComponent;
