import styled from "styled-components";

export const TextWrapper = styled.div`
        display: flex;
        margin: 0 1rem;
        align-items: center;
        justify-content: space-around;
        border-radius: 4px;
        padding: 5px 5px;
        color: #12427d;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        cursor: default;
        height: 25px;
        &.confirm-bg{
            border: 1px solid green;
        }
        &.alert-bg{
            border: 1px solid red;
        }
    `;

export const Title = styled.div`
        font-weight: 500;
    `;
