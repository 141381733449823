import React, {useContext, useEffect} from 'react';
import useApi from './useApi';
import {AuthContext} from "../context/AuthContext";
import {Role} from "../types/ViewModel/Role";
import {RoleType} from "../enums/RoleType";
import {AppStateContext} from "../context/AppStateContext";

export default () => {
    const endpoint = 'GetUserRole';
    const { state, getApiData } = useApi();
    const { loggedInUser, setRolesAreUpdated } = React.useContext(AuthContext);
    const { setIsLoadingRole
    } = useContext(AppStateContext)!;

    const getUserRole = () => {
        if (!endpoint) return;
        getApiData(endpoint, '');
    };

    useEffect(() => {
        setIsLoadingRole(state.isLoading);
        if (state.status === 'success'){
            const userRole = state.result as string;
            if (loggedInUser && userRole) {
                switch (userRole) {
                    case 'admin':
                        console.log('Role: admin')
                        //loggedInUser.role = new Role(RoleType.Reader, "Reader");
                        loggedInUser.role = new Role(RoleType.Admin, "Admin");
                        //loggedInUser.role = new Role(RoleType.User, "User");
                        if (setRolesAreUpdated) {
                            setRolesAreUpdated(true);
                        }
                        break;
                    case 'user':
                        console.log('Role: user')
                        loggedInUser.role = new Role(RoleType.User, "User");
                        if (setRolesAreUpdated) {
                            setRolesAreUpdated(true);
                        }
                        break;
                    case 'reader':
                        console.log('Role: reader')
                        loggedInUser.role = new Role(RoleType.Reader, "Reader");
                        if (setRolesAreUpdated) {
                            setRolesAreUpdated(true);
                        }
                        break;
                }
            }
        }
    }, [state]);

    return { state, getUserRole };
}
