export class AlertSavingsStatusMap {
    constructor(
        sendToApprovalIsEnabled: boolean,
        approveIsEnabled: boolean,
        rejectIsEnabled: boolean
    ) {
        this.sendToApprovalIsEnabled = sendToApprovalIsEnabled;
        this.approveIsEnabled = approveIsEnabled;
        this.rejectIsEnabled = rejectIsEnabled;
    }
    sendToApprovalIsEnabled: boolean;
    approveIsEnabled: boolean;
    rejectIsEnabled: boolean;
}
