import moment from 'moment';
import React, {useState} from 'react'
import {ContextProps} from '../types/ContextProps';
import {AddDays} from "../utils/dateUtils";

type DateContextType = {
    alertListFromDate: Date,
    alertListToDate: Date,
    alertSavingsListFromDate: Date,
    alertSavingsListToDate: Date,

    setAlertListFromDate: (fromDate: Date) => void,
    setAlertListToDate: (toDate: Date) => void,
    setAlertSavingsListFromDate: (fromDate: Date) => void,
    setAlertSavingsListToDate: (toDate: Date) => void,
}

export const DateContext = React.createContext<DateContextType>(undefined!);

export const DateProvider = ({ children }: ContextProps) => {
    const [alertListFromDate, setAlertListFromDate] = useState<Date>(moment().add(-1, 'month').toDate());
    const [alertListToDate, setAlertListToDate] = useState<Date>(new Date());
    const [alertSavingsListFromDate, setAlertSavingsListFromDate] = useState<Date>(moment().add(-1, 'month').toDate());
    const [alertSavingsListToDate, setAlertSavingsListToDate] = useState<Date>(new Date());

    return <DateContext.Provider value={{
        alertListFromDate,
        setAlertListFromDate,
        alertListToDate,
        setAlertListToDate,
        alertSavingsListFromDate,
        setAlertSavingsListFromDate,
        alertSavingsListToDate,
        setAlertSavingsListToDate
    }}>
        {children}
    </DateContext.Provider >
};
