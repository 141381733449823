export class AdGroupMember {
    constructor(
        id: string,
        businessPhones: string[],
        displayName: string,
        givenName: string,
        jobTitle: string,
        mail: string,
        mobilePhone: string,
        officeLocation: string,
        preferredLanguage: string,
        surname: string,
        userPrincipalName: string
    ) {
        this.id = id;
        this.businessPhones = businessPhones;
        this.displayName = displayName;
        this.givenName = givenName;
        this.jobTitle = jobTitle;
        this.mail = mail;
        this.mobilePhone = mobilePhone;
        this.officeLocation = officeLocation;
        this.preferredLanguage= preferredLanguage;
        this.surname = surname;
        this.userPrincipalName = userPrincipalName;
    }
    id: string;
    businessPhones: string[];
    displayName: string;
    givenName: string;
    jobTitle: string;
    mail: string;
    mobilePhone: string;
    officeLocation: string;
    preferredLanguage: string;
    surname: string;
    userPrincipalName: string;
}
