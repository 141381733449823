import styled from "styled-components";

export const Wrapper = styled.div`
    
`;

export const Title = styled.div`
    font-weight: 500;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    line-height: 25px;
    margin: 0 0 0 0.5rem;
    border: 1px #ddd;
    border-left-style: solid;
    position: relative;

    .user-icon {
        border-radius: 50%;
        background: purple;
        height: 50px;
        width: 50px;
        cursor: pointer;
        h3 {
            margin: 0;
            text-align: center;
            padding: 13px 0;
            color: #fff;
        }
    }

    .user-info-panel {
        position: absolute;
        top: 60px;
        right: 5px;
        z-index: 1;
        width: 300px;
        height: 189px;
        border: 1px solid #ddd;
        background: #fff;
        padding: 15px;
        box-shadow: rgb(221, 221, 221) 0 4px 5px 0px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .top-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 0 0 50%;
        }
        .bottom-wrapper{
            flex: 0 0 50%;
            align-items: flex-end;
            display: flex;
            .logout{
                padding: 0.5rem 1rem;
                background: #12427d;
                border-radius: 4px;
                border: 0;
                color: #fff;
                font-size: 0.9rem;
                outline: none;
                cursor: pointer;
            }
        }
        .user-icon {
            height: 75px;
            width: 75px;
            margin-right: 10px;
            h3{
                padding: 1.5rem 0 0;
                font-size: 1.5rem;
            }
        }
        h3, h4{
            margin:0;
        }
    }
`;

