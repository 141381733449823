import {Role} from "./Role";
import {AdGroupMember} from "./AdGroupMember";

export class User {
    constructor(
        adUserId: string,
        base64User: string,
        userName: string | null,
        displayName: string | null,
        adGroupMember: AdGroupMember | null,
        role: Role
    ) {
        this.adUserId = adUserId;
        this.base64User = base64User;
        this.userName = userName;
        this.displayName = displayName;
        this.adGroupMember = adGroupMember;
        this.role = role;
    }
    adUserId: string;
    base64User: string;
    userName: string | null;
    displayName: string | null;
    adGroupMember: AdGroupMember | null;
    role: Role;
}
