import './AlertDetailsTabs.scss';
import React, {useContext, useEffect, useRef} from 'react'
import {Tab, TabList, TabPanel, Tabs} from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import DetailsComponent from "../details/Details";
import CommentsComponent from "../comments/Comments";
import HistoryComponent from "../history/History";
import SavingsComponent from "../savings/Savings";
import useGetHistoricalAlerts from "../../../api/useGetHistoricalAlerts";
import {AlertListMode} from "../../../enums/AlertListMode";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {

}

const AlertDetailsTabsComponent: React.FC<Props> = () => {
    const { alertDetails, previousDetailsTab, alertListMode
    } = useContext(AppStateContext)!;
    const {
        historicalAlerts,
    } = useContext(AppAlertListContext)!;
    const detailsTabRef = useRef(null);

    useEffect(() => {
    }, [alertDetails])

    const getDefaultTab = () => {
        if (previousDetailsTab !== '') {
            return previousDetailsTab;
        }
        return alertListMode === AlertListMode.Default ? 'detailsTab' : 'savingsTab';
    };
    const { getHistoricalAlerts } = useGetHistoricalAlerts();

    return (
        <div className="outer-tabs-wrapper">
            {alertDetails && 
                <Tabs className="more-info-tabs-wrapper"
                    defaultTab={getDefaultTab()}
                    ref={detailsTabRef}
                    onChange={(tabId) => {
                          console.log(tabId)
                        }
                      }
                  // Using key to force a refresh and reset of the default tab
                  // as we cannot set the tab programmatically with the current TabList component
                      key={alertDetails.alertItem.alertId}
                ><TabList className="more-info-tabs">
                        <Tab tabFor="detailsTab">Details</Tab>
                        <Tab tabFor="commentsTab">Comments</Tab>
                        <Tab tabFor="savingsTab">Savings</Tab>
                        <Tab tabFor="historyTab" onClick={() => getHistoricalAlerts(alertDetails?.alertItem?.alertId)}>Previous alerts</Tab>
                    </TabList>
                    <TabPanel tabId="detailsTab" className="tab-content">
                        <DetailsComponent/>
                    </TabPanel>
                    <TabPanel tabId="commentsTab" className="tab-content">
                        <CommentsComponent/>
                    </TabPanel>
                    <TabPanel tabId="historyTab" className="tab-content">
                        <HistoryComponent alertDetails={alertDetails ?? null} historicalAlerts={historicalAlerts ?? null}/>
                    </TabPanel>
                    <TabPanel tabId="savingsTab" className="tab-content">
                        <SavingsComponent/>
                    </TabPanel>
                </Tabs>
            }
        </div>
    );
};

export default AlertDetailsTabsComponent;
