import React, { useContext, useEffect, useState } from 'react'
import {
    FilterWrapperTop,
    LeftUpperContentWrapper,
    RightUpperContentWrapper,
    Title,
    Wrapper
} from './StatisticsFilters.styles';
import LastPeriodFilterComponent from './drop-down-filters/LastPeriodFilter';
import AlertTypeStatsFilterComponent from './drop-down-filters/AlertTypeFilter';
import { AppStateContext } from '../../context/AppStateContext';
import moment from 'moment';
import { SelectOption } from '../../types/ViewModel/SelectOption';
import VesselStatsFilterComponent from './drop-down-filters/VesselFilter';
import ReportingGroupStatsFilterComponent from './drop-down-filters/ReportingGroup';

type Props = {}

const StatisticsFiltersComponent: React.FC<Props> = () => {
    const {
        vesselList,
        statisticsAlertList,
        setFilteredStatisticsAlertList,
        periodFilteredStatisticsAlertList,
        setPeriodFilteredStatisticsAlertList,
        selectedStatisticsPeriod,
        selectedStatisticsAlertTypes,
        selectedStatisticsVessels,
        setSelectedStatisticsVessels,
        selectedStatisticsReportingGroups
    } = useContext(AppStateContext)!;

    // ============   USE EFFECTS   =============

    useEffect(() => {
        if(periodFilteredStatisticsAlertList && periodFilteredStatisticsAlertList.length > 0){
            filterStatsBySelectedFilters();
        }
    }, [periodFilteredStatisticsAlertList]);

    useEffect(() => {
        if(selectedStatisticsAlertTypes || selectedStatisticsVessels){
            filterStatsBySelectedFilters();
        }
    }, [selectedStatisticsAlertTypes, selectedStatisticsVessels]);

    useEffect(() => {
        if(selectedStatisticsReportingGroups){
            filterVesselsByReportingGroups();
        }
    }, [selectedStatisticsReportingGroups]);


    useEffect(() => {
        if(selectedStatisticsPeriod){
            filterAlertStatisticsList(selectedStatisticsPeriod);
        }
    }, [selectedStatisticsPeriod]);
    
    const filterAlertStatisticsList = (selectedItem: SelectOption) => {
        if(statisticsAlertList && statisticsAlertList.length > 0){
            let filterAfterDate: Date = new Date();
            if(selectedItem.value === 'LAST_MONTH'){
                filterAfterDate = moment().add(-31, 'days').toDate();
            } else if(selectedItem.value === 'LAST_QUARTER'){
                filterAfterDate = moment().add(-3, 'months').toDate();
            } else if(selectedItem.value === 'LAST_SIX_MONTH'){
                filterAfterDate = moment().add(-6, 'months').toDate();
            } else if(selectedItem.value === 'LAST_YEAR'){
                filterAfterDate = moment().add(-1, 'year').toDate();
            } else if(selectedItem.value === 'LAST_THREE_YEARS'){
                filterAfterDate = moment().add(-3, 'year').toDate();
            }

            const statsAlertList = statisticsAlertList.filter(alert => {
                if(moment(alert.alertDate).isAfter(filterAfterDate)){
                    return true;
                } else {
                    return false;
                }
            });

           setPeriodFilteredStatisticsAlertList(statsAlertList);
        }
    };
   
    const filterStatsBySelectedFilters = () => {
        const filteredList = periodFilteredStatisticsAlertList.filter(alert => {
            if((selectedStatisticsAlertTypes.length > 0 && 
               selectedStatisticsAlertTypes.some(item => item.value === alert.alertTypeId.toString())) && 
               (selectedStatisticsVessels.length > 0 && 
               selectedStatisticsVessels.some(item => item.imoNumber === alert.imoNumber))){
                return true;
            } else{
                return false;
            }
        });
        
        setFilteredStatisticsAlertList(filteredList);
    };

    const filterVesselsByReportingGroups = () => {
        const filteredVesselList = vesselList.filter(vessel => {
            if((vesselList.length > 0 && selectedStatisticsReportingGroups.some(item => item.value.toString() === vessel.reportingGroup))){
                return true;
            } else{
                return false;
            }
        });

        setSelectedStatisticsVessels(filteredVesselList);
    };

    return <>
        <Wrapper>
            <FilterWrapperTop>
                <LeftUpperContentWrapper>
                    <Title>Filters</Title>
                </LeftUpperContentWrapper>
                <RightUpperContentWrapper>
                    <ReportingGroupStatsFilterComponent />
                    <VesselStatsFilterComponent />
                    <AlertTypeStatsFilterComponent />
                    <LastPeriodFilterComponent />
                </RightUpperContentWrapper>
            </FilterWrapperTop>
        </Wrapper>
    </>;
}

export default StatisticsFiltersComponent;
