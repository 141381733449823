import {AlertTypeDetailsBM} from "../types/BusinessModel/AlertTypeDetailsBM";
import {AlertTypeDetails} from "../types/ViewModel/AlertTypeDetails";

export class AlertTypeDetailsFactory {
    public getAlertTypeDetails(item: AlertTypeDetailsBM): AlertTypeDetails {
        return new AlertTypeDetails(
            item.alertTypeId,
            item.alertTypeName,
            item.alertCategoryId,
            item.alertCategoryName,
            item.alertScopeId,
            item.alertScopeName);
    }

    public getAlertTypeDetailsFromCode(item: AlertTypeDetails): AlertTypeDetails {
        return new AlertTypeDetails(
            item.alertTypeId,
            item.alertTypeName,
            item.alertCategoryId,
            item.alertCategoryName,
            item.alertScopeId,
            item.alertScopeName);
    }

    public getAlertTypeDetailsBM(item: AlertTypeDetails)
    {
        return {
            alertTypeId: item.alertTypeId,
            alertTypeName: item.alertTypeName,
            alertCategoryId: item.alertCategoryId,
            alertCategoryName: item.alertCategoryName,
            alertScopeId: item.alertScopeId,
            alertScopeName: item.alertScopeName
        }
    }
}
