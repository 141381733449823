import React, {useContext, useEffect} from 'react'
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import Login from '../pages/login/login';
import { PrivateRoute } from './PrivateRoute';
import AlertOverview from '../pages/alert/AlertOverview';
import useMsal from "../auth/useMsal";
import styled from "styled-components";
import Statistics from '../pages/statistics/Statistics';
import SavingsOverview from '../pages/savings/SavingsOverview';
import useSetData from '../app/useSetData';

const ContentWrapper = styled.div`
    height: 100%;
    overflow: hidden;
`;

export default () => {
  const {} = useSetData();
  const { checkAuthStatus, initHandleRedirect } = useMsal();
  const { loggedInUser, isAuthenticating } = React.useContext(AuthContext);
  const location = useLocation();


  const LoginRoute = () => {
    if (loggedInUser) {
      console.log('RouteContent loggedInUser');
      return <Route path='/login'><Redirect to='/alertoverview'/></Route>
    }

    console.log('RouteContent !loggedInUser');
    return <Route path='/login' component={Login}/>
  }

  useEffect(() => {
    console.log('RouteContent checkAuthStatus');
    initHandleRedirect();
    checkAuthStatus();
  }, []);


  return <>
    {!isAuthenticating &&
      <ContentWrapper className="full-height">
        <Switch>
          {console.log('location.pathname = ' + location.pathname)}
          {LoginRoute()}
          <PrivateRoute exact path='/'><Redirect to='/login'/></PrivateRoute>
          <PrivateRoute path='/alertoverview' component={AlertOverview} ></PrivateRoute>
          <PrivateRoute path='/savingsoverview' component={SavingsOverview} ></PrivateRoute>
          <PrivateRoute path='/statistics' component={Statistics} ></PrivateRoute>
        </Switch>
      </ContentWrapper>
    }
    </>
}
