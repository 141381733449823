import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import SelectInput from '../select-input/SelectInput';
import { Wrapper } from './Paginator.style';
import './Paginator.scss';

type props = {
  list: any[];
  setList: (list: any[]) => void
}

export const PaginatorComponent: React.FC<props> = (props) => {
  const [itemsPerPage, setItemsPerPage] = useState('100');
  const [pageCount, setPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const numberPerPageOptions = ['1', '5', '10', '15', '25', '50', '100', '500'];

  useEffect(() => {
    if (!props.list) return;
    const numberOfPages = props.list.length / +itemsPerPage;
    setPageCount(Math.ceil(numberOfPages));
    paginateList(currentPage);

  }, [props.list, itemsPerPage]);

  const onItemsPerPageChange = (event: any) => {
    const value = event.target.value;
    console.log(value)
    setItemsPerPage(value);
  }

  const handlePageChange = (event: any, value: any) => {
    paginateList(value);
  };

  const paginateList = (page: number) => {
    const startIndex = (page - 1) * +itemsPerPage;
    const endIndex = startIndex + +itemsPerPage;
    const paginatedList = props.list.slice(startIndex, endIndex)
    setCurrentPage(page);
    props.setList(paginatedList);
  }

  return (
    <>
      <Wrapper>
        <span>Items per page</span>
        <SelectInput name='itemsPerPage' options={numberPerPageOptions}
                     onChange={onItemsPerPageChange} value={itemsPerPage}></SelectInput>
        <Pagination page={currentPage} count={pageCount} onChange={handlePageChange}   size="small" />
      </Wrapper>
    </>
  );
}
