import {AlertSavingsPerFuelType} from "./AlertSavingsPerFuelType";
import {AlertSavingsStatus} from "../../enums/AlertSavingsStatus";

export class AlertSavings {
    constructor(
        alertId: number,
        alertSavingsPerFuelTypeList: AlertSavingsPerFuelType[],
        alertSavingsDescription: string,
        alertSavingsStatusId: AlertSavingsStatus,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string,
    ) {
        this.alertId = alertId;
        this.alertSavingsPerFuelTypeList = alertSavingsPerFuelTypeList;
        this.alertSavingsDescription = alertSavingsDescription;
        this.alertSavingsStatusId = alertSavingsStatusId;
        this.lastUpdatedUtc = lastUpdatedUtc;
        this.lastUpdatedBy= lastUpdatedBy;
    }
    alertId: number;
    alertSavingsPerFuelTypeList: AlertSavingsPerFuelType[];
    alertSavingsDescription: string;
    alertSavingsStatusId: AlertSavingsStatus;
    lastUpdatedUtc: Date;
    lastUpdatedBy: string;
}
