import {User} from "./User";
import {AlertType} from "./AlertType";
import {VesselOption} from "./VesselOption";
import {AlertSavingsStatus} from "../../enums/AlertSavingsStatus";

export class FilterSavingsData {
    constructor(
        userList: User[],
        alertSavingsStatusList: AlertSavingsStatus[],
        alertTypeList: AlertType[],
        vesselList: VesselOption[],
        updated: boolean
    ) {
        this.userList = userList;
        this.alertSavingsStatusList = alertSavingsStatusList;
        this.alertTypeList = alertTypeList;
        this.vesselList = vesselList;
        this.updated = updated;
    }
    userList: User[];
    alertSavingsStatusList: AlertSavingsStatus[];
    alertTypeList: AlertType[];
    vesselList: VesselOption[];
    updated: boolean;
}
