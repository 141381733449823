import {useContext, useEffect} from 'react';
import useApi from './useApi';
import {SetVesselNameForAlertItem} from '../utils/vesselUtils';
import {AlertDetailsBM} from "../types/BusinessModel/AlertDetailsBM";
import {AlertDetailsFactory} from "../factory/AlertDetailsFactory";
import {AlertDetails} from "../types/ViewModel/AlertDetails";
import {AlertItem} from "../types/ViewModel/AlertItem";
import {AppStateContext} from "../context/AppStateContext";
import {AppAlertListContext} from "../context/AppAlertListContext";

export default () => {
    const endpoint = 'GetAlertDetails';
    const { state, getApiData } = useApi();
    const { vesselList, setAlertDetails, setIsLoadingAlertDetails } = useContext(AppStateContext)!;

    const createQueryParam = (alertId: number) => {
        return '?alertId=' + String(alertId);
    };
    const {
        alertListView,
        alertSavingsListView
    } = useContext(AppAlertListContext)!;

    const getAlertDetails = (alertId: number) => {
        if (!endpoint) return;
        const queryParam = createQueryParam(alertId);
        getApiData(endpoint, queryParam);
    };

    const alertDetailsFactory = new AlertDetailsFactory();

    function setAlertItemInList(alertList: AlertItem[], alertDetails: AlertDetailsBM, alertDetailsView: AlertDetails) {
        const alertItemInList = alertList.find(o => o.alertId === alertDetails.alertItem.alertId);
        if (alertItemInList !== undefined) {
            const index = alertList.indexOf(alertItemInList);
            if (index >= 0) {
                alertList[index] = alertDetailsView.alertItem;
            }
        }
    }

    useEffect(() => {
        setIsLoadingAlertDetails(state.isLoading);
        if (state.status === 'success'){
            const alertDetails = state.result as unknown as AlertDetailsBM;
            const alertDetailsView: AlertDetails = alertDetailsFactory.getAlertDetails(alertDetails);
            SetVesselNameForAlertItem(alertDetailsView.alertItem, vesselList);
            setAlertDetails(alertDetailsView);
            console.log('updating lists');
            setAlertItemInList(alertListView, alertDetails, alertDetailsView);
            setAlertItemInList(alertSavingsListView, alertDetails, alertDetailsView);
        }
    }, [state, alertListView, alertSavingsListView]);

    return { state, getAlertDetails };
}
