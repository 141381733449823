import React, {useContext, useEffect} from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import RouterContent from './RouterContent';
import {AppSettingsGetter} from "../context/AppSettingsContext";
import {AppSettingsStateContext} from "../context/AppSettingsStateContext";

export default () => {
  const { isGettingAppSettings, setGettingAppSettings } = useContext(AppSettingsStateContext)!;

  useEffect(() => {
    if (setGettingAppSettings) {
      setGettingAppSettings(true);
    }
    new AppSettingsGetter().getAppSettings().then(o => {
      if (setGettingAppSettings) {
        setGettingAppSettings(false);
      }
    });
  }, []);

  return (
    <Router>
       {console.log('isGettingAppSettings ' + isGettingAppSettings)}
       { !isGettingAppSettings && <RouterContent/>}
    </Router>
  );
}
