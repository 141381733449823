import './PriorityFilter.scss';
import React, {useContext, useState} from 'react'
import ThemeContext from '../../../context/ThemeContext';
import {IButtonProps, StyledButton, Wrapper} from './PriorityFilter.styles';
import {AlertPriority} from "../../../enums/AlertPriority";
import { FilterAlertList, AddFilterPriority, RemoveFilterPriority } from '../../../utils/alertFilterUtils';
import {FilterAlertData} from "../../../types/ViewModel/FilterAlertData";
import useAppAlertList from "../../../app/useAppAlertList";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {}

type FilterState = {
    vesselSearch: string
}

type FilterAction = {
    field: string,
    value: string
}

const initialFilterState = {
    vesselSearch: ''
}

const filterReducer = (state: FilterState, { field, value }: FilterAction) => {
    return { ...state, [field]: value }
};

const PriorityFilterComponent: React.FC<Props> = () => {
    const theme = React.useContext(ThemeContext);
    var [urgentPriority, setUrgentPriority] = useState<boolean>(true);
    var [highPriority, setHighPriority] = useState<boolean>(true);
    var [mediumPriority, setMediumPriority] = useState<boolean>(true);
    var [lowPriority, setLowPriority] = useState<boolean>(true);
    const { filterAlertData, setFilterAlertData
    } = useContext(AppStateContext)!;
    const {
        filterAlertList,
    } = useAppAlertList()!;
    const {
        alertListView,
    } = useContext(AppAlertListContext)!;

    const priorityBtn = (alertPriority: AlertPriority) => () => {
        var updatedFilterData: FilterAlertData = filterAlertData;
        switch (alertPriority) {
            case AlertPriority.Urgent:
                if (urgentPriority) {
                    updatedFilterData = RemoveFilterPriority(filterAlertData, alertPriority);
                } else {
                    updatedFilterData = AddFilterPriority(filterAlertData, alertPriority);
                }
                urgentPriority = !urgentPriority;
                setUrgentPriority(urgentPriority);
                break;
            case AlertPriority.High:
                if (highPriority) {
                    updatedFilterData = RemoveFilterPriority(filterAlertData, alertPriority);
                } else {
                    updatedFilterData = AddFilterPriority(filterAlertData, alertPriority);
                }
                highPriority = !highPriority;
                setHighPriority(highPriority);
                break;
            case AlertPriority.Medium:
                if (mediumPriority) {
                    updatedFilterData = RemoveFilterPriority(filterAlertData, alertPriority);
                } else {
                    updatedFilterData = AddFilterPriority(filterAlertData, alertPriority);
                }
                mediumPriority = !mediumPriority;
                setMediumPriority(mediumPriority);
                break;
            case AlertPriority.Low:
                if (lowPriority) {
                    updatedFilterData = RemoveFilterPriority(filterAlertData, alertPriority);
                } else {
                    updatedFilterData = AddFilterPriority(filterAlertData, alertPriority);
                }
                lowPriority = !lowPriority;
                setLowPriority(lowPriority);
                break;
        }
        setFilterAlertData(updatedFilterData);
        filterAlertList(alertListView, updatedFilterData);
    };

    return <>
        <Wrapper>
            <StyledButton isSelected={lowPriority} onClick={priorityBtn(AlertPriority.Low)}>Low</StyledButton>
            <StyledButton isSelected={mediumPriority} onClick={priorityBtn(AlertPriority.Medium)}>Medium</StyledButton>
            <StyledButton isSelected={highPriority} onClick={priorityBtn(AlertPriority.High)}>High</StyledButton>
            <StyledButton isSelected={urgentPriority} onClick={priorityBtn(AlertPriority.Urgent)}>Urgent</StyledButton>
        </Wrapper>
    </>;
};

export default PriorityFilterComponent;
