import {AlertItem} from "../types/ViewModel/AlertItem";
import {User} from "../types/ViewModel/User";
import {FilterAlertData} from "../types/ViewModel/FilterAlertData";
import {AlertPriority} from "../enums/AlertPriority";
import {AlertStatus} from "../enums/AlertStatus";
import {SelectedUser} from "../types/ViewModel/SelectedUser";
import {Role} from "../types/ViewModel/Role";
import {RoleType} from "../enums/RoleType";
import {AlertType} from "../types/ViewModel/AlertType";
import {AlertSeverity} from "../enums/AlertSeverity";
import {VesselOption} from "../types/ViewModel/VesselOption";
import {AlertSavingsStatus} from "../enums/AlertSavingsStatus";
import {FilterSavingsData} from "../types/ViewModel/FilterSavingsData";

export const GetNumberOfUnresolvedAlerts = (alertList: AlertItem[], userName: string | null) => {
    return alertList.filter(o =>
        o.assignee === userName &&
        (o.alertStatusId === AlertStatus.New || o.alertStatusId === AlertStatus.InProgress)).length;
};

export const GetTotalNumberOfAlerts = (alertList: AlertItem[], userName: string | null) => {
    return alertList.filter(o =>
        o.assignee === userName).length;
};

export const FilterPriority = (alertList: AlertItem[], filterPriorityList: AlertPriority[]) => {
    return alertList.filter(o => {
        if (filterPriorityList.find((f:AlertPriority) => f === o.priorityId)) {
            return true;
        }
        return false;
    });
};

export const FilterSeverity = (alertList: AlertItem[], filterSeverityList: AlertSeverity[]) => {
    return alertList.filter(o => {
        if (filterSeverityList.find((f:AlertSeverity) => f === o.severityId)) {
            return true;
        }
        return false;
    });
};

export const FilterUser = (alertList: AlertItem[], filterUserList: User[]) => {
    return alertList.filter(o => {
        if (filterUserList.find((f:User) => f.userName === o.assignee)) {
            return true;
        }
        return false;
    });
};

export const FilterStatus = (alertList: AlertItem[], filterStatusList: AlertStatus[]) => {
    return alertList.filter(o => {
        if (filterStatusList.find((f:AlertStatus) => f === o.alertStatusId)) {
            return true;
        }
        return false;
    });
};

export const FilterSavingsStatus = (alertList: AlertItem[], filterAlertSavingsStatusList: AlertSavingsStatus[]) => {
    return alertList.filter(o => {
        if (filterAlertSavingsStatusList.find((f:AlertSavingsStatus) => (f !== AlertSavingsStatus.NoSavings && f === o.alertSavingsStatusId))) {
            return true;
        }
        return false;
    });
};

export const FilterAlertType = (alertList: AlertItem[], filterAlertTypeList: AlertType[]) => {
    return alertList.filter(o => {
        if (filterAlertTypeList.find((f:AlertType) => +f.value === o.alertTypeDetails.alertTypeId)) {
            return true;
        }
        return false;
    });
};

export const FilterVessel = (alertList: AlertItem[], filterVesselList: VesselOption[]) => {
    return alertList.filter(o => {
        if (filterVesselList.find((f:VesselOption) => +f.value === o.alertContext.imoNumber)) {
            return true;
        }
        return false;
    });
};

export const FilterAlertList = (alertList: AlertItem[], filterData: FilterAlertData) => {
    return FilterVessel(
        FilterSeverity(
            FilterPriority(
                FilterUser(
                    FilterStatus(
                        FilterAlertType(alertList, filterData.alertTypeList), filterData.alertStatusList), filterData.userList), filterData.alertPriorityList), filterData.alertSeverityList), filterData.vesselList);
};

export const FilterAlertListSavings = (alertList: AlertItem[], filterData: FilterSavingsData) => {
    return FilterVessel(FilterUser(FilterSavingsStatus(FilterAlertType(alertList, filterData.alertTypeList), filterData.alertSavingsStatusList), filterData.userList), filterData.vesselList);
};

export const CreateFilterAlertData = (filterData: FilterAlertData | null): FilterAlertData => {
    if (!filterData) {
        filterData = new FilterAlertData([], [], [], [], [], [], false);
    }
    filterData.alertPriorityList = [];
    filterData.alertPriorityList.push(AlertPriority.Urgent);
    filterData.alertPriorityList.push(AlertPriority.High);
    filterData.alertPriorityList.push(AlertPriority.Medium);
    filterData.alertPriorityList.push(AlertPriority.Low);
    filterData.alertSeverityList = [];
    filterData.alertSeverityList.push(AlertSeverity.Critical);
    filterData.alertSeverityList.push(AlertSeverity.Major);
    filterData.alertSeverityList.push(AlertSeverity.Normal);
    filterData.alertSeverityList.push(AlertSeverity.Minor);
    filterData.alertStatusList = [];
    filterData.alertStatusList.push(AlertStatus.New);
    filterData.alertStatusList.push(AlertStatus.InProgress);
    return filterData;
};

export const CreateFilterSavingsData = (filterData: FilterSavingsData | null): FilterSavingsData => {
    if (!filterData) {
        filterData = new FilterSavingsData([], [], [], [], false);
    }
    filterData.alertSavingsStatusList = [];
    filterData.alertSavingsStatusList.push(AlertSavingsStatus.PendingApproval);
    return filterData;
};

export const RemoveFilterPriority = (filterData: FilterAlertData, filterPriority: AlertPriority) => {
    const ind =  filterData.alertPriorityList.findIndex((o: AlertPriority) => o === filterPriority);
    if (ind !== -1) {
        filterData.alertPriorityList.splice(ind, 1);
    }
    return filterData;
};

export const AddFilterPriority = (filterData: FilterAlertData, filterPriority: AlertPriority) => {
    const ind =  filterData.alertPriorityList.findIndex((o: AlertPriority) => o === filterPriority);
    if (ind === -1) {
        filterData.alertPriorityList.push(filterPriority);
    }
    return filterData;
};

export const RemoveFilterSeverity = (filterData: FilterAlertData, filterSeverity: AlertSeverity) => {
    const ind =  filterData.alertSeverityList.findIndex((o: AlertSeverity) => o === filterSeverity);
    if (ind !== -1) {
        filterData.alertSeverityList.splice(ind, 1);
    }
    return filterData;
};

export const AddFilterSeverity = (filterData: FilterAlertData, filterSeverity: AlertSeverity) => {
    const ind =  filterData.alertSeverityList.findIndex((o: AlertSeverity) => o === filterSeverity);
    if (ind === -1) {
        filterData.alertSeverityList.push(filterSeverity);
    }
    return filterData;
};

export const RemoveFilterStatus = (filterData: FilterAlertData, filterStatus: AlertStatus) => {
    const ind =  filterData.alertStatusList.findIndex((o: AlertStatus) => o === filterStatus);
    if (ind !== -1) {
        filterData.alertStatusList.splice(ind, 1);
    }
    return filterData;
};

export const AddFilterStatus = (filterData: FilterAlertData, filterStatus: AlertStatus) => {
    const ind =  filterData.alertStatusList.findIndex((o: AlertStatus) => o === filterStatus);
    if (ind === -1) {
        filterData.alertStatusList.push(filterStatus);
    }
    return filterData;
};

export const RemoveFilterSavingsStatus = (filterData: FilterSavingsData, filterStatus: AlertSavingsStatus) => {
    const ind =  filterData.alertSavingsStatusList.findIndex((o: AlertSavingsStatus) => o === filterStatus);
    if (ind !== -1) {
        filterData.alertSavingsStatusList.splice(ind, 1);
    }
    return filterData;
};

export const AddFilterSavingsStatus = (filterData: FilterSavingsData, filterStatus: AlertSavingsStatus) => {
    const ind =  filterData.alertSavingsStatusList.findIndex((o: AlertSavingsStatus) => o === filterStatus);
    if (ind === -1) {
        filterData.alertSavingsStatusList.push(filterStatus);
    }
    return filterData;
};

export const SetFilterUser = (selectedUserList: SelectedUser[]) => {
    var userList: User[] = [];
    selectedUserList.forEach(o => {
        userList.push(new User(o.value, o.label, o.label, o.label, null, new Role(RoleType.Admin, 'Admin')));
    });
    return userList;
};

export const SetFilterAlertType = (selectedAlertTypeList: AlertType[]) => {
    var alertTypeList: AlertType[] = [];
    selectedAlertTypeList.forEach(o => {
        alertTypeList.push(o);
    });
    return alertTypeList;
};

export const SetFilterVessel = (selectedVesselList: VesselOption[]) => {
    var vesselList: VesselOption[] = [];
    selectedVesselList.forEach(o => {
        vesselList.push(o);
    });
    return vesselList;
};
