import './Savings.scss';
import {
    ButtonLeftContentWrapper,
    ButtonRightContentWrapper,
    CommentTextWrapper,
    GridItemWrapper,
    StyledButton,
    ButtonWrapper,
    Wrapper
} from './Savings.styles';
import React, {useContext, useEffect, useRef, useState} from 'react'
import {GetAlertSavingsStatusMap, GetAlertSavingsStatusName} from '../../../utils/workFlowUtils';
import {FuelType} from "../../../enums/FuelType";
import {AlertSavingsPerFuelType} from "../../../types/ViewModel/AlertSavingsPerFuelType";
import {AlertSavingsPerFuelTypeFactory} from "../../../factory/AlertSavingsPerFuelTypeFactory";
import {AlertSavingsStatus} from "../../../enums/AlertSavingsStatus";
import {AlertSavingsFactory} from "../../../factory/AlertSavingsFactory";
import usePostAlertSavings from "../../../api/usePostAlertSavings";
import {AlertSavingsStatusMap} from "../../../types/ViewModel/AlertSavingsStatusMap";
import {AuthContext} from "../../../context/AuthContext";
import {AddCommentWrapper} from "../comments/Comments.styles";
import {IsAdmin, CanEdit} from "../../../utils/userUtils";
import {AppStateContext} from "../../../context/AppStateContext";

type Props = {
}

const DetailsComponent: React.FC<Props> = () => {
    const { updateSavingsStatus } = usePostAlertSavings();
    var [alertSavingsStatusMap, setAlertSavingsStatusMap] = useState<AlertSavingsStatusMap>(new AlertSavingsStatusMap(false, false, false));
    const [fuelPriceHfo, setFuelPriceHfo] = useState(0);
    const [fuelPriceMgo, setFuelPriceMgo] = useState(0);
    const [expectedMtHfo, setExpectedMtHfo] = useState('');
    const expectedMtHfoInputRef = useRef(null);
    const [expectedMtMgo, setExpectedMtMgo] = useState('');
    const expectedMtMgoInputRef = useRef(null);
    const [actualMtHfo, setActualMtHfo] = useState('');
    const actualMtHfoInputRef = useRef(null);
    const [actualMtMgo, setActualMtMgo] = useState('');
    const actualMtMgoInputRef = useRef(null);
    const [numberOfDaysHfo, setnumberOfDaysHfo] = useState('');
    const numberOfDaysHfoInputRef = useRef(null);
    const [numberOfDaysMgo, setnumberOfDaysMgo] = useState('');
    const numberOfDaysMgoInputRef = useRef(null);
    const [savingsMtHfo, setSavingsMtHfo] = useState('');
    const [savingsMtHfoValue, setSavingsMtHfoValue] = useState(0);
    const savingsMtHfoInputRef = useRef(null);
    const [savingsMtMgo, setSavingsMtMgo] = useState('');
    const [savingsMtMgoValue, setSavingsMtMgoValue] = useState(0);
    const savingsMtMgoInputRef = useRef(null);
    const [savingsUsdHfo, setSavingsUsdHfo] = useState('');
    const [savingsUsdHfoValue, setSavingsUsdHfoValue] = useState(0);
    const savingsUsdHfoInputRef = useRef(null);
    const [savingsUsdMgo, setSavingsUsdMgo] = useState('');
    const [savingsUsdMgoValue, setSavingsUsdMgoValue] = useState(0);
    const savingsUsdMgoInputRef = useRef(null);
    const [totalSavingsUsd, setTotalSavingsUsd] = useState('');
    const totalSavingsUsdInputRef = useRef(null);
    const { updateAlertSavings } = usePostAlertSavings();
    const {
        isAlertSavingsUpdated,
        setIsAlertSavingsUpdated,
        alertDetails,
        selectedUserList,
        setDetailComponentId,
        setPreviousDetailTab
    } = useContext(AppStateContext)!;
    const { loggedInUser,  } = React.useContext(AuthContext);
    const [alertSavingsDescriptionTxt, setAlertSavingsDescriptionTxt] = useState<string>('');
    var [formChanged, setFormChanged] = useState<boolean>(false);

    useEffect(() => {
        if (alertDetails && isAlertSavingsUpdated) {
            initSavings();
            setIsAlertSavingsUpdated(false);
        }
    }, [alertDetails, isAlertSavingsUpdated]);

    useEffect(() => {
        initSavings();
    }, [alertDetails]);

    const hasValue = (val: string): boolean => {
        if (val && val.length > 0 && +val > 0) {
            return true;
        }
        return false;
    };

    const getValue = (val: string): number => {
        val.replace(/\s/g, "");
        if (val && val.length > 0) {
            return +val;
        }
        return 0;
    };

    const setTotalSavings = (valHfo: number, valMgo: number) => {
        const total = valHfo + valMgo;
        if (total > 0) {
            setTotalSavingsUsd(String(total.toLocaleString('no-NO')));
        } else {
            setTotalSavingsUsd('0');
        }
    };

    const initFuelTypeInputs = (hfo: boolean, mgo: boolean) => {
        if (hfo) {
            setExpectedMtHfo('');
            setActualMtHfo('');
            setnumberOfDaysHfo('');
            setSavingsMtHfo('');
            setSavingsMtHfoValue(0);
            setSavingsUsdHfo('');
            setSavingsUsdHfoValue(0);
        }
        if (mgo) {
            setExpectedMtMgo('');
            setActualMtMgo('');
            setnumberOfDaysMgo('');
            setSavingsMtMgo('');
            setSavingsMtMgoValue(0);
            setSavingsUsdMgo('');
            setSavingsUsdMgoValue(0);
        }
    };

    const setHfoFuelTypeInputs = (hfoItem: AlertSavingsPerFuelType) => {
        setExpectedMtHfo(String(hfoItem.expectedConsumption));
        setActualMtHfo(String(hfoItem.actualConsumption));
        setnumberOfDaysHfo(String(hfoItem.numberOfDays));
        setSavingsMtHfo(String(hfoItem.savingsInMt.toLocaleString('no-NO')));
        setSavingsUsdHfo(String(hfoItem.savingsInUsd.toLocaleString('no-NO')));
    };

    const setMgoFuelTypeInputs = (mgoItem: AlertSavingsPerFuelType) => {
        setExpectedMtMgo(String(mgoItem.expectedConsumption));
        setActualMtMgo(String(mgoItem.actualConsumption));
        setnumberOfDaysMgo(String(mgoItem.numberOfDays));
        setSavingsMtMgo(String(mgoItem.savingsInMt.toLocaleString('no-NO')));
        setSavingsUsdMgo(String(mgoItem.savingsInUsd.toLocaleString('no-NO')));
    };

    const initSavings = () => {
        var savUsdHfo: number = 0;
        var savUsdMgo: number = 0;
        if (alertDetails) {
            if (alertDetails.alertItem) {
                setFuelPriceHfo(alertDetails.alertItem.hfoPrice);
                setFuelPriceMgo(alertDetails.alertItem.mgoPrice);
                setAlertSavingsStatusMap(GetAlertSavingsStatusMap(alertDetails.alertItem.alertSavingsStatusId));
                if (alertDetails.alertItem.alertSavingsDescription) {
                    setAlertSavingsDescriptionTxt(alertDetails.alertItem.alertSavingsDescription);
                } else {
                    setAlertSavingsDescriptionTxt('');
                }
            }

            if (alertDetails.alertSavingsPerFuelTypeList && alertDetails.alertSavingsPerFuelTypeList.length > 0) {
                var hfoItem = alertDetails.alertSavingsPerFuelTypeList.find(o => o.fuelTypeId === FuelType.HFO);
                if (hfoItem) {
                    setHfoFuelTypeInputs(hfoItem);
                    savUsdHfo = hfoItem.savingsInUsd;
                } else {
                    initFuelTypeInputs(true, false);
                }

                var mgoItem = alertDetails.alertSavingsPerFuelTypeList.find(o => o.fuelTypeId === FuelType.MGO);
                if (mgoItem) {
                    setMgoFuelTypeInputs(mgoItem);
                    savUsdMgo = mgoItem.savingsInUsd;
                } else {
                    initFuelTypeInputs(false, true);
                }
            } else {
                initFuelTypeInputs(true, true);
            }
        } else {
            initFuelTypeInputs(true, true);
        }
        setTotalSavings(savUsdHfo, savUsdMgo);
    };

    const saveBtn = () => () => {
        if (alertDetails) {
            var fuelTypeFactory = new AlertSavingsPerFuelTypeFactory();
            var savPerFuelTypeList: AlertSavingsPerFuelType[] = [];
            // Post update savings
            var hfoItem = fuelTypeFactory.createAlertSavingsPerFuelTypeFromCode(0, FuelType.HFO, '',
                +fuelPriceHfo, getValue(expectedMtHfo), getValue(actualMtHfo), getValue(numberOfDaysHfo), savingsUsdHfoValue, savingsMtHfoValue);
            savPerFuelTypeList.push(hfoItem);
            var mgoItem = fuelTypeFactory.createAlertSavingsPerFuelTypeFromCode(0, FuelType.MGO, '',
                +fuelPriceMgo, getValue(expectedMtMgo), getValue(actualMtMgo), getValue(numberOfDaysMgo), savingsUsdMgoValue, savingsMtMgoValue);
            savPerFuelTypeList.push(mgoItem);

            if (savPerFuelTypeList.length > 0) {
                var savingsFactory = new AlertSavingsFactory();
                var alertSavings = savingsFactory.createAlertSavingsFromCode(
                    alertDetails.alertItem.alertId,
                    savPerFuelTypeList,
                    alertSavingsDescriptionTxt,
                    alertDetails.alertItem.alertSavingsStatusId,
                    new Date(),
                    (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A'
                );
                updateAlertSavings(alertSavings);
                setFormChanged(false);
            }
        }
    };

    const cancelBtn = () => () => {
        initSavings();
    };

    const registerBtn = () => () => {
        if (alertDetails && alertDetails.alertItem) {
            updateSavingsStatus(AlertSavingsStatus.PendingApproval, alertSavingsDescriptionTxt, new Date(), (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A');
        }
    };

    const approveBtn = () => () => {
        if (alertDetails && alertDetails.alertItem) {
            updateSavingsStatus(AlertSavingsStatus.Approved, alertSavingsDescriptionTxt, new Date(), (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A');
        }
    };

    const rejectBtn = () => () => {
        if (alertDetails && alertDetails.alertItem) {
            updateSavingsStatus(AlertSavingsStatus.Rejected, alertSavingsDescriptionTxt, new Date(), (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A');
        }
    };

    const calculateAndSetSavings = (
        expMtHfo: string,
        actMtHfo: string,
        noOfDaysHfo: string,
        expMtMgo: string,
        actMtMgo: string,
        noOfDaysMgo: string,
    ) => {
        var savUsdHfo: number = 0;
        var savUsdMgo: number = 0;
        if (hasValue(expMtHfo) && hasValue(actMtHfo) && hasValue(noOfDaysHfo)) {
            const savedMt: number = (+actMtHfo - +expMtHfo) * +noOfDaysHfo;
            setSavingsMtHfoValue(savedMt);
            setSavingsMtHfo(String(savedMt.toLocaleString('no-NO')));
            if (savedMt > 0) {
                savUsdHfo = savedMt * +fuelPriceHfo;
                setSavingsUsdHfoValue(savUsdHfo);
                setSavingsUsdHfo(String(savUsdHfo.toLocaleString('no-NO')));
            } else {
                setSavingsUsdHfoValue(0);
                setSavingsUsdHfo('0');
            }
        }
        if (hasValue(expMtMgo) && hasValue(actMtMgo) && hasValue(noOfDaysMgo)) {
            const savedMt: number = (+actMtMgo - +expMtMgo) * +noOfDaysMgo;
            setSavingsMtMgoValue(savedMt);
            setSavingsMtMgo(String(savedMt.toLocaleString('no-NO')));
            if (savedMt > 0) {
                savUsdMgo = savedMt * +fuelPriceMgo;
                setSavingsUsdMgoValue(savUsdMgo);
                setSavingsUsdMgo(String(savUsdMgo.toLocaleString('no-NO')));
            } else {
                setSavingsUsdMgoValue(0);
                setSavingsUsdMgo('0');
            }
        }
        setTotalSavings(savUsdHfo, savUsdMgo);
    };

    const handleExpectedMtHfo = (selectedItem) => {
        setExpectedMtHfo(selectedItem);
        calculateAndSetSavings(selectedItem, actualMtHfo, numberOfDaysHfo, expectedMtMgo, actualMtMgo, numberOfDaysMgo);
        setFormChanged(true);
    };

    const handleExpectedMtMgo = (selectedItem) => {
        setExpectedMtMgo(selectedItem);
        calculateAndSetSavings(expectedMtHfo, actualMtHfo, numberOfDaysHfo, selectedItem, actualMtMgo, numberOfDaysMgo);
        setFormChanged(true);
    };

    const handleActualMtHfo = (selectedItem) => {
        setActualMtHfo(selectedItem);
        calculateAndSetSavings(expectedMtHfo, selectedItem, numberOfDaysHfo, expectedMtMgo, actualMtMgo, numberOfDaysMgo);
        setFormChanged(true);
    };

    const handleActualMtMgo = (selectedItem) => {
        setActualMtMgo(selectedItem);
        calculateAndSetSavings(expectedMtHfo, actualMtHfo, numberOfDaysHfo, expectedMtMgo, selectedItem, numberOfDaysMgo);
        setFormChanged(true);
    };

    const handleNumberOfDaysHfo = (selectedItem) => {
        setnumberOfDaysHfo(selectedItem);
        calculateAndSetSavings(expectedMtHfo, actualMtHfo, selectedItem, expectedMtMgo, actualMtMgo, numberOfDaysMgo);
        setFormChanged(true);
    };

    const handleNumberOfDaysMgo = (selectedItem) => {
        setnumberOfDaysMgo(selectedItem);
        calculateAndSetSavings(expectedMtHfo, actualMtHfo, numberOfDaysHfo, expectedMtMgo, actualMtMgo, selectedItem);
        setFormChanged(true);
    };

    const isInputEnabled = () => {
        if (alertDetails && alertDetails.alertItem && alertDetails.alertItem.alertSavingsStatusId !== AlertSavingsStatus.Approved) {
            return true;
        }
        if (IsAdmin(loggedInUser)) {
            return false;
        }
        if (!alertSavingsStatusMap.approveIsEnabled && !alertSavingsStatusMap.rejectIsEnabled) {
            return false;
        }
        return true;
    };

    const disabledRefSavingsStatus = () => {
        if (!CanEdit(loggedInUser)) {
            return true;
        }
        if (alertDetails &&
            alertDetails.alertItem &&
            alertDetails.alertItem.alertSavingsStatusId !== AlertSavingsStatus.Approved &&
            alertDetails.alertItem.alertSavingsStatusId !== AlertSavingsStatus.PendingApproval) {
            return false;
        }
        return true;
    };

    const onSaveDescriptionTxt = (desc) => {
        setAlertSavingsDescriptionTxt(desc);
    };

    return (
        <>
            { alertDetails && alertDetails.alertItem &&
            <Wrapper>
                <ButtonWrapper>
                    <ButtonLeftContentWrapper>
                    {(CanEdit(loggedInUser) && (alertSavingsStatusMap.sendToApprovalIsEnabled || alertSavingsStatusMap.approveIsEnabled || alertSavingsStatusMap.rejectIsEnabled)) &&
                        <>
                        {alertSavingsStatusMap.sendToApprovalIsEnabled &&
                        <StyledButton className="primary-btn" disabled={false} isSelected={true}
                                        onClick={registerBtn()}>Send to approval</StyledButton>
                        }
                        {alertSavingsStatusMap.approveIsEnabled && IsAdmin(loggedInUser) &&
                        <StyledButton className="primary-btn" disabled={false} isSelected={true}
                                        onClick={approveBtn()}>Approve</StyledButton>
                        }
                        {alertSavingsStatusMap.rejectIsEnabled && IsAdmin(loggedInUser) &&
                        <StyledButton className="secondary-btn" disabled={false} isSelected={true}
                                        onClick={rejectBtn()}>Reject</StyledButton>
                        }
                        </>
                    }
                    </ButtonLeftContentWrapper>
                    {!disabledRefSavingsStatus() &&
                        <ButtonRightContentWrapper>
                            <StyledButton className="cancel-btn" disabled={!CanEdit(loggedInUser)} isSelected={CanEdit(loggedInUser)} onClick={cancelBtn()}>Cancel</StyledButton>
                            <StyledButton className="primary-btn"
                                disabled={!formChanged && (alertSavingsStatusMap.sendToApprovalIsEnabled  || alertSavingsStatusMap.rejectIsEnabled)}
                                isSelected={true}
                                onClick={saveBtn()}>Save</StyledButton>
                        </ButtonRightContentWrapper>
                    }
                </ButtonWrapper>

                <div className="savings-status">
                    <span>Savings Status: </span>
                    <span>{GetAlertSavingsStatusName(alertDetails.alertItem.alertSavingsStatusId)}</span>
                </div>
                 <div className="savings-grid">
                    
                    <span>Fuel Type</span><span>HFO</span><span>MGO</span>

                    <span>Fuel Price (USD)</span><span>{fuelPriceHfo}</span><span>{fuelPriceMgo}</span>
                    
                    <span>Before (MT)</span>
                    <span>
                        <GridItemWrapper>
                            <input className="input" type="number" disabled={disabledRefSavingsStatus()} ref={actualMtHfoInputRef} value={actualMtHfo} onChange={e => handleActualMtHfo(e.target.value)} />
                        </GridItemWrapper>
                    </span>
                    <span>
                        <GridItemWrapper>
                            <input className="input" type="number" disabled={disabledRefSavingsStatus()} ref={actualMtMgoInputRef} value={actualMtMgo} onChange={e => handleActualMtMgo(e.target.value)} />
                        </GridItemWrapper>
                    </span>

                    <span>After (MT)</span>
                    <span>
                        <GridItemWrapper>
                            <input className="input" type="number" disabled={disabledRefSavingsStatus()} ref={expectedMtHfoInputRef} value={expectedMtHfo} onChange={e => handleExpectedMtHfo(e.target.value)} />
                        </GridItemWrapper>
                    </span>
                    <span>
                        <GridItemWrapper>
                            <input className="input" type="number" disabled={disabledRefSavingsStatus()} ref={expectedMtMgoInputRef} value={expectedMtMgo} onChange={e => handleExpectedMtMgo(e.target.value)} />
                        </GridItemWrapper>
                    </span>
                   
                    <span># Days</span>
                    <span>
                        <GridItemWrapper>
                            <input className="input" type="number" disabled={disabledRefSavingsStatus()} ref={numberOfDaysHfoInputRef} value={numberOfDaysHfo} onChange={e => handleNumberOfDaysHfo(e.target.value)} />
                        </GridItemWrapper>
                    </span>
                    <span>
                        <GridItemWrapper>
                            <input className="input" type="number" disabled={disabledRefSavingsStatus()} ref={numberOfDaysMgoInputRef} value={numberOfDaysMgo} onChange={e => handleNumberOfDaysMgo(e.target.value)} />
                        </GridItemWrapper>
                    </span>
                    <span>Savings (MT)</span>
                    <span>
                        <GridItemWrapper>
                            <span>{savingsMtHfo}</span>
                        </GridItemWrapper>
                    </span>
                    <span>
                        <GridItemWrapper>
                            <span>{savingsMtMgo}</span>
                        </GridItemWrapper>
                    </span>
                    <span>Savings (USD)</span>
                    <span>
                        <GridItemWrapper>
                            <span>{savingsUsdHfo}</span>
                        </GridItemWrapper>
                    </span>
                    <span>
                        <GridItemWrapper>
                            <span>{savingsUsdMgo}</span>
                        </GridItemWrapper>
                    </span>

                    <span>TOTAL SAVINGS (USD)</span>
                    <span>
                        <GridItemWrapper>
                            <span>{totalSavingsUsd}</span>
                        </GridItemWrapper>
                    </span>
                    <span>
                        <GridItemWrapper>
                            <span>{' '}</span>
                        </GridItemWrapper>
                    </span>
                </div>
                <AddCommentWrapper>
                    <CommentTextWrapper>
                            <textarea
                                className="input"
                                disabled={disabledRefSavingsStatus()}
                                //ref={savingsDescriptionInput}
                                value={alertSavingsDescriptionTxt}
                                placeholder="Savings description"
                                onChange={e => onSaveDescriptionTxt(e.target.value)}
                            />
                    </CommentTextWrapper>
                </AddCommentWrapper>
            </Wrapper>
            }
        </>
    );
};

export default DetailsComponent;
