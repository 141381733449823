import React, {useContext} from 'react'
import {AppStateContext} from '../context/AppStateContext';
import {AlertItem} from "../types/ViewModel/AlertItem";
import {
    FilterAlertList,
    FilterAlertListSavings
} from "../utils/alertFilterUtils";
import {FilterAlertData} from "../types/ViewModel/FilterAlertData";
import {FilterSavingsData} from "../types/ViewModel/FilterSavingsData";
import {AppAlertListContext} from "../context/AppAlertListContext";
import moment from "moment";

export default () => {
    const {
        setSelectedAlertItem,
        setFilteredAlertListView,
        setFilteredAlertSavingsListView,
        setNumberOfFilteredAlerts,
        setNumberOfFilteredSavingsAlerts
    } = useContext(AppAlertListContext)!;

    const {
        alertDetails,
        setAlertDetails,
    } = useContext(AppStateContext)!;

    const filterAlertList = (alertList: AlertItem[], filterData: FilterAlertData) => {
        const filteredAlertList = FilterAlertList(alertList, filterData)
          .sort((a, b) => (moment(a.alertDate).isAfter(moment(b.alertDate)) ? 1 : 0))
          .reverse();
        setFilteredAlertListView(filteredAlertList);
        setNumberOfFilteredAlerts(filteredAlertList.length);
        if (alertDetails && alertDetails.alertItem) {
            const alertItem = filteredAlertList.find(o => o.alertId === alertDetails.alertItem.alertId);
            if (alertItem) {
                setSelectedAlertItem(alertItem);
            } else {
                setSelectedAlertItem(null);
                setAlertDetails(null);
            }
        } else {
            setSelectedAlertItem(null);
            setAlertDetails(null);
        }
    };

    const filterSavingsAlertList = (alertList: AlertItem[], filterData: FilterSavingsData) => {
        const filteredAlertList = FilterAlertListSavings(alertList, filterData);
        setFilteredAlertSavingsListView(filteredAlertList);
        setSelectedAlertItem(null); // Why? Do we need this? If not, refactor with previous function.
        setNumberOfFilteredSavingsAlerts(filteredAlertList.length);
        if (alertDetails && alertDetails.alertItem) {
            const alertItem = filteredAlertList.find(o => o.alertId === alertDetails.alertItem.alertId);
            if (alertItem) {
                setSelectedAlertItem(alertItem);
            } else {
                setSelectedAlertItem(null);
                setAlertDetails(null);
            }
        } else {
            setSelectedAlertItem(null);
            setAlertDetails(null);
        }
    };

    return {
        filterAlertList,
        filterSavingsAlertList,
    }
}
