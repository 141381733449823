import './HeaderItem.scss';
import React, { useState } from 'react';
import {StyledLabel} from "./HeaderItem.styles";
import {StyledDiv} from "./HeaderItem.styles";
import {Icon} from "./HeaderItem.styles";
import ErrorIcon from '@material-ui/icons/ErrorOutlineSharp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import { Link, useLocation } from 'react-router-dom';

type Props = {
    path: string,
    title: string,
    disabled: boolean
}

const HeaderItem: React.FC<Props> = (props) => {
    const location = useLocation();
    const icon = () =>
      props.title === 'Alerts' ? <ErrorIcon/> :
      props.title === 'Savings' ? <AttachMoneyIcon/> :
      props.title === 'Statistics' ? <BarChartIcon/> :
      <span/>

    return (
        <StyledDiv  className={location.pathname === `/${props.path}` ? 'is-selected' : ''}>
            <Link  to={{
                pathname: `/${props.path}`
            }}>
            <Icon>
                {icon()}
            </Icon>
            <StyledLabel className="icon-label">{props.title}</StyledLabel>
            </Link>
        </StyledDiv>
    );
};

export default HeaderItem;