import {AlertStatus} from "../enums/AlertStatus";
import {AlertStatusUpdateBM} from "../types/BusinessModel/AlertStatusUpdateBM";
import {AlertComment} from "../types/ViewModel/AlertComment";
import {AlertCommentCategory} from "../enums/AlertCommentCategory";
import {AlertCommentFactory} from "./AlertCommentFactory";

export class AlertStatusUpdateFactory {
    constructor(
    ) {
    }

    public getAlertStatusUpdateBM(
        alertId: number,
        alertStatusId: AlertStatus,
        alertComment: AlertComment | null,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string): AlertStatusUpdateBM | null {
        const alertCommentFactory = new AlertCommentFactory();
        return {
            alertId: alertId,
            alertStatusId: alertStatusId,
            alertComment: alertCommentFactory.getAlertCommentBM(alertComment),
            lastUpdatedUtc: lastUpdatedUtc,
            lastUpdatedBy: lastUpdatedBy
        }
    }
}
