import {User} from "../types/ViewModel/User";
import {RoleType} from "../enums/RoleType";

export const IsUser = (user: User | null | undefined) => {
    if (user) {
        return user.role.roleType === RoleType.User;
    }
    return false;
};

export const IsAdmin = (user: User | null | undefined) => {
    if (user) {
        return user.role.roleType === RoleType.Admin;
    }
    return false;
};

export const CanEdit = (user: User | null | undefined) => {
    if (user) {
        return user.role.roleType !== RoleType.Reader;
    }
    return true;
};
