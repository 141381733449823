import {AlertCommentBM} from "../types/BusinessModel/AlertCommentBM";
import {AlertComment} from "../types/ViewModel/AlertComment";
import {AlertCommentCategory} from "../enums/AlertCommentCategory";

export class AlertCommentFactory {
    constructor(
    ) {
    }

    public getAlertComment(item: AlertCommentBM): AlertComment {
        return new AlertComment(
            item.alertCommentId,
            item.alertId,
            item.comment,
            item.alertCommentCategoryId,
            item.alertCommentCategoryName,
            item.createdUtc,
            item.createdBy);
    }

    public getAlertCommentList(alertCommentListBM: AlertCommentBM[]): AlertComment[] {
        return alertCommentListBM.map(o => this.getAlertComment(o));
    }

    public createAlertCommentFromCode(
        alertCommentId: number,
        alertId: number,
        comment: string,
        alertCommentCategoryId: AlertCommentCategory,
        alertCommentCategoryName: string,
        createdUtc: Date,
        createdBy: string
        ) {
        return new AlertComment(
            alertCommentId,
            alertId,
            comment,
            alertCommentCategoryId,
            alertCommentCategoryName,
            createdUtc,
            createdBy
        )
    }

    public getAlertCommentBM(alertComment: AlertComment | null): AlertCommentBM | null {
        if (alertComment) {
            return {
                alertCommentId: 0,
                alertId: alertComment.alertId,
                comment: alertComment.comment,
                alertCommentCategoryId: alertComment.alertCommentCategoryId,
                alertCommentCategoryName: alertComment.alertCommentCategoryName,
                createdUtc: alertComment.createdUtc,
                createdBy: alertComment.createdBy
            }
        }
        return null;
    }
}
