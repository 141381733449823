import styled, {css} from "styled-components";

export const Wrapper = styled.div`
    padding-left: 15px;
    height: 100%;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    padding-right: 8px;
    text-align: right;
`;

export const ContentWrapper = styled.div`
    flex: 0 30% 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    heigth: 100%;
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 175px;

    .dropdown-heading {
        height: 25px;
        .dropdown-heading-dropdown-arrow svg{
            height: 20px;
        }
    }

    .dropdown-content {
        label.select-item{
            margin: 0 2px 0 0;
            font-weight: 500;
            font-size: 0.8rem;
            font-weight: 400;
            border-bottom: 1px solid #ddd;
            background: #eee;
        }

        .item-renderer > span{
            display: inline;
            padding-left: 3px;
        }
    }
`;

export const Title = styled.div`
        font-weight: 500;
    `;
