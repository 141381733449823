import React, {useContext, useEffect, useState} from 'react';
import MultiSelect from "react-multi-select-component";
import { AppStateContext } from '../../../context/AppStateContext';
import { SelectOption } from '../../../types/ViewModel/SelectOption';
import {ContentWrapper, DropdownWrapper, TextWrapper, Title, Wrapper} from "./DropDownFilter.styles";

type Props = {}

const ReportingGroupStatsFilterComponent: React.FC<Props> = () => {
    const [reportingGroupList, setReportingGroupList] = useState<SelectOption[]>([]);
    const {
        vesselList,
        statisticsAlertList,
        selectedStatisticsReportingGroups,
        setSelectedStatisticsReportingGroups
    } = useContext(AppStateContext)!;
    
    useEffect(() => {
        if(statisticsAlertList && vesselList.length > 0){
            const _reportingGroupList = generateReportingGroupsList(vesselList);
            setReportingGroupList(_reportingGroupList);
            setSelectedStatisticsReportingGroups(_reportingGroupList);
        }
    }, [statisticsAlertList]);
    
    const generateReportingGroupsList = (vesselList) => {
        const reportingGroupOptionsList: SelectOption[] = [];
        const uniqueReportingGroupsList = vesselList.reduce((result, currentValue) => {
            const reportingGroup = currentValue.reportingGroup !== '' ? currentValue.reportingGroup : 'Unknown';;
            
            (result[reportingGroup] = result[reportingGroup] || []).push(
                currentValue
            );
            return result;
        }, {});

        Object.entries(uniqueReportingGroupsList).forEach(([key]) => {
            reportingGroupOptionsList.push(new SelectOption(key, key));
        });

        return reportingGroupOptionsList;
;
    };

    const handleChange = (selectedItems) => {
        setSelectedStatisticsReportingGroups(selectedItems);
    };

    let filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    return (
        <>
            <Wrapper>
                <ContentWrapper>
                    <TextWrapper>
                        <Title>Reporting group</Title>
                    </TextWrapper>
                    <DropdownWrapper>
                        <MultiSelect
                            options={reportingGroupList}
                            filterOptions = {filterOptions}
                            value={selectedStatisticsReportingGroups}
                            onChange={handleChange}
                            labelledBy={"Select"}
                            overrideStrings={{
                                selectSomeItems: "Select reporting groups",
                                allItemsAreSelected: "All",
                                selectAll: "Select All",
                                search: "Search"
                            }}
                        />
                    </DropdownWrapper>
                </ContentWrapper>
            </Wrapper>
        </>
    );
};

export default ReportingGroupStatsFilterComponent;