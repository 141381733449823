import React, {useContext, useEffect, useState} from 'react';
import MultiSelect from "react-multi-select-component";
import {ContentWrapper, DropdownWrapper, TextWrapper, Title, Wrapper} from "./DropDownFilter.styles";
import {SelectedUser} from "../../../types/ViewModel/SelectedUser";
import {SetFilterUser} from '../../../utils/alertFilterUtils';
import {RoleType} from "../../../enums/RoleType";
import {SelectedUserFactory} from "../../../factory/SelectedUserFactory";
import {AuthContext} from "../../../context/AuthContext";
import {GetSavingsLastMonthForSelectedUsers} from "../../../utils/savingsUtils";
import {AlertListMode} from "../../../enums/AlertListMode";
import useAppAlertList from "../../../app/useAppAlertList";
import {AppStateContext} from "../../../context/AppStateContext";
import {AppAlertListContext} from "../../../context/AppAlertListContext";

type Props = {}

const AssigneeFilterComponent: React.FC<Props> = () => {
    const {
        filterAlertData,
        filterSavingsData,
        adGroupUserList,
        alertSavingsPerUserList,
        setSavingsLastMonth,
        alertListMode,
        setFilterAlertData,
        setFilterSavingsData,
        setSelectedUserList
    } = useContext(AppStateContext)!;
    const {
        filterAlertList,
        filterSavingsAlertList,
    } = useAppAlertList()!;
    const {
        alertListView,
        alertSavingsListView
    } = useContext(AppAlertListContext)!;
    const { loggedInUser, userIsUpdated, rolesAreUpdated } = React.useContext(AuthContext);
    const [selected, setSelected] = useState<SelectedUser[]>([]);
    const [initialSelected, setInitialSelected] = useState<SelectedUser[]>([]);

    const selectedUserFactory = new SelectedUserFactory();

    const setFilter = (selectedItems) => {
        setSelected(selectedItems);
        setSelectedUserList(selectedItems);
        filterAlertData.userList = SetFilterUser(selectedItems);
        filterAlertData.updated = true;
        filterSavingsData.userList = SetFilterUser(selectedItems);
        filterSavingsData.updated = true;
        setFilterAlertData(filterAlertData);
        if (alertListMode === AlertListMode.Default) {
            filterAlertList(alertListView, filterAlertData);
        } else {
            filterSavingsAlertList(alertSavingsListView, filterSavingsData);
        }
    };

    useEffect(() => {
        if (adGroupUserList && adGroupUserList.length > 0 && loggedInUser && loggedInUser.role.roleType !== RoleType.NoRole && userIsUpdated && rolesAreUpdated) {
            var selectedOptions: SelectedUser[] = [];
            var options = selectedUserFactory.getSelectedUserList(adGroupUserList);
            if (loggedInUser && loggedInUser.role.roleType === RoleType.User) {
                selectedOptions = selectedUserFactory.getSelectedUser(adGroupUserList, loggedInUser.userName);
            } else {
                selectedOptions = selectedUserFactory.getSelectedUserList(adGroupUserList);
            }
            setSavingsLastMonth(GetSavingsLastMonthForSelectedUsers(selectedOptions, alertSavingsPerUserList));
            setSelected(selectedOptions);
            setSelectedUserList(selectedOptions);
            filterAlertData.userList = SetFilterUser(selectedOptions);
            filterAlertData.updated = true;
            filterSavingsData.userList = SetFilterUser(selectedOptions);
            filterSavingsData.updated = true;
            setFilterAlertData(filterAlertData);
//            setFilter(selectedOptions);
            setInitialSelected(options);
        }
    }, [loggedInUser, userIsUpdated, rolesAreUpdated, adGroupUserList]);

    const handleChange = (selectedItems) => {
        setSavingsLastMonth(GetSavingsLastMonthForSelectedUsers(selectedItems, alertSavingsPerUserList));
        setFilter(selectedItems);
    };

    let filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    return (
        <>
            {selected && initialSelected &&
            <Wrapper>
               <ContentWrapper>
                    <TextWrapper>
                        <Title>Assignee</Title>
                    </TextWrapper>
                    <DropdownWrapper>
                        <MultiSelect
                            options={initialSelected}
                            filterOptions = {filterOptions}
                            value={selected}
                            onChange={handleChange}
                            labelledBy={"Select"}
                            overrideStrings={{
                                selectSomeItems: "Select assignees",
                                allItemsAreSelected: "All",
                                selectAll: "Select All",
                                search: "Search",
                            }}
                        />
                    </DropdownWrapper>
                </ContentWrapper>
            </Wrapper>
            }
        </>
    );
};

export default AssigneeFilterComponent;
