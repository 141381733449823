import {RoleType} from "../../enums/RoleType";

export class Role {
    constructor(
        roleType: RoleType,
        roleName: string
    ) {
        this.roleType = roleType;
        this.roleName = roleName;
    }
    roleType: RoleType;
    roleName: string | null;
}
