import './WorkflowInprogress.scss';
import {
    Wrapper,
    Title,
    CommentHeaderWrapper,
    CommentTextWrapper,
    StyledButton,
    AddCommentWrapper,
    CancelSaveRightContentWrapper
} from './WorkflowInprogress.styles';
import React, {useContext, useEffect, useRef, useState} from 'react'
import {AppStateContext} from "../../../context/AppStateContext";
import {DetailComponent} from "../../../enums/DetailComponent";
import usePostAlertStatusUpdate from "../../../api/usePostAlertStatusUpdate";
import {AlertStatus} from "../../../enums/AlertStatus";
import {AuthContext} from "../../../context/AuthContext";

type Props = {
}

const WorkflowInprogressComponent: React.FC<Props> = () => {
    const { updateAlertStatus } = usePostAlertStatusUpdate();
    const { alertDetails, setDetailComponentId } = useContext(AppStateContext)!;
    const [commentTxt, setCommentTxt] = useState('');
    const commentTextInput = useRef(null);
    const { loggedInUser } = React.useContext(AuthContext);

    useEffect(() => {
        if (alertDetails && alertDetails.alertItem) {
            setCommentTxt('');
        }
    }, [alertDetails]);

    const saveBtn = () => () => {
        // Post update alert status
        if (alertDetails && alertDetails.alertItem) {
            updateAlertStatus(AlertStatus.InProgress, commentTxt, new Date(), (loggedInUser && loggedInUser.displayName) ? loggedInUser.displayName : 'N/A');
        }
        setCommentTxt('');
        // @ts-ignore
        commentTextInput.current.hidden = true;
        setDetailComponentId(DetailComponent.Tabs);
    };

    const cancelBtn = () => () => {
        setCommentTxt('');
        // @ts-ignore
        commentTextInput.current.hidden = true;
        setDetailComponentId(DetailComponent.Tabs);
    };

    return (
        <>
            {alertDetails &&
            <Wrapper>
                <AddCommentWrapper>
                    <Title>Process alert</Title>
                    <CommentTextWrapper>
                        <textarea
                            className="input"
                            ref={commentTextInput}
                            value={commentTxt}
                            placeholder="Add a comment to status changed"
                            onChange={e => setCommentTxt(e.target.value)}
                        />
                    </CommentTextWrapper>
                    <CancelSaveRightContentWrapper>
                        <StyledButton className="cancel-btn" disabled={false} isSelected={true} onClick={cancelBtn()}>Cancel</StyledButton>
                        <StyledButton className="primary-btn" disabled={false} isSelected={true} onClick={saveBtn()}>Save</StyledButton>
                    </CancelSaveRightContentWrapper>
                </AddCommentWrapper>
            </Wrapper>
            }
        </>
    );
};

export default WorkflowInprogressComponent;
