import React, {useContext} from 'react'
import {AlertItem} from "../types/ViewModel/AlertItem";
import {FilterAlertData} from "../types/ViewModel/FilterAlertData";
import {
    CreateFilterAlertData,
    CreateFilterSavingsData,
} from "../utils/alertFilterUtils";
import {FilterSavingsData} from "../types/ViewModel/FilterSavingsData";
import useAppAlertList from "./useAppAlertList";

export default () => {

    const { filterAlertList, filterSavingsAlertList
    } = useAppAlertList()!;

    const initializeAlertFilter = (alertList: AlertItem[], filterData: FilterAlertData) => {
        const initFilterData = CreateFilterAlertData(filterData);
        filterAlertList(alertList,initFilterData);
    };

    const initializeSavingsAlertFilter = (alertList: AlertItem[], filterData: FilterSavingsData) => {
        const initFilterData = CreateFilterSavingsData(filterData);
        filterSavingsAlertList(alertList,initFilterData);
    };

    return {
        initializeAlertFilter,
        initializeSavingsAlertFilter,
    }
}
