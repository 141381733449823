import styled from "styled-components";

export const ListTable = styled.table`
    height: calc(100% - 65px);
    overflow-y: auto;
    
    table-layout: fixed;
    width: 100%;
    margin-top: 1rem;
    border-collapse: collapse;

    thead {
        background: #efedeb;
        font-weight: 400;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        color: #190b05
    }

    th, td {
        padding: 0.35rem 0.5rem;
        font-size: 0.8rem;
        text-align: left;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
    }

    th {
        background: #00000024;
    }

    td {
        font-size: 0.8rem;
    }

    tbody {
        tr {
            cursor: pointer;
            
            &:hover {
                background: #ccc;
            }
        }
    }

    .active-item {
        background: #ccc;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    .limit-wrapper{
        font-weight: 500;
        margin: -15px 0 10px 0;
        display: flex;
    }
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    width: 175px;
`;

export const EditButtonWrapper = styled.div`
    display: flex;
    background: #fff;
    margin: 0;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    padding-right: 1rem;
    height: 50px;
`;

export const CancelSaveButtonWrapper = styled.div`
    display: flex;
    background: #fff;
    margin: 0;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    margin-right: 1rem;
    padding-right: 1rem;
    height: 50px;
`;

export const RightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
//    max-width: 65%;
`;
