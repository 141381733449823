import {ContentWrapper, ListContentWrapper, ListWrapper} from './AlertOverview.styles';
import React, {useContext, useEffect} from 'react';
import PageHeader from "../../components/page-header/PageHeader";
import AlertListDefault from '../../components/alert-list/alert-list-default/AlertListDefault';
import AlertListSavings from '../../components/alert-list/alert-list-savings/AlertListSavings';
import DetailsOverviewComponent from '../../components/alert-details/details-overview/DetailsOverview';
import AlertListFiltersComponent from "../../components/alert-list-filters/drop-down-filters/AlertListFilters";
import useAppState from "../../app/useAppState";
import SpinnerLoader from "../../components/loader/SpinnerLoader";
import {AlertListMode} from "../../enums/AlertListMode";
import {AppStateContext} from "../../context/AppStateContext";
import {AppAlertListContext} from "../../context/AppAlertListContext";
import moment from 'moment';
import { DateContext } from '../../context/DateContext';
import useGetAlertList from '../../api/useGetAlertList';

type Props = {
    title: string
}

const AlertOverviewComponent: React.FC<Props> = ({title}: Props) => {
     const {
        initializeAlertFilter,
        initializeSavingsAlertFilter,
    } = useAppState()!;
    const {
        filterAlertData,
        filterSavingsData,
        setAlertDetails,
        isLoadingVesselList,
        isLoadingAlertTypeList,
        isLoadingAdminList,
        isLoadingUserList,
        isLoadingRole,
        isLoadingSavingsList,
        alertListMode
    } = useContext(AppStateContext)!;

    const {
        alertListView,
        alertSavingsListView,
        isLoadingAlertListView,
        isLoadingAlertSavingsListView
    } = useContext(AppAlertListContext)!;
    
    const { getAlertList } = useGetAlertList();
    const { setAlertListMode
    } = useContext(AppStateContext)!;

    const {
        alertListFromDate,
        alertListToDate,
        setAlertListFromDate,
        setAlertListToDate,
    } = useContext(DateContext);

    const initialAlertListFromdate = moment().add(-1, 'month').toDate();

    useEffect(() => {
        setAlertListMode(AlertListMode.Default);

        if(!alertListFromDate){
            setAlertListFromDate(initialAlertListFromdate);
        }

        if(!alertListToDate){
            setAlertListToDate(new Date());
        }
    }, []);

    useEffect(() => {
        if(alertListFromDate && alertListToDate){
            getAlertList(alertListFromDate, alertListToDate);
        }
        
    }, [alertListFromDate, alertListToDate]);

    useEffect(() => {
        if (alertListView && alertListView.length > 0 && filterAlertData && filterSavingsData) {
            initializeAlertFilter(alertListView, filterAlertData);
            setAlertDetails(null);
        }
    }, [alertListMode]);

    return (
        <>
        <PageHeader />
        <ContentWrapper>
            <ListContentWrapper>
                { (isLoadingAlertListView || isLoadingAlertSavingsListView || isLoadingVesselList || isLoadingAlertTypeList || isLoadingSavingsList
                  || isLoadingRole || isLoadingAdminList || isLoadingUserList)
                && <SpinnerLoader showOverlay={true} /> }
                <AlertListFiltersComponent/>
                {
                    <ListWrapper>
                        { alertListMode === AlertListMode.Default && <AlertListDefault/> }
                        { alertListMode === AlertListMode.Savings && <AlertListSavings/> }
                    </ListWrapper>
                }
            </ListContentWrapper>
            <DetailsOverviewComponent />
        </ContentWrapper>
        </>
    );
}
export default AlertOverviewComponent;
