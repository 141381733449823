import './App.scss';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import ThemeContext from './context/ThemeContext';
import { AppSettingsStateProvider } from './context/AppSettingsStateContext';
import { AuthContextProvider } from './context/AuthContext';
import { AppStateProvider } from './context/AppStateContext';
import AppRouter from './route/AppRouter';
import SnackbarProvider from 'react-simple-snackbar'
import {AppAlertListProvider} from "./context/AppAlertListContext";
import {DateProvider} from "./context/DateContext";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faSearch, faTimesCircle, faShip, faSort, faSortUp, faSortDown,
    faList, faPlus, faMinus, faSignInAlt, faSignOutAlt, faUserCog,
    faUser
} from '@fortawesome/free-solid-svg-icons';

const AppContainer = styled.div`
  color: ${props => props.theme.primaryTextColor};
  height: 100%;
`;

library.add(faSearch, faTimesCircle, faShip, faList, faPlus, faMinus, faSignInAlt, faSignOutAlt, faSort, faSortUp, faSortDown, faUserCog,
    faUser);

export default () => {
  const theme = React.useContext(ThemeContext);

  return (
      <AppContainer theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <AppSettingsStateProvider>
              <AuthContextProvider>
                <AppStateProvider>
                    <AppAlertListProvider>
                        <DateProvider>
                            <AppRouter />
                        </DateProvider>
                    </AppAlertListProvider>
                </AppStateProvider>
              </AuthContextProvider>
            </AppSettingsStateProvider>
          </SnackbarProvider>
      </AppContainer>
  );
};
