import {AlertItem} from "../types/ViewModel/AlertItem";
import {HighChartModel} from "../types/ViewModel/HighChartModel";
import {AlertPriority} from "../enums/AlertPriority";

const NumberOfAlertsForPriority = (alertList: AlertItem[], priority: AlertPriority) => {
    return alertList.filter(o => o.priorityId === priority).length;
};

export const GetPriorityMap = (alertList: AlertItem[]) => {
    var highChartActivitiesArray: Array<HighChartModel> = [];
    highChartActivitiesArray.push({name: 'Urgent', fullname: 'Urgent', y: NumberOfAlertsForPriority(alertList, AlertPriority.Urgent), color: '#ff6666'})
    highChartActivitiesArray.push({name: 'High', fullname: 'High', y: NumberOfAlertsForPriority(alertList, AlertPriority.High), color: '#ffb266'})
    highChartActivitiesArray.push({name: 'Medium', fullname: 'Medium', y: NumberOfAlertsForPriority(alertList, AlertPriority.Medium), color: '#99ccff'})
    highChartActivitiesArray.push({name: 'Low', fullname: 'Low', y: NumberOfAlertsForPriority(alertList, AlertPriority.Low), color: '##efa500'})
    return highChartActivitiesArray;
};
