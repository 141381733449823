import { useContext, useReducer } from 'react';
import axios from 'axios';
import ApiReducer from './ApiReducer';
import getContext from "../context/AppSettingsContext";
import {useSnackbar} from 'react-simple-snackbar'
import {snackbarOptionsError} from "../utils/snackbarUtils";

export default () => {
    const appSettings = useContext(getContext());
    const [state, dispatch] = useReducer(ApiReducer, { status: 'empty', isLoading: false, error: null });
    const [openSnackbarError] = useSnackbar(snackbarOptionsError);

    const getApiData = async (endpoint: string, queryParam: string) => {
        try {
            dispatch({ type: 'request_started' });

            if (!endpoint) {
                throw new Error('Missing url parameter')
            }
            let url = appSettings.api + endpoint + (queryParam && queryParam.length > 0 ? queryParam : '');
          
            console.log('Calling API: ' + url);
            const response = await axios.get(url);

            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            dispatch({ type: 'request_successful', result: response.data });

        } catch (error) {
            openSnackbarError('Api-call ' + endpoint + ' failed');
            dispatch({ type: 'request_failed', error: error });
        }
    };
    return { state, getApiData };
}
