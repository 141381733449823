import React from 'react';
import { Select } from './SelectInput.style';

type Props = {
  name: string,
  value: string,
  placeholder?: string,
  options: string[],
  onChange: (e: any) => void
};

const SelectInput: React.FC<Props> = (props) => {
  return (
    <>
      <Select onChange={props.onChange} value={props.value} name={props.name}>
        {
          props.placeholder && <option value={0}>{props.placeholder}</option>
        }
        {props.options.map((selectOption, index) =>
          <option key={index} value={selectOption}>
            {selectOption}
          </option>
        )}
      </Select>
    </>
  );
}

export default SelectInput;
