import {AlertStatus} from "../../../enums/AlertStatus";
import styled, {css} from "styled-components";

export interface IButtonProps {
    isSelected?: boolean;
    onClick?: (alertStatus: AlertStatus) => void;
}

export const Wrapper = styled.div`
  
`;

export const StyledButton = styled.button<IButtonProps>`
  height: 24px;
  padding: 2px 8px;
  font-size: 12px;
  margin: 1px;
  outline: 0;
  cursor: pointer;
  border-radius: 3px;

  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;
