import React, {useContext} from 'react';
import axios from 'axios';
import getContext from '../context/AppSettingsContext';
import {AlertSavings} from "../types/ViewModel/AlertSavings";
import {AlertSavingsBM} from "../types/BusinessModel/AlertSavingsBM";
import {AlertSavingsFactory} from "../factory/AlertSavingsFactory";
import {AlertSavingsStatus} from "../enums/AlertSavingsStatus";
import {AlertSavingsStatusUpdateFactory} from "../factory/AlertSavingsStatusUpdateFactory";
import {AlertSavingsStatusUpdateBM} from "../types/BusinessModel/AlertSavingsStatusUpdateBM";
import {GetAlertSavingsStatusName} from "../utils/workFlowUtils";
import {FuelType} from "../enums/FuelType";
import {AlertItem} from "../types/ViewModel/AlertItem";
import {
    AddAlertSavingsPerUser,
    GetSavingsLastMonthForSelectedUsers,
} from "../utils/savingsUtils";
import {useSnackbar} from 'react-simple-snackbar'
import {snackbarOptionsError, snackbarOptionsOk} from "../utils/snackbarUtils";
import {AlertSavingsPerFuelTypeFactory} from "../factory/AlertSavingsPerFuelTypeFactory";
import {AppStateContext} from "../context/AppStateContext";

export default () => {
    const appSettings = useContext(getContext());
    const {
        setIsAlertSavingsUpdated,
        alertDetails,
        setAlertDetails,
        setSavingsLastMonth,
        selectedUserList,
        alertSavingsPerUserList,
        setAlertSavingsPerUserList
    } = useContext(AppStateContext)!;
    const [openSnackbarOk] = useSnackbar(snackbarOptionsOk);
    const [openSnackbarError] = useSnackbar(snackbarOptionsError);

    const updateAlertItem = (alertItem: AlertItem, alertSavingsStatusId: AlertSavingsStatus, lastUpdUtc: Date, lastUpdBy: string, alertSavingsDescription: string) => {
        if (alertItem.alertSavingsStatusId === AlertSavingsStatus.NoSavings) {
            alertItem.alertSavingsStatusId = AlertSavingsStatus.Draft;
        } else {
            alertItem.alertSavingsStatusId = alertSavingsStatusId;
        }
        alertItem.alertSavingsStatusName = GetAlertSavingsStatusName(alertSavingsStatusId);
        alertItem.alertSavingsDescription = alertSavingsDescription;
        alertItem.lastUpdatedUtc = lastUpdUtc;
        alertItem.lastUpdatedBy = lastUpdBy;
    };

    const updateAlertSavings = async (alertSavings: AlertSavings) => {
        const endpoint = 'UpdateAlertSavings';
        var fuelTypeFactory = new AlertSavingsPerFuelTypeFactory();
        if (alertDetails && alertDetails.alertSavingsPerFuelTypeList) {
            openSnackbarOk('Updating alert savings');

            const alertSavingsFactory = new AlertSavingsFactory();
            const url = appSettings.api + endpoint;
            const alertSavingsBM = alertSavingsFactory.getAlertSavingsBM(alertSavings);

            const response = await axios
                .post<AlertSavingsBM>(url, alertSavingsBM, {})
                .then(postResponse => {
                    openSnackbarOk('Alert savings updated');
                    return postResponse;
                })
                .catch(error => {
                    return error.response;
                });

            if (!response) {
                openSnackbarError('Failed: no connection to server');
            } else if (response.status !== 200) {
                if (response.data) {
                    openSnackbarError('Failed: ' + String(response.data.statusCode) + ' (' + response.data.errorMessage + ')');
                } else {
                    openSnackbarError('Updating savings failed');
                }
            } else {
                var totalSavings: number = 0;
                var hfoNewItem = alertSavings.alertSavingsPerFuelTypeList.find(o => o.fuelTypeId === FuelType.HFO);
                if (hfoNewItem) {
                    var hfoOldItem = alertDetails.alertSavingsPerFuelTypeList.find(o => o.fuelTypeId === FuelType.HFO);
                    if (hfoOldItem) {
                        hfoOldItem.fuelPrice = hfoNewItem.fuelPrice;
                        hfoOldItem.expectedConsumption = hfoNewItem.expectedConsumption;
                        hfoOldItem.actualConsumption = hfoNewItem.actualConsumption;
                        hfoOldItem.numberOfDays = hfoNewItem.numberOfDays;
                        hfoOldItem.savingsInMt = hfoNewItem.savingsInMt;
                        hfoOldItem.savingsInUsd = hfoNewItem.savingsInUsd;
                    } else {
                        var hfoItem = fuelTypeFactory.createAlertSavingsPerFuelTypeFromCode(
                            hfoNewItem.alertSavingsPerFuelTypeId,
                            hfoNewItem.fuelTypeId,
                            hfoNewItem.fuelTypeName,
                            hfoNewItem.fuelPrice,
                            hfoNewItem.expectedConsumption,
                            hfoNewItem.actualConsumption,
                            hfoNewItem.numberOfDays,
                            hfoNewItem.savingsInUsd,
                            hfoNewItem.savingsInMt);
                        alertDetails.alertSavingsPerFuelTypeList.push(hfoItem);
                    }
                    totalSavings += hfoNewItem.savingsInUsd;
                }
                var mgoNewItem = alertSavings.alertSavingsPerFuelTypeList.find(o => o.fuelTypeId === FuelType.MGO);
                if (mgoNewItem) {
                    var mgoOldItem = alertDetails.alertSavingsPerFuelTypeList.find(o => o.fuelTypeId === FuelType.MGO);
                    if (mgoOldItem) {
                        mgoOldItem.fuelPrice = mgoNewItem.fuelPrice;
                        mgoOldItem.expectedConsumption = mgoNewItem.expectedConsumption;
                        mgoOldItem.actualConsumption = mgoNewItem.actualConsumption;
                        mgoOldItem.numberOfDays = mgoNewItem.numberOfDays;
                        mgoOldItem.savingsInMt = mgoNewItem.savingsInMt;
                        mgoOldItem.savingsInUsd = mgoNewItem.savingsInUsd;
                        totalSavings += mgoOldItem.savingsInUsd;
                    } else {
                        var mgoItem = fuelTypeFactory.createAlertSavingsPerFuelTypeFromCode(
                            mgoNewItem.alertSavingsPerFuelTypeId,
                            mgoNewItem.fuelTypeId,
                            mgoNewItem.fuelTypeName,
                            mgoNewItem.fuelPrice,
                            mgoNewItem.expectedConsumption,
                            mgoNewItem.actualConsumption,
                            mgoNewItem.numberOfDays,
                            mgoNewItem.savingsInUsd,
                            mgoNewItem.savingsInMt);
                        alertDetails.alertSavingsPerFuelTypeList.push(mgoItem);
                    }
                    alertDetails.alertItem.totalSavingsInUsd = totalSavings;
                    updateAlertItem(alertDetails.alertItem, alertDetails.alertItem.alertSavingsStatusId, alertSavings.lastUpdatedUtc, alertSavings.lastUpdatedBy, alertSavings.alertSavingsDescription);
                    setSavingsLastMonth(GetSavingsLastMonthForSelectedUsers(selectedUserList, alertSavingsPerUserList));
                    setAlertDetails(alertDetails);
                }
            }
            setIsAlertSavingsUpdated(true);
        }
    };

    const updateSavingsStatus = async (
        alertSavingsStatusId: AlertSavingsStatus,
        alertSavingsDescription: string,
        lastUpdatedUtc: Date,
        lastUpdatedBy: string) => {
        if (alertDetails && alertDetails.alertItem) {
            openSnackbarOk('Updating alert savings status');
            const endpoint = 'UpdateAlertSavingsStatus';
            const savingsStatusUpdateFactory = new AlertSavingsStatusUpdateFactory();
            const url = appSettings.api + endpoint;

            const savingsStatusUpdateBM = savingsStatusUpdateFactory.getAlertStatusUpdateBM(alertDetails.alertItem.alertId, alertSavingsStatusId, alertSavingsDescription, lastUpdatedUtc, lastUpdatedBy);

            const response = await axios
                .post<AlertSavingsStatusUpdateBM>(url, savingsStatusUpdateBM, {})
                .then(postResponse => {
                    openSnackbarOk('Alert savings status updated');
                    return postResponse;
                })
                .catch(error => {
                    return error.response;
                });


            if (!response) {
                openSnackbarError('Failed: no connection to server');
            } else if (response.status !== 200) {
                if (response.data) {
                    openSnackbarError('Updating savings status failed: ' + String(response.data.statusCode) + ' (' + response.data.errorMessage + ')');
                } else {
                    openSnackbarError('Updating savings status failed');
                }
            } else {
                if (alertSavingsStatusId === AlertSavingsStatus.Approved) {
                    const alertSavings = AddAlertSavingsPerUser(alertSavingsPerUserList, alertDetails.alertItem.assignee, alertDetails.alertItem.totalSavingsInUsd);
                    setAlertSavingsPerUserList(alertSavings);
                    setSavingsLastMonth(GetSavingsLastMonthForSelectedUsers(selectedUserList, alertSavings));
                }
                updateAlertItem(alertDetails.alertItem, alertSavingsStatusId, lastUpdatedUtc, lastUpdatedBy, alertSavingsDescription);
                setAlertDetails(alertDetails);
            }
            setIsAlertSavingsUpdated(true);
        }
    };
    return { updateAlertSavings, updateSavingsStatus }
}
