import { useContext } from 'react';
import axios from 'axios';
import {AlertCommentFactory} from "../factory/AlertCommentFactory";
import {AlertComment} from "../types/ViewModel/AlertComment";
import {AlertCommentBM} from "../types/BusinessModel/AlertCommentBM";
import getContext from "../context/AppSettingsContext";
import { useSnackbar } from 'react-simple-snackbar'
import {snackbarOptionsError, snackbarOptionsOk} from "../utils/snackbarUtils";
import {AppStateContext} from "../context/AppStateContext";
import {AppAlertListContext} from "../context/AppAlertListContext";


export default () => {
    const endpoint = 'AddAlertComment';
    const appSettings = useContext(getContext());
    const {
        alertDetails,
        setAlertDetails
    } = useContext(AppStateContext)!;
    const {
        setIsAlertItemUpdated,
    } = useContext(AppAlertListContext)!;
    const [openSnackbarOk] = useSnackbar(snackbarOptionsOk);
    const [openSnackbarError] = useSnackbar(snackbarOptionsError);

    const alertCommentFactory = new AlertCommentFactory();

    const url = appSettings.api + endpoint;

    const addAlertComment = async (alertComment: AlertComment) => {
        const alertCommentBM = alertCommentFactory.getAlertCommentBM(alertComment);
        openSnackbarOk('Saving alert comment');

        const response = await axios
            .post<AlertCommentBM>(url, alertCommentBM, {})
            .then(postResponse => {
                openSnackbarOk('Alert comment saved');
                return postResponse;
            })
            .catch(error => {
                return error.response;
            });

        if (!response) {
            openSnackbarError('Failed: no connection to server');
        } else if (response.status !== 200) {
            if (response.data) {
                openSnackbarError('Failed: ' + String(response.data.statusCode) + ' (' + response.data.errorMessage + ')');
            } else {
                openSnackbarError('Saving comment failed');
            }
        } else {
            if (alertDetails && alertDetails.alertItem) {
                alertDetails.alertItem.commentList.splice(0, 0, alertComment);
                setAlertDetails(alertDetails);
            }
        }
        setIsAlertItemUpdated(true);
    };

    return { addAlertComment }
}
