import {AlertItem} from "../types/ViewModel/AlertItem";
import {AlertContextBM} from "../types/BusinessModel/AlertContextBM";
import {AlertContext} from "../types/ViewModel/AlertContext";

export class AlertContextFactory {
    constructor(
    ) {
    }

    public getAlertContext(item : AlertContextBM): AlertContext {
        return new AlertContext(
            item.imoNumber,
            item.cargoOperationId,
            !item.cargoNumber ? '' : item.cargoNumber,
            !item.productName ? '' : item.productName,
            item.noonReportId,
            !item.tankName ? '' : item.tankName);
    }

    public getAlertContextBM(item : AlertContext): AlertContextBM {
        return {
            imoNumber: item.imoNumber,
            vesselName: item.vesselName,
            cargoOperationId: item.cargoOperationId,
            cargoNumber: item.cargoNumber,
            productName: item.productName,
            noonReportId: item.noonReportId,
            tankName: item.tankName
        }
    }
}
