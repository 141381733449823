import React from 'react';

const colors = {
    darkBlue: '#00447e',
    lightBlue: '#009fdf',
    orange: '#ec6825',
}

const theme = {
    colors: colors,
    primaryTextColor: colors.darkBlue
}

const ThemeContext = React.createContext(theme);

export default ThemeContext;
