import {AlertStatus} from "../../../enums/AlertStatus";
import styled, {css} from "styled-components";

export interface IButtonProps {
    isSelected?: boolean;
    onClick?: (alertStatus: AlertStatus) => void;
}

export const Wrapper = styled.div`
 
`;

export const StyledButton = styled.button<IButtonProps>`
  background: #00aeef;
  border: 1px solid #00aeef;
  color: #fff;
  height: 24px;
  padding: 2px 8px;
  font-size: 12px;
  margin: 1px;
  outline: 0;
  cursor: pointer;
  border-radius: 3px;

  &.status-complete {
    border: 1px solid #4cb944;
    background: #4cb944;
  }
  &.status-exclude {
      border: 1px solid #ef5656;
      background: #ef5656;
  }
  &.status-in-progress {
      border: 1px solid #f1be39;
      background: #f1be39;
  }
  &.status-new {
      border: 1px solid #aaa;
      background: #aaa;
  }
  &.status-muted {
      border: 1px solid #aaa;
      background: #a34198;
  }

  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;

