import React, {useState} from 'react'
import {ContextProps} from '../types/ContextProps';
import {AlertDetails} from "../types/ViewModel/AlertDetails";
import {HighChartModel} from "../types/ViewModel/HighChartModel";
import {FilterAlertData} from "../types/ViewModel/FilterAlertData";
import {FilterSavingsData} from "../types/ViewModel/FilterSavingsData";
import {AlertType} from "../types/ViewModel/AlertType";
import {VesselOption} from "../types/ViewModel/VesselOption";
import {AdGroupMember} from "../types/ViewModel/AdGroupMember";
import {CreateFilterAlertData, CreateFilterSavingsData} from "../utils/alertFilterUtils";
import {DetailComponent} from "../enums/DetailComponent";
import {AlertSavingsPerUser} from "../types/ViewModel/AlertSavingsPerUser";
import {SelectedUser} from "../types/ViewModel/SelectedUser";
import {AlertListMode} from "../enums/AlertListMode";
import { StatisticsAlert } from '../types/ViewModel/StatisticsAlert';
import { SelectOption } from '../types/ViewModel/SelectOption';

type AppStateContextType = {
    isAlertSavingsUpdated: boolean,
    savingsLastMonth: number,
    numberOfUnresolvedAlerts: number,
    highChartActivitiesArray: Array<HighChartModel>,
    filterAlertData: FilterAlertData,
    filterSavingsData: FilterSavingsData,
    alertTypeList: AlertType[],
    isVesselListRetrieved: boolean,
    vesselList: VesselOption[],
    filteredVesselList: VesselOption[],
    alertDetails: AlertDetails | null,
    isLoadingAlertDetails: boolean,
    isLoadingVesselList: boolean,
    isLoadingAlertTypeList: boolean,
    isLoadingSavingsList: boolean,
    isLoadingStatistics: boolean,
    isLoadingRole: boolean,
    isLoadingUserList: boolean,
    isLoadingAdminList: boolean,
    adGroupUserList: AdGroupMember[],
    adGroupAdminList: AdGroupMember[],
    detailComponentId: DetailComponent,
    previousDetailsTab: string,
    alertSavingsPerUserList: AlertSavingsPerUser[],
    alertListMode: AlertListMode,
    selectedStatisticsPeriod: SelectOption | null,
    selectedStatisticsAlertTypes: AlertType[],
    selectedStatisticsVessels: VesselOption[],
    selectedStatisticsReportingGroups: SelectOption[],
    selectedUserList: SelectedUser[],
    statisticsAlertList: StatisticsAlert[],
    periodFilteredStatisticsAlertList: StatisticsAlert[],
    filteredStatisticsAlertList: StatisticsAlert[],
    setIsAlertSavingsUpdated: (boolean) => void,
    setSavingsLastMonth: (savings: number) => void,
    setNumberOfUnresolvedAlerts: (unresolvedAlerts: number) => void,
    setHighChartActivitiesArray: (highChartActivityArray: Array<HighChartModel>) => void,
    setFilterAlertData: (filterAlertData: FilterAlertData) => void,
    setFilterSavingsData: (filterSavingsData: FilterSavingsData) => void,
    setIsVesselListRetrieved: (retrieved: boolean) => void,
    setVesselList: (vesselList: VesselOption[]) => void,
    setFilteredVesselList: (vesselList: VesselOption[]) => void,
    setIsLoadingVesselList: (isLoadingVesselList: boolean) => void
    setIsLoadingAlertTypeList: (isLoadingVesselList: boolean) => void
    setIsLoadingSavingsList: (isLoadingVesselList: boolean) => void
    setIsLoadingStatistics: (isLoadingVesselList: boolean) => void
    setIsLoadingRole: (isLoadingVesselList: boolean) => void
    setIsLoadingUserList: (isLoadingVesselList: boolean) => void
    setIsLoadingAdminList: (isLoadingVesselList: boolean) => void
    setAlertDetails: (alertDetails: AlertDetails | null) => void,
    setIsLoadingAlertDetails: (boolean) => void,
    setAdGroupUserList: (adGroupMemberList: AdGroupMember[]) => void,
    setAdGroupAdminList: (adGroupMemberList: AdGroupMember[]) => void,
    setDetailComponentId: (detailComponentId: DetailComponent) => void,
    setPreviousDetailTab: (prevDetailsTab: string) => void,
    setAlertSavingsPerUserList: (alertSavingsPerUserList: AlertSavingsPerUser[]) => void,
    setAlertListMode: (alertListMOde: AlertListMode) => void,
    setSelectedUserList: (selectedUsers: SelectedUser[]) => void,
    setAlertTypeList: (alertTypeList: AlertType[]) => void,
    setStatisticsAlertList: (statisticsAlert: StatisticsAlert[]) => void,
    setSelectedStatisticsPeriod: (selectOption: SelectOption | null) => void,
    setSelectedStatisticsAlertTypes: (alertTypeList: AlertType[]) => void,
    setSelectedStatisticsVessels: (vessels: VesselOption[]) => void,
    setSelectedStatisticsReportingGroups: (reportingGroups: SelectOption[]) => void,
    setPeriodFilteredStatisticsAlertList: (statisticsAlert: StatisticsAlert[]) => void,
    setFilteredStatisticsAlertList: (statisticsAlert: StatisticsAlert[]) => void,
}

export const AppStateContext = React.createContext<AppStateContextType | null>(null);

export const AppStateProvider = ({ children }: ContextProps) => {
    const [alertDetails, setAlertDetails] = useState<AlertDetails | null>(null);
    const [isLoadingAlertDetails, setIsLoadingAlertDetails] = useState<boolean>(false);
    const [isAlertSavingsUpdated, setIsAlertSavingsUpdated] = useState<boolean>(false);
    const [savingsLastMonth, setSavingsLastMonth] = useState<number>(0);
    const [numberOfUnresolvedAlerts, setNumberOfUnresolvedAlerts] = useState<number>(0);
    const [highChartActivitiesArray, setHighChartActivitiesArray] = useState<Array<HighChartModel>>([]);
    const [filterAlertData, setFilterAlertData] = useState<FilterAlertData>(CreateFilterAlertData(null));
    const [filterSavingsData, setFilterSavingsData] = useState<FilterSavingsData>(CreateFilterSavingsData(null));
    const [alertTypeList, setAlertTypeList] = useState<AlertType[]>([]);
    const [selectedStatisticsPeriod, setSelectedStatisticsPeriod] = useState<SelectOption | null>(null);
    const [selectedStatisticsAlertTypes, setSelectedStatisticsAlertTypes] = useState<AlertType[]>([]);
    const [selectedStatisticsVessels, setSelectedStatisticsVessels] = useState<VesselOption[]>([]);
    const [selectedStatisticsReportingGroups, setSelectedStatisticsReportingGroups] = useState<SelectOption[]>([]);
    const [isVesselListRetrieved, setIsVesselListRetrieved] = useState<boolean>(false);
    const [vesselList, setVesselList] = useState<VesselOption[]>([]);
    const [filteredVesselList, setFilteredVesselList] = useState<VesselOption[]>([]);
    const [isLoadingVesselList, setIsLoadingVesselList] = useState<boolean>(false);
    const [isLoadingAlertTypeList, setIsLoadingAlertTypeList] = useState<boolean>(false);
    const [isLoadingStatistics, setIsLoadingStatistics] = useState<boolean>(false);
    const [isLoadingSavingsList, setIsLoadingSavingsList] = useState<boolean>(false);
    const [isLoadingRole, setIsLoadingRole] = useState<boolean>(false);
    const [isLoadingUserList, setIsLoadingUserList] = useState<boolean>(false);
    const [isLoadingAdminList, setIsLoadingAdminList] = useState<boolean>(false);
    const [adGroupUserList, setAdGroupUserList] = useState<AdGroupMember[]>([]);
    const [adGroupAdminList, setAdGroupAdminList] = useState<AdGroupMember[]>([]);
    const [detailComponentId, setDetailComponentId] = useState<DetailComponent>(DetailComponent.Tabs);
    const [previousDetailsTab, setPreviousDetailTab] = useState<string>('');
    const [alertSavingsPerUserList, setAlertSavingsPerUserList] = useState<AlertSavingsPerUser[]>([]);
    const [alertListMode, setAlertListMode] = useState<AlertListMode>(AlertListMode.Default);
    const [selectedUserList, setSelectedUserList] = useState<SelectedUser[]>([]);
    const [statisticsAlertList, setStatisticsAlertList] = useState<StatisticsAlert[]>([]);
    const [periodFilteredStatisticsAlertList, setPeriodFilteredStatisticsAlertList] = useState<StatisticsAlert[]>([]);
    const [filteredStatisticsAlertList, setFilteredStatisticsAlertList] = useState<StatisticsAlert[]>([]);

    return <AppStateContext.Provider value={{
        alertDetails,
        setAlertDetails,
        isLoadingAlertDetails,
        setIsLoadingAlertDetails,
        isAlertSavingsUpdated,
        setIsAlertSavingsUpdated,
        filterAlertData,
        filterSavingsData,
        alertTypeList,
        isVesselListRetrieved,
        vesselList,
        filteredVesselList,
        isLoadingVesselList,
        isLoadingAlertTypeList,
        isLoadingSavingsList,
        isLoadingRole,
        isLoadingUserList,
        isLoadingAdminList,
        savingsLastMonth,
        setSavingsLastMonth,
        numberOfUnresolvedAlerts,
        setNumberOfUnresolvedAlerts,
        highChartActivitiesArray,
        alertSavingsPerUserList,
        setHighChartActivitiesArray,
        setFilterAlertData,
        setFilterSavingsData,
        setAlertTypeList,
        setIsVesselListRetrieved,
        setVesselList,
        setFilteredVesselList,
        setIsLoadingVesselList,
        setIsLoadingAlertTypeList,
        setIsLoadingSavingsList,
        isLoadingStatistics,
        setIsLoadingStatistics,
        setIsLoadingRole,
        setIsLoadingUserList,
        setIsLoadingAdminList,
        adGroupUserList,
        setAdGroupUserList,
        adGroupAdminList,
        setAdGroupAdminList,
        detailComponentId,
        setDetailComponentId,
        previousDetailsTab,
        setPreviousDetailTab,
        setAlertSavingsPerUserList,
        alertListMode,
        setAlertListMode,
        selectedUserList,
        setSelectedUserList,
        statisticsAlertList, 
        setStatisticsAlertList,
        periodFilteredStatisticsAlertList, 
        setPeriodFilteredStatisticsAlertList,
        filteredStatisticsAlertList, 
        setFilteredStatisticsAlertList,
        selectedStatisticsPeriod,
        setSelectedStatisticsPeriod,
        selectedStatisticsAlertTypes,
        setSelectedStatisticsAlertTypes,
        selectedStatisticsVessels, 
        setSelectedStatisticsVessels,
        selectedStatisticsReportingGroups, 
        setSelectedStatisticsReportingGroups
    }}>
        {children}
    </AppStateContext.Provider >
}
