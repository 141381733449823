import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px 2px #ddd;
  margin: 0 0 1rem;
  padding: 0.75rem;
  height: 75px;
  @media only screen and (max-width: 1280px) {
    display: block;
    height: auto;
  }
`;

export const Title = styled.h1`
  padding: 0;
  text-align: left;
  font-size: 1.5rem;
  font-weight: 100;
  @media only screen and (max-width: 1280px) {
    margin: 0 0 15px 0;
  }
`;

export const LeftUpperContentWrapper = styled.div`
    flex: 0 0 40%;
    max-width: 40%;
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1280px) {
      max-width: 100%;
      display:block;
    }
`;

export const RightUpperContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    flex: 0 0 60%;
    max-width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    @media only screen and (max-width: 1280px) {
      max-width: 100%;
    }
    label {
      margin: 0 2px 0 20px;
      font-weight: 500;
    }
`;


export const LeftBottomContentWrapper = styled.div`
    flex: 0 0 80%;
    max-width: 80%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media only screen and (max-width: 1280px) {
      max-width: 100%;
    }
`;

export const RightBottomContentWrapper = styled.div`
    display: flex;    
    flex: 0 0 20%;
    max-width: 20%;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    
    @media only screen and (max-width: 1280px) {
      position: absolute;
      top: 10px;
      right: 20px;
    }
    label {
      margin: 0 2px 0 20px;
      font-weight: 500;
    }
`;

export const FilterWrapperTop = styled.div`
    max-height: 50%;
    flex: 0 0 0 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 1280px) {
      display:block;
    }
`;

export const FilterWrapperBottom = styled.div`
    flex: 0 0 50%;
    max-height:50%
    background: purple;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0 0 0;
`;

export const ButtonList = styled.div`
      display: flex;
      margin: 0;
      button {
        flex: 0 0 auto;
        width: 95px;
        height: 24px;
        padding: 2px 4px !important;
        font-size: 12px !important;
        margin: 1px;
        outline: none;
        flex: 0 0 auto;
        cursor: pointer;
        z-index: 0;

        &.blue-btn {
          background: #7cccea;
          border: 1px solid #00aeef;
          color: #ffffff;
          &.selected{
            background: #00aeef;
            border: 1px solid #00aeef;
            color: #ffffff;
          }
        }
        &.yellow-btn {
          background: #fdd06e;
          border: 1px solid #efa500;
          color: #ffffff;
        &.selected{
          background: #efa500;
          border: 1px solid #efa500;
            color: #ffffff;
          }
        }
      }

      &.account-groups {
        button {
          width: 50px;
        }
      }

      &.activity-types {
        flex: 0 0 50%;
        flex-flow: row-reverse;
        justify-content: flex-end;
      }
`;

export const ViewModeWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 1rem;
    z-index: 10;

    .active-view-mode{
        box-shadow: 0 1px 3px #009fdf57;
        border: solid 1px #009fdf8c;

        &:hover {
            box-shadow: 0 1px 3px #009fdf57;
            border: solid 1px #009fdf8c;
    
        }
    }
`;

export const ViewModeButton = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 1rem;
    background: #ffffff;
    margin: 0 0.2rem;
    font-size: 0.6rem;

    svg {
        margin: 0 auto;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    &:hover {
        border-color: #cccccc;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
`;
