import { useContext, useEffect } from 'react';
import useApi from './useApi';
import useAppState from "../app/useAppState";
import {AlertSavingsPerUserFactory} from "../factory/AlertSavingsPerUserFactory";
import {AlertSavingsPerUserDataBM} from "../types/BusinessModel/AlertSavingsPerUserDataBM";
import {AppStateContext} from "../context/AppStateContext";

export default () => {
    const endpoint = 'GetAlertSavingsPerUserData';
    const { state, getApiData } = useApi();
    const { setAlertSavingsPerUserList, setIsLoadingSavingsList
    } = useContext(AppStateContext)!;

    const alertSavingsPerUserFactory = new AlertSavingsPerUserFactory();

    const getAlertSavingsPerUserList = () => {
        getApiData(endpoint, '');
    };

    useEffect(() => {
        setIsLoadingSavingsList(state.isLoading);
        if (state.status === 'success'){
            const alertSavingsPerUserData = state.result as unknown as AlertSavingsPerUserDataBM;
            const alertSavingsPerUserList = alertSavingsPerUserFactory.getAlertSavingsPerUserList(alertSavingsPerUserData.alertSavingsPerUserList);
            setAlertSavingsPerUserList(alertSavingsPerUserList);
        }
    }, [state]);

    return { state, getAlertSavingsPerUserList };
}
